@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap);
._29V0wjo2YAg5bGKRxI5TyP {
  color: black !important; }

._244RfM_0-GQcB71i_YpMpa {
  background-color: #edf1f6;
  min-height: 100vh !important; }

._1x415JqjlvSClaCMyMNhPd {
  padding: 26px 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  ._1x415JqjlvSClaCMyMNhPd > div:last-child {
    width: 100%; }

._3ScNPYdNyo5REalF1Q3Efe {
  height: 60px; }

._3EkpG90ELiWf-_VGhm1qoB {
  padding: 0 !important; }

.BR3PwJ0A8oRGueUtrEwGo {
  height: 60px;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #000 !important;
  color: var(--Dark, #000) !important;
  font-size: 16px;
  line-height: 24px;
  border: 0 !important;
  margin: 0 10px !important; }
  .BR3PwJ0A8oRGueUtrEwGo img {
    margin-right: 15px !important; }
  .BR3PwJ0A8oRGueUtrEwGo:hover {
    border: none !important; }

._2aeEa6-xtTToSHQutf4_bw {
  height: 60px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border: 0 !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04) !important;
  margin: 0 10px !important; }
  ._2aeEa6-xtTToSHQutf4_bw span {
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%) !important;
    background: var(--Blue-Gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-weight: bold; }
  ._2aeEa6-xtTToSHQutf4_bw img {
    margin-right: 15px !important; }

._2HPf7GJCrbKdTdKBVdJkXj {
  font-size: 18px; }

._1xAxc_L9zG87Mf9CEKzb2O {
  display: none; }

.LKvUAeNhfjpmLihNLKuvd {
  display: block;
  width: 100%;
  border-bottom: none !important; }

._1k39vvpiIH1iHcwFcs-_We::after {
  content: none;
  margin-top: 20px; }

@media screen and (min-width: 521px) and (max-width: 990px) {
  .BR3PwJ0A8oRGueUtrEwGo img, ._2aeEa6-xtTToSHQutf4_bw img {
    margin-left: 8px !important; } }

@media screen and (max-width: 576px) {
  ._1xAxc_L9zG87Mf9CEKzb2O {
    display: block;
    float: right; }
  .LKvUAeNhfjpmLihNLKuvd {
    display: none; }
  ._244RfM_0-GQcB71i_YpMpa {
    min-height: unset !important; } }

._1b2hN5NjNz7Q2ii_e0ACDb {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center; }
  ._1b2hN5NjNz7Q2ii_e0ACDb ._2yS948ByDRw5UDoNdQuTnD {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold; }
  ._1b2hN5NjNz7Q2ii_e0ACDb img {
    margin-right: 24px; }

._1wBl1nlJt6cLIyajucwO1w:hover {
  cursor: pointer; }

@media screen and (max-width: 530px) {
  ._1b2hN5NjNz7Q2ii_e0ACDb {
    flex-wrap: wrap; } }

.LisJV1U3Fy6VpEK9ykvcM {
  background-color: white;
  min-height: 100vh; }

._1aAlS9CnHEaxf8KqgtL7Ad {
  border-top: solid #a5adb8 1px;
  padding: 10px;
  background: #fff; }

._3VdEn3ucfCXZGefxtecTtK {
  width: 1px;
  height: 1em;
  background: #eaeff7;
  margin: 0 20px; }

._1YCkkAIIHZdHT2oowSeIMp {
  color: #a5adb8; }

._2fVB09KZ0H4sp3iM66jdzr {
  padding: 25px 50px; }
  ._2fVB09KZ0H4sp3iM66jdzr input {
    background: #edf1f6 !important; }
  ._2fVB09KZ0H4sp3iM66jdzr ._1kqjCXsd37UM5gBq4m4gSi {
    display: flex;
    justify-content: center; }
    ._2fVB09KZ0H4sp3iM66jdzr ._1kqjCXsd37UM5gBq4m4gSi .PiUZoQzqhzlHQeYIwGIkX {
      font-size: 18px;
      font-weight: bold;
      color: #1c1c1e; }
  ._2fVB09KZ0H4sp3iM66jdzr ._1vNHIzfv6yGYKGXvhnt9FR {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px; }
  ._2fVB09KZ0H4sp3iM66jdzr ._203RdA4ud80hoQ-Anz8grS {
    margin-top: 15%; }
    ._2fVB09KZ0H4sp3iM66jdzr ._203RdA4ud80hoQ-Anz8grS button {
      font-weight: bold; }
    ._2fVB09KZ0H4sp3iM66jdzr ._203RdA4ud80hoQ-Anz8grS .PG6-qAu-Ixrph_MWceNVP {
      font-weight: 300 !important; }
    ._2fVB09KZ0H4sp3iM66jdzr ._203RdA4ud80hoQ-Anz8grS .PG6-qAu-Ixrph_MWceNVP {
      font-weight: 400; }

@media screen and (max-width: 520px) {
  ._1kqjCXsd37UM5gBq4m4gSi {
    justify-content: center; } }

.UjlHWZMNWBLcxvc770tWD {
  font-weight: 700;
  color: black; }


._2CoklEzZD3WwmILkYFR2Ga {
  margin-top: 14%; }

._2C1T2TcUZpC2TYEI1udrE5 {
  cursor: pointer; }

._1vxxvbIKkif_mfnSpHlCsB {
  font-weight: bold;
  font-size: 32px !important;
  text-align: center;
  color: #3c9;
  line-height: 1.19; }

._2V7Okx1tMFTM8s5RK5bJcb {
  text-align: center;
  font-size: 69px !important;
  font-weight: bold;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.rpluDK2IlEtf7m2t1WLNJ {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 16px !important;
  margin-bottom: 15% !important;
  margin: auto;
  color: #031937;
  width: 230px !important; }

._3uDQlZKgJyoTIJ4ha2bCtK {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  ._3uDQlZKgJyoTIJ4ha2bCtK button {
    width: 304px;
    font-weight: bold; }
  ._3uDQlZKgJyoTIJ4ha2bCtK > button:last-child {
    margin-top: 20px !important;
    border: 2px solid #0b71ff;
    color: black; }
    ._3uDQlZKgJyoTIJ4ha2bCtK > button:last-child:hover {
      color: black;
      background: transparent; }

.J5JCWNEp4mgDVaz3jIbtH {
  cursor: pointer; }

._3hj0x9V2VUyGJ3TNSRPBJ- {
  margin-top: 4%; }
  ._3hj0x9V2VUyGJ3TNSRPBJ- input {
    background: #edf1f6 !important; }

._1E-D_zEoQuwbodhq5CoJ17 {
  font-size: 48px !important; }

.bD-zyQ9x_W1uqdV8fMjEP {
  display: flex;
  justify-content: space-around;
  margin: auto;
  font-size: 16px;
  margin-top: 16px;
  width: 240px;
  font-weight: bold;
  margin-bottom: 45px !important; }

._2jFg7BbR-F7CgOhu26-zg0 {
  font-weight: 600; }
  ._2jFg7BbR-F7CgOhu26-zg0 input {
    height: 17px !important;
    width: 17px !important; }
  ._2jFg7BbR-F7CgOhu26-zg0 label {
    margin-left: 5px; }

._34fVcA3b9zHMjOiyb3kJ01 {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

._1eo1Ko2E3yhuBLhDrJZsAP {
  display: flex !important;
  align-items: center; }
  ._1eo1Ko2E3yhuBLhDrJZsAP > div:first-child {
    margin-bottom: 0px !important; }
    ._1eo1Ko2E3yhuBLhDrJZsAP > div:first-child > div:first-child {
      background: #edf1f6;
      border: none; }
      ._1eo1Ko2E3yhuBLhDrJZsAP > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  ._1eo1Ko2E3yhuBLhDrJZsAP p {
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0px !important; }
    ._1eo1Ko2E3yhuBLhDrJZsAP p span {
      color: #0b71ff; }
      ._1eo1Ko2E3yhuBLhDrJZsAP p span:hover {
        cursor: pointer; }

._2WAAxvj71pJe9SnnepfIKp {
  margin: 32px 0;
  font-weight: bold; }

.Ys9nozGW-e4Z6oQ3mcpSq {
  margin-bottom: 4px; }

._2phxzbf_LmvyyXU9g0jUjB {
  text-align: center;
  color: #031937; }
  ._2phxzbf_LmvyyXU9g0jUjB span {
    color: #0b71ff; }
    ._2phxzbf_LmvyyXU9g0jUjB span:hover {
      cursor: pointer; }

._1UPCtyy7yuLyU3b8Yszqor {
  position: relative;
  background-image: url(/static/media/onboarding-background.5798e1b0.svg);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0; }

._3CMb2ATrUrs1PbmpKG0j94 ._2ny841xNF_Hl1CNi23t5oi {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: auto; }

._3CMb2ATrUrs1PbmpKG0j94 ._3hyX83EPOSocm533KzGlH3 {
  margin-top: 2.5%; }

._3CMb2ATrUrs1PbmpKG0j94 label {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

._3CMb2ATrUrs1PbmpKG0j94 select {
  background-color: #FFFFFF; }

._3CMb2ATrUrs1PbmpKG0j94 input {
  background: #FFFFFF !important; }

._3CMb2ATrUrs1PbmpKG0j94 button {
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._3CMb2ATrUrs1PbmpKG0j94 ._1nEpRK8PtHeH3Z7CFBOVd9 {
  margin-top: 10px;
  display: flex;
  flex-direction: row; }
  ._3CMb2ATrUrs1PbmpKG0j94 ._1nEpRK8PtHeH3Z7CFBOVd9 button {
    margin-top: 0px !important; }

._2iVaCQDsXNOnjuibhKFBI- {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  width: 532px; }
  ._2iVaCQDsXNOnjuibhKFBI- h2 {
    color: #000; }
  ._2iVaCQDsXNOnjuibhKFBI- ._2LogKBdOHVR090Yj42sojI {
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer; }
  ._2iVaCQDsXNOnjuibhKFBI- ._29pOmQcXLtxDbuE9qZYI8L {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  ._2iVaCQDsXNOnjuibhKFBI- ._1uVz9oLrxnbO1G7WAMqOE7 {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px; }
  ._2iVaCQDsXNOnjuibhKFBI- ._9qjB7VzsfBXAb9Mkrv3Gt {
    color: #475569; }
  ._2iVaCQDsXNOnjuibhKFBI- ._2Ibgmly0ti49VSwn1FXGgz {
    text-align: right;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #04B5FF;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    margin-left: auto !important; }
    ._2iVaCQDsXNOnjuibhKFBI- ._2Ibgmly0ti49VSwn1FXGgz:hover {
      cursor: pointer; }
  ._2iVaCQDsXNOnjuibhKFBI- ._3W9uXm7Ui8_U-D2c76eYV4 {
    font-size: 16px;
    line-height: 22px;
    color: #475569; }
    ._2iVaCQDsXNOnjuibhKFBI- ._3W9uXm7Ui8_U-D2c76eYV4 .huxGp5Z5ZkK2rBNhn3Fl6 {
      cursor: pointer;
      font-weight: 500; }
  ._2iVaCQDsXNOnjuibhKFBI- .pKfZ9cT_dbJnrEULSCLOP {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  ._2iVaCQDsXNOnjuibhKFBI- ._2mU2dQU2_y4ddRUYXn3ZGM {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._2Dw2zENGHf4VMJxi5Rnba2 {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._3_WtSUzd8QdlnVH-749mXd {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

._3JFWzs5lm4bgwmxmD7W6qf {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

._3Ai2JfjMz8_Gka39GeoohR {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

._3q-MCm6I2Abt2AqZnCpNbN {
  display: flex;
  justify-content: center; }

._1qJVb3Nl-c7sCciPlsztVx input {
  height: 17px;
  width: 17px; }

._1qJVb3Nl-c7sCciPlsztVx p {
  font-weight: 300 !important; }
  ._1qJVb3Nl-c7sCciPlsztVx p span {
    font-weight: bold; }

._1qJVb3Nl-c7sCciPlsztVx label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  ._1qJVb3Nl-c7sCciPlsztVx label p span {
    color: #0b71ff; }
    ._1qJVb3Nl-c7sCciPlsztVx label p span:hover {
      cursor: pointer; }

._2TjQo2doKYxvoq0wwHb9J2 {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px; }

._1PAU2pby1lo2fC11Ec0hdD {
  display: none; }

._1z4GyW8W27hXAcQPEIVBvh {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._1nbCIAj3wGQVbNFv_KcS_N {
  display: block; }

._1z4GyW8W27hXAcQPEIVBvh {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._17LZ0bGE9lyjobn71fA0EI {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

._28IHq5ZGCLY1vfg0yW-yyf {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

._3DA3YPqpIe88sTvYbi-5Lj {
  margin-top: 30%; }

._2in-mX9yKbexXSsNMafyWF {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._1Hnq5Vs5ri_Vb9f1swEO8S span {
  display: inline-block; }

._3f90usZo5Cdo38dLepx_34 {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._3Ev6ShWYjMBr9cbakmik18 {
  margin-top: 25px; }

._3LHRBpNs4-zMI7OzSFe8xp {
  margin-top: 10px; }

._2ptiOT2UR34S_oAiYssot {
  height: 200px;
  width: 200px;
  object-fit: contain; }

.QXMWJpDxRbwt7ddTjY_GY {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  ._2ptiOT2UR34S_oAiYssot {
    height: 200px;
    width: 200px; } }

._3KqBedmrLpeiMqduLg1kiP {
  margin-top: 14px; }

.RsQxjseXMKaCsIYunDbFP {
  margin-top: 30px; }

._2tyKUdOkr2VfwgDsc7LH1Q {
  border: none;
  color: black; }
  ._2tyKUdOkr2VfwgDsc7LH1Q:hover {
    background: transparent;
    color: black; }

.xyvFXUcnxsgJpRoVSAfJ5 {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  .xyvFXUcnxsgJpRoVSAfJ5 div:first-child {
    width: 100% !important; }
    .xyvFXUcnxsgJpRoVSAfJ5 div:first-child > ul li:last-child {
      display: none !important; }
    .xyvFXUcnxsgJpRoVSAfJ5 div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes _3PcKArcf0D70YUbclJo4CI {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes _3PcKArcf0D70YUbclJo4CI {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._3I5SAjCYVS1vihrIKfg0Mp {
  -webkit-animation: _3PcKArcf0D70YUbclJo4CI 4s forwards;
          animation: _3PcKArcf0D70YUbclJo4CI 4s forwards; }

.KID9Tsd-aDeQR9-gLPrJ0 {
  display: flex;
  justify-content: center;
  align-items: center; }

._3WMtchaQXE5-0rXfViD2UE {
  justify-content: center;
  flex-wrap: wrap; }
  ._3WMtchaQXE5-0rXfViD2UE .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._3WMtchaQXE5-0rXfViD2UE .active button:hover {
    background: none;
    cursor: default !important; }
  ._3WMtchaQXE5-0rXfViD2UE .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._3WMtchaQXE5-0rXfViD2UE div button {
    display: block !important; }

@media screen and (max-width: 520px) {
  ._2TjQo2doKYxvoq0wwHb9J2 {
    justify-content: center; }
  ._2iVaCQDsXNOnjuibhKFBI- {
    padding: 48px 0;
    width: 350px;
    font-size: 14px;
    line-height: 20px; }
    ._2iVaCQDsXNOnjuibhKFBI- ._29pOmQcXLtxDbuE9qZYI8L {
      font-size: 30px;
      line-height: 40px; }
    ._2iVaCQDsXNOnjuibhKFBI- ._1uVz9oLrxnbO1G7WAMqOE7, ._2iVaCQDsXNOnjuibhKFBI- ._2Ibgmly0ti49VSwn1FXGgz {
      font-size: 14px;
      line-height: 20px; }
  ._3CMb2ATrUrs1PbmpKG0j94 {
    padding: 0 25px; } }

._3FTK6mFR5Obzd2gtLvObBl {
  cursor: pointer; }

._1B1kBYZtQ_ZIFc0sKsKNYO {
  margin-top: 4%; }
  ._1B1kBYZtQ_ZIFc0sKsKNYO input {
    background: #edf1f6 !important; }

._3HuaXZctO_EfZQIhChuXE9 {
  font-size: 48px !important; }

._33Ml7BIltBA4I_Xs9FEevf {
  margin: 5rem auto 2rem auto;
  text-align: left;
  font-size: 16px; }

._31V6X6y9d886KJZGTlc0- {
  margin: 10rem auto; }

._1L8QuywDl5tKCpXptway-Q {
  text-align: center;
  justify-content: center;
  display: grid; }

._3aadOW3PhcmwCUCSZSDB02 {
  margin: auto;
  font-size: 12px;
  margin-top: 16px;
  width: 300px;
  margin-bottom: 20px !important; }

._3uTbNYQwQ2bFl1NgbfPMIw {
  display: flex;
  justify-content: space-around;
  margin: auto;
  font-size: 16px;
  margin-top: 16px;
  width: 240px;
  font-weight: bold;
  margin-bottom: 45px !important; }

._2kjmMBm8oytDtqSN8ZXftF {
  font-weight: 600; }
  ._2kjmMBm8oytDtqSN8ZXftF input {
    height: 17px !important;
    width: 17px !important; }
  ._2kjmMBm8oytDtqSN8ZXftF label {
    margin-left: 5px; }

._1QFJ0tVXa5pmDxOBVm13ew {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

._38tqDMqcXnBEm87eaG5Be {
  display: flex !important;
  align-items: center; }
  ._38tqDMqcXnBEm87eaG5Be > div:first-child {
    margin-bottom: 0px !important; }
    ._38tqDMqcXnBEm87eaG5Be > div:first-child > div:first-child {
      background: #edf1f6;
      border: none; }
      ._38tqDMqcXnBEm87eaG5Be > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  ._38tqDMqcXnBEm87eaG5Be p {
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0px !important; }
    ._38tqDMqcXnBEm87eaG5Be p span {
      color: #0b71ff; }
      ._38tqDMqcXnBEm87eaG5Be p span:hover {
        cursor: pointer; }

.bNXq8UbdUdN1S1qAb-EgN {
  text-align: right;
  font-size: 12px;
  font-weight: 500; }
  .bNXq8UbdUdN1S1qAb-EgN:hover {
    cursor: pointer; }

._kSOFLlVa6_MdKqHeoAoA {
  margin-top: 32px;
  font-weight: bold; }

._1_I1FzDUSH5FwtHa9Va5qg {
  margin-bottom: 4px; }

._3wME6nMoNVlLnVvGKiU0HR {
  font-weight: 600;
  margin-top: 25%;
  text-align: center;
  color: #031937; }
  ._3wME6nMoNVlLnVvGKiU0HR span {
    color: #0b71ff; }
    ._3wME6nMoNVlLnVvGKiU0HR span:hover {
      cursor: pointer; }

._3wJHDm0SNM_czAjPSQk7kh {
  position: relative;
  background-image: url(/static/media/onboarding-background.5798e1b0.svg);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0; }

._11_3TTvIPdJsoxfS02qheh ._34cOkKvcFcgWJzOfeaRu1o {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: auto; }

._11_3TTvIPdJsoxfS02qheh ._2fraywWM_0oQCZVmlLD5Xg {
  margin-top: 2.5%; }

._11_3TTvIPdJsoxfS02qheh label {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

._11_3TTvIPdJsoxfS02qheh select {
  background-color: #FFFFFF; }

._11_3TTvIPdJsoxfS02qheh input {
  background: #FFFFFF !important; }

._11_3TTvIPdJsoxfS02qheh button {
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._11_3TTvIPdJsoxfS02qheh ._16oIW6pg3EGp5jTW_zpUMP {
  margin-top: 10px;
  display: flex;
  flex-direction: row; }
  ._11_3TTvIPdJsoxfS02qheh ._16oIW6pg3EGp5jTW_zpUMP button {
    margin-top: 0px !important; }

._3RryQ1m8gR0IbRULGtsCeR {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  width: 532px; }
  ._3RryQ1m8gR0IbRULGtsCeR h2 {
    color: #000; }
  ._3RryQ1m8gR0IbRULGtsCeR ._22oaG1_gE6tYNaOh_x_sQb {
    margin-right: 10px;
    cursor: pointer; }
  ._3RryQ1m8gR0IbRULGtsCeR ._18RINrjbKf-Yps7NjmFROx {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  ._3RryQ1m8gR0IbRULGtsCeR ._1ha6GGNRVWekCG3dsyDVMX {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px; }
  ._3RryQ1m8gR0IbRULGtsCeR ._3zfvzmKKBCLQSIhHx0NBTg {
    color: #475569; }
  ._3RryQ1m8gR0IbRULGtsCeR ._26jwO29u5EoGbdD22SFgsz {
    font-size: 16px;
    line-height: 22px;
    color: #475569; }
    ._3RryQ1m8gR0IbRULGtsCeR ._26jwO29u5EoGbdD22SFgsz ._2PRfffjuzKQtuV2rSJj4hb {
      cursor: pointer;
      font-weight: 500; }
  ._3RryQ1m8gR0IbRULGtsCeR ._3gsyvMDi2Q-5ce7ItNimEu {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  ._3RryQ1m8gR0IbRULGtsCeR ._3rElN9nJS-LIFCUBW1VTP1 {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

.CnFDeeJFEjEhEbJ-IejH2 {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._1h9UX5DtOCOZgwDx7y8bnQ {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

._28V5s_DNvH1KmVcDwvD3ba {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

.rHZiVyr7wVXnqa6Dm-Doo {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

._1To2PsA17AyTkq-0rHDJQL {
  display: flex;
  justify-content: center; }

._3wyRmmAJC-btRfy9vXikSq input {
  height: 17px;
  width: 17px; }

._3wyRmmAJC-btRfy9vXikSq p {
  font-weight: 300 !important; }
  ._3wyRmmAJC-btRfy9vXikSq p span {
    font-weight: bold; }

._3wyRmmAJC-btRfy9vXikSq label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  ._3wyRmmAJC-btRfy9vXikSq label p span {
    color: #0b71ff; }
    ._3wyRmmAJC-btRfy9vXikSq label p span:hover {
      cursor: pointer; }

._3irE-AzFVyCcMi5fU5HeYU {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px; }

._1NflIams9StnVM97lDEPdy {
  display: none; }

.cFqB0p8dD-noLcv7qXGY1 {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._1Oiv93GVNVLFhGyZZFBO7c {
  display: block; }

.cFqB0p8dD-noLcv7qXGY1 {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._1Jx3M9txloEComTtAq055u {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

._3y_7VI7I-A_UAWH21p5ZfP {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

._2pjoicBkLxVv1IcK6_DrLk {
  margin-top: 30%; }

._1kohWWp1kokK4MP6xRBkZh {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._2rPeasuBYDoj7aAE_uumSi span {
  display: inline-block; }

._1GV_0RBHcJG41WljGnTnwH {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

.tPiEdLkIz2RMbSaVkU177 {
  margin-top: 25px; }

._1mQOs31oX3_1cyOcBOuonP {
  margin-top: 10px; }

.pxCO8pyDa3KE6yLmmdtMW {
  height: 200px;
  width: 200px;
  object-fit: contain; }

._2lrlBEW6PiefWgTsoP2HxP {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  .pxCO8pyDa3KE6yLmmdtMW {
    height: 200px;
    width: 200px; } }

._3zv2-j91mDnVo_I6YU6v7_ {
  margin-top: 14px; }

.SQRW6qaxQ0_954JUseYM {
  margin-top: 30px; }

._2ceGbxcKR9A0tB5rWbCxxz {
  border: none;
  color: black; }
  ._2ceGbxcKR9A0tB5rWbCxxz:hover {
    background: transparent;
    color: black; }

._3BEglpVUdWEoe8IpnvrcCS {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  ._3BEglpVUdWEoe8IpnvrcCS div:first-child {
    width: 100% !important; }
    ._3BEglpVUdWEoe8IpnvrcCS div:first-child > ul li:last-child {
      display: none !important; }
    ._3BEglpVUdWEoe8IpnvrcCS div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes _1xLid6wlbA_H2iAyB0YjqE {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes _1xLid6wlbA_H2iAyB0YjqE {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._2GzVmvLxWAHwm6sAIxZ_3Q {
  -webkit-animation: _1xLid6wlbA_H2iAyB0YjqE 4s forwards;
          animation: _1xLid6wlbA_H2iAyB0YjqE 4s forwards; }

.lcLkXprd9BaXlwlBAScWB {
  display: flex;
  justify-content: center;
  align-items: center; }

._2ZXNZuoeqeKNNw8Rp1XZgw {
  justify-content: center;
  flex-wrap: wrap; }
  ._2ZXNZuoeqeKNNw8Rp1XZgw .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._2ZXNZuoeqeKNNw8Rp1XZgw .active button:hover {
    background: none;
    cursor: default !important; }
  ._2ZXNZuoeqeKNNw8Rp1XZgw .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._2ZXNZuoeqeKNNw8Rp1XZgw div button {
    display: block !important; }

@media screen and (max-width: 520px) {
  ._3irE-AzFVyCcMi5fU5HeYU {
    justify-content: center; }
  ._3RryQ1m8gR0IbRULGtsCeR {
    padding: 48px 0;
    width: 350px !important;
    font-size: 14px;
    line-height: 20px; }
    ._3RryQ1m8gR0IbRULGtsCeR ._18RINrjbKf-Yps7NjmFROx {
      font-size: 30px;
      line-height: 40px; }
    ._3RryQ1m8gR0IbRULGtsCeR ._1ha6GGNRVWekCG3dsyDVMX {
      font-size: 14px;
      line-height: 20px; }
  ._11_3TTvIPdJsoxfS02qheh {
    padding: 0 25px; } }

._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w {
  display: flex !important;
  align-items: flex-start; }
  ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w > div:first-child {
    margin-bottom: 0px !important; }
    ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w > div:first-child > div:first-child {
      background: #FFFFFF;
      border: none; }
      ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w p {
    margin-left: 5px;
    font-size: 12px; }
    ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w p span {
      color: #0b71ff; }
      ._2HIoMZvlUYoUQ2XXGET7Nd .mxm8yBx3m0NTbg8uQgt9w p span:hover {
        cursor: pointer; }

._2HIoMZvlUYoUQ2XXGET7Nd > p:last-child {
  color: #dc3545 !important;
  font-size: 80% !important; }

._2HIoMZvlUYoUQ2XXGET7Nd ._1bGdkxFKgJ5gSPLLVA_H1j {
  margin: 32px 0; }
  ._2HIoMZvlUYoUQ2XXGET7Nd ._1bGdkxFKgJ5gSPLLVA_H1j input {
    background: #F8FAFC !important;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    font-size: 23px;
    height: 62px;
    width: 62px; }
  ._2HIoMZvlUYoUQ2XXGET7Nd ._1bGdkxFKgJ5gSPLLVA_H1j ._12WcOlshs_xMvU1WnLlht4 {
    grid-gap: 0 12px;
    gap: 0 12px; }

@media screen and (max-width: 520px) {
  ._2HIoMZvlUYoUQ2XXGET7Nd ._1bGdkxFKgJ5gSPLLVA_H1j input {
    font-size: 12px;
    height: 45px;
    width: 45px; }
  ._2HIoMZvlUYoUQ2XXGET7Nd ._1bGdkxFKgJ5gSPLLVA_H1j ._12WcOlshs_xMvU1WnLlht4 {
    grid-gap: 0 6px;
    gap: 0 6px; } }

._5M3K1pEiLlQchsmXl16qh {
  position: fixed;
  top: 50%;
  right: 50%; }

._3Nuq2jQcyBIEL4Pw089Y3B {
  position: absolute;
  z-index: 99999999999999999;
  background: #000;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.8; }

._2__n4KWrDMbpke_deXg2JX {
  position: absolute;
  background: #000;
  width: 100%;
  z-index: 999999999999999999;
  right: 0;
  opacity: 0.8;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: Dof6Cna-GZcfD-4qT7WU5;
          animation-name: Dof6Cna-GZcfD-4qT7WU5; }

.WRRgLH0DFUZB6HTsppSCZ {
  padding: 25px 50px; }
  .WRRgLH0DFUZB6HTsppSCZ input {
    background: #edf1f6 !important; }
  .WRRgLH0DFUZB6HTsppSCZ ._1Wr6H4NCk9v8pSPQseuAvW {
    display: flex;
    justify-content: center; }
    .WRRgLH0DFUZB6HTsppSCZ ._1Wr6H4NCk9v8pSPQseuAvW ._1-2QhTk3bBQhqJJsOVrfRS {
      font-size: 18px;
      font-weight: bold;
      color: #1c1c1e; }
  .WRRgLH0DFUZB6HTsppSCZ ._2i72iIWM6qezidBjpMqnIf {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px; }
  .WRRgLH0DFUZB6HTsppSCZ .oYcqqU1Q9bVnTuAtyDdK_ {
    margin-top: 15%; }
    .WRRgLH0DFUZB6HTsppSCZ .oYcqqU1Q9bVnTuAtyDdK_ ._2osNGdN83PDKMdmF85BVlO {
      font-size: 14px;
      font-weight: 300; }
    .WRRgLH0DFUZB6HTsppSCZ .oYcqqU1Q9bVnTuAtyDdK_ button {
      font-weight: bold; }
    .WRRgLH0DFUZB6HTsppSCZ .oYcqqU1Q9bVnTuAtyDdK_ .OxoRB6DL9yAIEnG9mjNgv {
      font-weight: 300 !important; }
    .WRRgLH0DFUZB6HTsppSCZ .oYcqqU1Q9bVnTuAtyDdK_ .OxoRB6DL9yAIEnG9mjNgv {
      font-weight: 400; }

@media screen and (max-width: 520px) {
  ._1Wr6H4NCk9v8pSPQseuAvW {
    justify-content: center; } }

.HkLkHRzU7eUk07DDE93QN {
  justify-content: flex-end;
  text-align: right;
  padding-top: 0; }

._2ax8wUqgmmAQKTBC0HMef_ {
  padding: 0; }
  ._2ax8wUqgmmAQKTBC0HMef_ label, ._2ax8wUqgmmAQKTBC0HMef_ button {
    font-weight: bold;
    font-size: 14px; }
  ._2ax8wUqgmmAQKTBC0HMef_ ._1C2VDh6aKZHzDUkI2MCQ9P button {
    width: 120px;
    border-radius: 8px; }
  ._2ax8wUqgmmAQKTBC0HMef_ ._1YQolPyZXUdFyzpRzdRknO {
    display: block; }
  ._2ax8wUqgmmAQKTBC0HMef_ ._1w1fHTMeADdd52BN9Pt1Np {
    display: none; }
  ._2ax8wUqgmmAQKTBC0HMef_ ._15Jlv2MLjpBJE2_J_O686e {
    min-height: 522px; }
  ._2ax8wUqgmmAQKTBC0HMef_ .W1rVhu9tKeBv_C_6Gab11 {
    background-color: #0b71ff !important;
    color: white !important;
    box-shadow: none; }
  ._2ax8wUqgmmAQKTBC0HMef_ ._2a8-jc6SB-FxB2SSAE4O2B {
    background-color: white !important;
    color: black !important;
    box-shadow: none; }

.-aTj29DQNVpCJOqrumhuP p {
  font-size: 16px;
  font-weight: bold;
  color: #808b9a;
  margin-bottom: 0; }

.-aTj29DQNVpCJOqrumhuP span {
  margin-bottom: 15px;
  color: #031937;
  font-weight: bold;
  display: inline-block; }

._13j97tpcDKu-vrYEy8clk7 {
  margin-left: 20px; }

._3dIEuXdImJE8nlzTgHzvVA {
  background-color: #3c9;
  color: white; }
  ._3dIEuXdImJE8nlzTgHzvVA:hover {
    background-color: #3c9;
    color: white; }

._33wFqivItOgppErVyefqfl {
  page-break-before: always;
  margin-top: 10px; }

._3h-DWWVIXnRnHu-O18xkSB {
  margin-top: 20px;
  float: right; }
  ._3h-DWWVIXnRnHu-O18xkSB button:first-child {
    width: 130px;
    border-radius: 8px;
    margin-right: 10px; }
  ._3h-DWWVIXnRnHu-O18xkSB button:last-child {
    border: 1px solid black;
    width: 200px;
    font-weight: bold;
    border-radius: 8px; }

._3yoZVOiADBXzaEa0qfGekc {
  position: absolute;
  margin-left: 32px;
  font-weight: bold; }
  ._3yoZVOiADBXzaEa0qfGekc i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 15px; }
  ._3yoZVOiADBXzaEa0qfGekc .qAlVk7JwlMDV0vzlqCh2N {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

.fU7Tq0PFL6dPAvK2-WQ6n {
  padding: 10px 5px 55px 5px; }

._2rn_w85kAFNxAGEQ0AabTf {
  padding: 15px 40px; }
  ._2rn_w85kAFNxAGEQ0AabTf div {
    margin-bottom: 3px; }

._1k66ioa29DCAh4Xdp0ZbdN {
  height: 1px;
  width: 100%;
  background: #ececec; }

._1kMgFQeK14439mp1MIQ9RQ {
  border-bottom: none; }
  ._1kMgFQeK14439mp1MIQ9RQ ._14BEaQg0CSXHH9eQRjOL2W {
    height: 25px;
    background: #b5b7b9;
    width: 2px;
    margin-left: 10px;
    margin-right: 10px; }
  ._1kMgFQeK14439mp1MIQ9RQ div div div a {
    color: black !important;
    font-weight: bold; }

.h6pYsgTewQ3lJpebVnzsr {
  padding: 0 0 35px; }

._3RVs1ZFgtR92K0suF31bu5 {
  word-break: break-all; }

._1G7hd5x8vsAd7uryeW6XMI {
  display: flex;
  justify-content: space-between;
  padding: 12px 40px; }
  ._1G7hd5x8vsAd7uryeW6XMI ._3RSVOKYLiip1WiGerq-gi0 {
    display: -webkit-box; }
    ._1G7hd5x8vsAd7uryeW6XMI ._3RSVOKYLiip1WiGerq-gi0 button {
      border-radius: 8px;
      font-weight: bold; }
    ._1G7hd5x8vsAd7uryeW6XMI ._3RSVOKYLiip1WiGerq-gi0 button:first-child {
      margin-right: 10px; }
  ._1G7hd5x8vsAd7uryeW6XMI ._11RDA4R611D7i2QsJG567O {
    margin-left: -13px;
    font-weight: bold; }
  ._1G7hd5x8vsAd7uryeW6XMI .IoCmAO0T_LJSAk5ShCpHi {
    word-break: break-all; }
  ._1G7hd5x8vsAd7uryeW6XMI span {
    font-weight: bold; }

@media screen and (max-width: 900px) {
  ._1G7hd5x8vsAd7uryeW6XMI {
    flex-wrap: wrap; } }

@media screen and (max-width: 400px) {
  ._1G7hd5x8vsAd7uryeW6XMI {
    flex-wrap: wrap; }
    ._1G7hd5x8vsAd7uryeW6XMI ._3RSVOKYLiip1WiGerq-gi0 {
      display: unset; }
  ._3h-DWWVIXnRnHu-O18xkSB button:first-child {
    width: 108px !important;
    border-radius: 8px;
    margin-right: 10px; }
  ._3h-DWWVIXnRnHu-O18xkSB button:last-child {
    border: 1px solid black;
    width: 150px !important;
    font-weight: bold;
    border-radius: 8px; } }

@media screen and (max-width: 430px) {
  ._1G7hd5x8vsAd7uryeW6XMI {
    justify-content: center; }
  ._3RSVOKYLiip1WiGerq-gi0 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    ._3RSVOKYLiip1WiGerq-gi0 button:first-child {
      margin-right: 0px !important;
      margin-bottom: 5px; } }

@media screen and (max-width: 520px) {
  ._11RDA4R611D7i2QsJG567O {
    margin-left: -10px !important; } }

@media screen and (max-width: 385px) {
  ._11RDA4R611D7i2QsJG567O {
    margin-left: 20px !important; } }

._2qHhZydSJrmeaWQcMhxRmy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center; }
  ._2qHhZydSJrmeaWQcMhxRmy ._3--R9U7aGknb6jI7E7AQA1 {
    font-weight: bold;
    font-size: 34px !important;
    margin-bottom: 0; }
  ._2qHhZydSJrmeaWQcMhxRmy ._3xm-ngWdGRaGLV0WWVYd9X {
    margin-right: 10px;
    margin-top: 2px;
    cursor: pointer; }
  ._2qHhZydSJrmeaWQcMhxRmy ._29S9HmRmWOLu4Lb-6onZq0 {
    font-size: 30px !important; }
  ._2qHhZydSJrmeaWQcMhxRmy .lAdQWSSR8UbpaqJqYf2RN {
    margin-top: 0;
    margin-bottom: 0;
    color: #e8ebf0; }
  ._2qHhZydSJrmeaWQcMhxRmy .JCNGfvJULuHdZ6HyPxs5Z {
    margin-right: 15px;
    height: 26px; }
  ._2qHhZydSJrmeaWQcMhxRmy ._1LKCpt6kaNDPw6Rdim0aKj {
    margin-right: 15px;
    height: 32px; }
  ._2qHhZydSJrmeaWQcMhxRmy ._25VuFVgyuKPaWCq8fk3f6w {
    margin: 5px 18px 5px 8px; }
  ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV {
    padding: 0;
    background: #fff;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04); }
    ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba {
      border-radius: 8px;
      border: 1px solid #e8ebf0; }
      ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba ._3kSo5HsPoPsW-re_O8iWD5 {
        width: 100%; }
        ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba ._3kSo5HsPoPsW-re_O8iWD5:hover {
          background: #edf1f6; }
      ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba .u8nBOnnKyz82HwxDUrEJb {
        width: 100%; }
        ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba .u8nBOnnKyz82HwxDUrEJb:hover {
          background: #edf1f6; }
      ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba ._2HZkGRlkkXKFq15QMLF29C div {
        border-radius: 8px;
        border: 1px solid #e8ebf0;
        width: 100% !important;
        padding: 0 !important;
        z-index: 10000000; }
        ._2qHhZydSJrmeaWQcMhxRmy ._31N940ZIb0wIPhqbSI_2UV .K8r4GlkK9M4XiHwVhu4Ba ._2HZkGRlkkXKFq15QMLF29C div > a {
          padding: 4px !important;
          background: none;
          color: black; }

._3d0SaTwptEv-nkBCeCUNOE {
  margin-right: 20px !important; }
  ._3d0SaTwptEv-nkBCeCUNOE img {
    vertical-align: bottom;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 13px; }

.RFjYmL7UFthJJv2ZjW0zP {
  margin: 0; }

@media screen and (max-width: 507px) {
  ._2qHhZydSJrmeaWQcMhxRmy {
    flex-wrap: wrap;
    display: flex;
    justify-content: center; } }

@media screen and (max-width: 430px) {
  ._3--R9U7aGknb6jI7E7AQA1 {
    text-align: center;
    width: 100%; }
  ._31N940ZIb0wIPhqbSI_2UV {
    text-align: center;
    width: 100%;
    justify-content: center; } }

.react-transform-component.TransformComponent-module_container__3NwNd {
  max-width: 100% !important;
  height: 100% !important;
  padding-bottom: 20px;
  overflow: scroll !important;
  position: relative !important; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

._2sNgLDJqqTO5bC7RmHZGG7 {
  margin: 8px 0 !important;
  font-weight: 500 !important; }

._3aS0_l-hkUQ2J9Xkj7bis7 {
  display: inline-flex !important; }

._25uZzoYmxzfNsEBU8opn7u {
  display: flex;
  justify-content: center;
  align-items: center; }

._3EYChD67LdwLzVMKEB0ke- {
  background-color: #3c9;
  color: white; }
  ._3EYChD67LdwLzVMKEB0ke-:hover {
    background-color: #3c9;
    color: white; }

._3ebsNpqMLOGhAYcRk6JcGn {
  background-color: red;
  color: white;
  margin-right: 15px;
  border-radius: 10px; }
  ._3ebsNpqMLOGhAYcRk6JcGn:hover {
    background-color: red;
    color: white; }

.P1GcoM7CzFz5xoqkeuYfC {
  display: contents;
  flex-direction: column; }

._3Jfw0zIwTY0wVOd7i2GmeB {
  font-size: 7px;
  letter-spacing: -0.5px; }

._3j1unuMQAK3a3ivHQWmqwy:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer; }

._2Kzidc9iLs2QZcAVRwFSBL {
  float: right; }
  ._2Kzidc9iLs2QZcAVRwFSBL button {
    width: 120px;
    font-weight: bold;
    border-radius: 8px; }
  ._2Kzidc9iLs2QZcAVRwFSBL button:last-child {
    margin-left: 10px;
    border: 1px solid black; }

._1kMkKdWBW4edQHYqkj0QfZ {
  display: flex;
  padding: 0px 2px;
  width: 100%;
  justify-content: center;
  margin-top: 10px; }

._3uftq3IukG6y2do9N8A_od {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative; }

._1B66c1SvbJdVZWWL02AG_c {
  position: absolute;
  top: -25%;
  right: -5.2%;
  display: flex;
  justify-content: center;
  align-items: center; }

._1LbTDmrQEPM09Juc8b2jEJ {
  margin-right: 15px;
  display: inline-flex;
  border-radius: 1px !important; }

._3DQWxzb_Zxi2JkMmMF0CI7 {
  margin-right: unset !important;
  background: #3c9 !important;
  color: white;
  border: white; }
  ._3DQWxzb_Zxi2JkMmMF0CI7:hover {
    color: #3c9 !important;
    border-color: #3c9 !important;
    border-width: 2px;
    border-style: solid;
    background: white !important; }

._2Nw9YS5RPKEKX82ye1Ii8Y {
  border: groove; }

._9CWpTfT-rerS_M9c5UuX- {
  border: groove; }

._1GizATlm5IwhjB7NnMepq- {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

._1f7557CY1Tu02HYPtGDvNC {
  width: 90%;
  object-fit: contain;
  max-width: 100%; }

._2o5vbvYuF7ldbqmSAg5pLj {
  width: 600px;
  height: 600px;
  overflow: scroll; }

.R_Dgy7L62walo_fe6lxaD {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: normal; }

._3TGPWMYlZoigcOs4YEYXMz {
  color: white;
  padding-left: 2px; }

._17PhliutexTXC4wiZBkmIF {
  color: white;
  padding-right: 2px; }

.vuHcXB2X9Hmg5A6Xuchy8 {
  padding: 0px 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .vuHcXB2X9Hmg5A6Xuchy8 ._135ZDgXe8ENu2rOUemSkyv {
    font-weight: 600; }
    .vuHcXB2X9Hmg5A6Xuchy8 ._135ZDgXe8ENu2rOUemSkyv input {
      height: 17px !important;
      width: 17px !important;
      border-radius: 50%; }
    .vuHcXB2X9Hmg5A6Xuchy8 ._135ZDgXe8ENu2rOUemSkyv label {
      margin-left: 5px;
      font-weight: 600 !important;
      font-size: 16px; }
  .vuHcXB2X9Hmg5A6Xuchy8 ._1f7557CY1Tu02HYPtGDvNC {
    max-width: 100%;
    width: 90%; }
  .vuHcXB2X9Hmg5A6Xuchy8 .qRRztw2WId0QAljSXHIPl {
    max-width: 90%;
    overflow: overlay; }

.M6C8Y8kIJ2mcU8VbtquY0 {
  margin-left: 5px;
  width: 90%;
  object-fit: contain;
  max-width: 100%;
  height: 100% !important; }

._28BGkLDeXI1pEeXVkjF1IG {
  position: absolute;
  z-index: 11111111111111111111111111111111059;
  background: #000;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.8; }

.eSPqQSG90gTFMyZlmEUZT {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  flex-direction: row;
  align-items: center; }
  .eSPqQSG90gTFMyZlmEUZT ._3FsJBwzO8KerHGnQhLDeAd {
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .eSPqQSG90gTFMyZlmEUZT ._3FsJBwzO8KerHGnQhLDeAd b {
      margin-left: 15px;
      margin-right: 25px; }
    .eSPqQSG90gTFMyZlmEUZT ._3FsJBwzO8KerHGnQhLDeAd button {
      padding: 0 !important;
      margin-right: 14px;
      font-weight: 600; }
      .eSPqQSG90gTFMyZlmEUZT ._3FsJBwzO8KerHGnQhLDeAd button span {
        font-weight: 300; }
      .eSPqQSG90gTFMyZlmEUZT ._3FsJBwzO8KerHGnQhLDeAd button img {
        margin-right: 10px; }
  .eSPqQSG90gTFMyZlmEUZT button:last-child {
    font-weight: bold;
    border-radius: 8px; }

._3cCpDzcyNV-QW6pbAfjYN6 {
  float: right !important; }

._2T6_RnmOHgCiyv7d1QSFUB {
  font-weight: 700 !important;
  font-size: 1.2rem !important; }

._2RWFnCVzpCEqPkN8zhIp5S {
  width: 2px;
  background-color: #808b9a;
  margin-right: 10px;
  height: 25px; }

._3rsCyhQvWX2tlUbgOmA2Il {
  cursor: -webkit-grab;
  cursor: grab; }

.qRRztw2WId0QAljSXHIPl {
  max-width: 90%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: groove;
  margin-left: 40px;
  overflow-x: scroll; }

._3s5qXLoNrL37dsZBmJT6gK {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  position: relative;
  margin: 10px; }

.D8OmtTBdj-Ub9rwh7N1_p {
  position: absolute;
  height: 100%;
  width: 100%;
  resize: both;
  overflow: auto; }
  .D8OmtTBdj-Ub9rwh7N1_p .zFnd1n9BWRB_mvZNKnktC {
    position: relative;
    display: flex;
    align-items: center;
    width: 194px; }
  .D8OmtTBdj-Ub9rwh7N1_p ._28vwdeBM44dkRQzuspph0G {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 165px; }
  .D8OmtTBdj-Ub9rwh7N1_p .sKRp9o9C0XflKQCdwQnGp {
    position: relative;
    width: 190px; }
  .D8OmtTBdj-Ub9rwh7N1_p ._2ODZdXQM8kPdjTvWwL6Gsa {
    margin-top: -25px;
    height: 44px !important;
    right: -20px;
    position: absolute; }
  .D8OmtTBdj-Ub9rwh7N1_p ._15JF0sz0f6nhXMaQdOYI3S {
    max-height: 100px;
    width: 100px;
    object-fit: fill; }
  .D8OmtTBdj-Ub9rwh7N1_p ._1AIH8mXOjuPBRKW4VTNZwX {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    overflow: visible; }
  .D8OmtTBdj-Ub9rwh7N1_p .JbWiSNQOdq-ahI7-OgHN4 {
    display: none; }

._1jMkl0UMVlWB3W-X-8UZa5 {
  position: absolute;
  height: 100%;
  width: 100%;
  resize: both;
  overflow: auto; }
  ._1jMkl0UMVlWB3W-X-8UZa5 .zFnd1n9BWRB_mvZNKnktC {
    position: relative;
    display: flex;
    align-items: center;
    width: 194px; }
  ._1jMkl0UMVlWB3W-X-8UZa5 ._28vwdeBM44dkRQzuspph0G {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 165px;
    z-index: 99999 !important; }
  ._1jMkl0UMVlWB3W-X-8UZa5 .sKRp9o9C0XflKQCdwQnGp {
    position: relative;
    width: 190px; }
  ._1jMkl0UMVlWB3W-X-8UZa5 ._2ODZdXQM8kPdjTvWwL6Gsa {
    margin-top: -25px;
    height: 44px !important;
    right: -20px;
    position: absolute; }
  ._1jMkl0UMVlWB3W-X-8UZa5 ._15JF0sz0f6nhXMaQdOYI3S {
    max-height: 100px;
    width: 100px;
    object-fit: fill; }
  ._1jMkl0UMVlWB3W-X-8UZa5 ._1AIH8mXOjuPBRKW4VTNZwX {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    overflow: visible;
    z-index: 999999999 !important; }
  ._1jMkl0UMVlWB3W-X-8UZa5 .JbWiSNQOdq-ahI7-OgHN4 {
    display: none; }

.e26W0vvhNE0VeM-jeXaO8 {
  z-index: 1000; }

._1TKeRzJOPpwR2oXSCCk43 {
  position: absolute;
  margin-left: 32px;
  font-weight: bold; }
  ._1TKeRzJOPpwR2oXSCCk43 i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 15px; }
  ._1TKeRzJOPpwR2oXSCCk43 ._2cOpSBscdSoxaPrhTj-zzs {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

._1ptsSJtSnl5toKqPE-zeXE {
  margin-bottom: 20px;
  border-bottom: none; }

.fufFo4OyWM41vHkflz4BQ {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: 100000;
  justify-content: flex-end;
  display: flex;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: _3gc2RO4yDozWP-2RL8B9_-;
          animation-name: _3gc2RO4yDozWP-2RL8B9_-; }

.Yt8uP9QGYBj4ilwIeemIL {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: 100000;
  justify-content: flex-end;
  display: flex;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: _2JyO6O7JA7nHTgP570K-fb;
          animation-name: _2JyO6O7JA7nHTgP570K-fb; }

._1I1rm7rYthi9-lzoaSxFFs {
  background: red !important; }

._34pnLyf8N5mx2KZNzt-CGR {
  z-index: 1000;
  background: #000;
  width: 100%;
  right: 0;
  opacity: 0.8;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: _2JYp_FVgMaN1uaVToeaQhA;
          animation-name: _2JYp_FVgMaN1uaVToeaQhA; }

._1Egvw-YQuRUYoF30dIef6l {
  display: flex; }
  ._1Egvw-YQuRUYoF30dIef6l > button:first-child {
    margin-right: 10px;
    border-radius: 8px;
    font-weight: bold;
    width: 120px; }

._1dxFXyyqyFuFo9f3Lp1ylY {
  background: #fff;
  height: 100%;
  padding: 6px 10px;
  width: 60%;
  overflow-y: scroll; }
  ._1dxFXyyqyFuFo9f3Lp1ylY ._3NrmR3yGROhEgY4UOklnpd {
    display: flex;
    margin-bottom: 6px; }

#Un62hyvv9jS_UAnSmbYRf {
  margin-bottom: 2px; }

@-webkit-keyframes _1kvm6gPEkXl_Av74hInRKu {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@keyframes _1kvm6gPEkXl_Av74hInRKu {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  80% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

._1LekiroqqaoszMmqbjiWqC {
  -webkit-animation: _1kvm6gPEkXl_Av74hInRKu 1s forwards;
          animation: _1kvm6gPEkXl_Av74hInRKu 1s forwards; }

@-webkit-keyframes _2PV5dmFczkw441zcRZas0_ {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes _2PV5dmFczkw441zcRZas0_ {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

._2PV5dmFczkw441zcRZas0_ {
  -webkit-animation: _2PV5dmFczkw441zcRZas0_ 4s forwards;
          animation: _2PV5dmFczkw441zcRZas0_ 4s forwards; }

@-webkit-keyframes _3gc2RO4yDozWP-2RL8B9_- {
  0% {
    left: 100%; }
  100% {
    left: 0%; } }

@keyframes _3gc2RO4yDozWP-2RL8B9_- {
  0% {
    left: 100%; }
  100% {
    left: 0%; } }

@-webkit-keyframes _2JyO6O7JA7nHTgP570K-fb {
  0% {
    left: 0%; }
  100% {
    left: 100%; } }

@keyframes _2JyO6O7JA7nHTgP570K-fb {
  0% {
    left: 0%; }
  100% {
    left: 100%; } }

._1IbFQvVpr0PWYqUjRU20-q {
  position: absolute;
  color: #26619c;
  z-index: 999999; }

@media screen and (max-width: 1040px) {
  .eSPqQSG90gTFMyZlmEUZT {
    flex-wrap: wrap; } }

@media screen and (max-width: 1050px) {
  .react-transform-component.TransformComponent-module_container__3NwNd {
    width: 100% !important;
    overflow: scroll; }
  ._1f7557CY1Tu02HYPtGDvNC {
    max-width: 100%;
    width: 90%;
    object-fit: contain; }
  .M6C8Y8kIJ2mcU8VbtquY0 {
    max-width: 100%;
    width: 90%;
    overflow: overlay; }
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 10px; } }

@media screen and (max-width: 420px) {
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100% !important; }
    .D8OmtTBdj-Ub9rwh7N1_p ._1Veq39aq99hZwq6JlpSIN9 {
      height: 100px !important;
      width: 100px !important;
      border-radius: 50% !important; }
    .D8OmtTBdj-Ub9rwh7N1_p ._28vwdeBM44dkRQzuspph0G {
      width: 160px !important; } }

@media screen and (max-width: 450px) {
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100%; } }

@media (max-width: 570px) and (min-width: 490px) {
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100% !important; } }

@media screen and (max-width: 576px) {
  ._1f7557CY1Tu02HYPtGDvNC {
    width: 90%;
    object-fit: contain;
    max-width: 100%; }
  .M6C8Y8kIJ2mcU8VbtquY0 {
    margin: 30px 30px 20px 0px !important;
    object-fit: contain;
    max-width: 100%;
    width: 90%; }
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100%;
    margin-left: 0px; }
    .D8OmtTBdj-Ub9rwh7N1_p ._28vwdeBM44dkRQzuspph0G {
      width: 160px !important; }
    .D8OmtTBdj-Ub9rwh7N1_p ._2ODZdXQM8kPdjTvWwL6Gsa {
      height: 35px !important; }
  ._1dxFXyyqyFuFo9f3Lp1ylY {
    width: 100%; } }

@media screen and (max-width: 750px) {
  .D8OmtTBdj-Ub9rwh7N1_p {
    width: 100%; }
    .D8OmtTBdj-Ub9rwh7N1_p ._1Veq39aq99hZwq6JlpSIN9 {
      height: 150px;
      width: 150px;
      border-radius: 50% !important; }
    .D8OmtTBdj-Ub9rwh7N1_p ._2ODZdXQM8kPdjTvWwL6Gsa {
      height: 40px !important;
      right: 21px !important; }
    .D8OmtTBdj-Ub9rwh7N1_p ._28vwdeBM44dkRQzuspph0G {
      width: 160px !important; } }

@media screen and (max-width: 420px) {
  ._1dxFXyyqyFuFo9f3Lp1ylY {
    width: unset; } }

@media screen and (min-width: 900px) {
  ._1dxFXyyqyFuFo9f3Lp1ylY {
    width: 70%; } }

._3y4an6zNPi3-hQjtXEU703 > div:first-child {
  margin-bottom: 20px; }

._3y4an6zNPi3-hQjtXEU703 label {
  font-weight: bold;
  font-size: 14px !important; }

._3y4an6zNPi3-hQjtXEU703 p {
  font-weight: bold; }

._1X-q5WM_7f5L0X4w3cMWMi {
  color: grey;
  font-size: small; }

.uW-ualycWWBvhwG59gRaI {
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  padding: 0 0 9px 0; }
  .uW-ualycWWBvhwG59gRaI p {
    font-weight: bold;
    display: flex;
    align-items: center; }
  .uW-ualycWWBvhwG59gRaI div {
    display: flex;
    justify-content: space-between; }
    .uW-ualycWWBvhwG59gRaI div div {
      align-items: center; }
      .uW-ualycWWBvhwG59gRaI div div div a {
        color: darkgray !important;
        font-weight: bold; }
      .uW-ualycWWBvhwG59gRaI div div div .active {
        color: #3c9 !important;
        border-bottom: 1px solid lightgray !important;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important; }
    .uW-ualycWWBvhwG59gRaI div button {
      padding: 0 !important; }
    .uW-ualycWWBvhwG59gRaI div p {
      margin: 0 !important; }

._3FoVbvQx8O4wSPyH2LX08I {
  width: 100%;
  margin-bottom: 6px; }
  ._3FoVbvQx8O4wSPyH2LX08I label {
    border-color: #d6d5d5 !important; }
  ._3FoVbvQx8O4wSPyH2LX08I .focus {
    background-color: #0b71ff !important;
    color: white !important;
    box-shadow: none; }
  ._3FoVbvQx8O4wSPyH2LX08I label {
    background-color: white;
    color: black; }
    ._3FoVbvQx8O4wSPyH2LX08I label:hover {
      background-color: white;
      color: black; }

._3dM7_HwLOlmCmXqc2vsPSo {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; }
  ._3dM7_HwLOlmCmXqc2vsPSo button {
    border-radius: 8px;
    width: 120px;
    font-weight: bold; }
  ._3dM7_HwLOlmCmXqc2vsPSo ._2ALDZq9QyyjUOTnk7c5-7d ._3ivodFyw44PfheB9XeMYA {
    margin-right: 10px;
    border: 1px solid black; }

._7WXvnvABbjFUbsj2sByJB {
  margin-bottom: 30px; }

._2tVv7cwEU9bM8Y4q2U6_0E {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

._1wGy2Ck0DSZHR_5ReaaPvu {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0 !important; }
  ._1wGy2Ck0DSZHR_5ReaaPvu span {
    font-size: 12px;
    color: #0b71ff; }

._3FBxnk_4pSHsGSZGr1QKDX {
  margin-bottom: 15px !important; }

.HcoEV4oJX4ymRll6fmJqI {
  width: 80%; }

.abNPsTUhCdYZPZXUQDWr5 {
  width: 10%; }

.RhHi2zRx8e9RvQF8MUuB8 {
  font-weight: 600; }
  .RhHi2zRx8e9RvQF8MUuB8 input {
    height: 17px !important;
    width: 17px !important; }
  .RhHi2zRx8e9RvQF8MUuB8 label {
    margin-left: 5px;
    font-weight: 600 !important; }

@media screen and (max-width: 500px) {
  ._3dM7_HwLOlmCmXqc2vsPSo {
    flex-wrap: wrap; }
    ._3dM7_HwLOlmCmXqc2vsPSo button {
      width: unset !important; } }

@media screen and (max-width: 990px) {
  .uW-ualycWWBvhwG59gRaI div {
    flex-wrap: wrap; } }

._1Rb3atd7qlZlW2OkFa3rBS {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._3EA8P1sftuB0SfdZd6xhhM {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

._3x1RW2mYYNxgRRlPTPLBLA {
  display: block; }

.eqbtwjtiNAnnnYylGnJ0i {
  display: none; }

.PmoEVCxum1uPiaLxmRwCF {
  min-height: 522px; }

._29QqsoDLwReMWMgpWOG7of {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._15jnM0AiVKsbSzYDs0Sf-t {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

.search-wrapper span {
    font-size: 11px !important;
}

@media screen and (max-width: 460px) {
    .search-wrapper span {
        font-size: 9px !important;
    }
}
._3mXynMt7ZfR8gHKaYeODU0 {
  min-height: 522px; }

._1pZ4niZxQIg-8YfQetExvO {
  min-height: 522px; }

._2TWlzwvrBlJ-EI7nuVhkmJ {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._1hMYAMhnNwdirZJAEc75G4 {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

._3186XCTlhiXRTZrrO9Pu_Z {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da; }
  ._3186XCTlhiXRTZrrO9Pu_Z:focus {
    color: #495057 !important;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

._1nfzWRVBbZ64qoJXgL35hJ {
  font-size: 1.5rem !important;
  font-weight: 600 !important; }

._2_Aurxckcg7weHlkwJic5b > div:first-child {
  margin-bottom: 20px; }

._2_Aurxckcg7weHlkwJic5b label {
  font-weight: bold;
  font-size: 14px !important; }

._2_Aurxckcg7weHlkwJic5b p {
  font-weight: bold; }

._32fAMLA4OrZksEnAB-9krC {
  min-height: 422px; }

.k7eGLDumr5JLaUsuux3oA {
  color: #0b71ff; }

._1a0aBAsDGuHHNPbD-C5ayX {
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  padding: 0 0 9px 0; }
  ._1a0aBAsDGuHHNPbD-C5ayX p {
    font-weight: bold;
    display: flex;
    align-items: center; }
  ._1a0aBAsDGuHHNPbD-C5ayX div {
    display: flex;
    justify-content: space-between; }
    ._1a0aBAsDGuHHNPbD-C5ayX div div {
      align-items: center; }
      ._1a0aBAsDGuHHNPbD-C5ayX div div div a {
        color: darkgray !important;
        font-weight: bold; }
      ._1a0aBAsDGuHHNPbD-C5ayX div div div .active {
        color: #3c9 !important;
        border-bottom: 1px solid lightgray !important;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important; }
    ._1a0aBAsDGuHHNPbD-C5ayX div button {
      padding: 0 !important; }
    ._1a0aBAsDGuHHNPbD-C5ayX div p {
      margin: 0 !important; }

._1CtFOr5EXpFOZsLL-OuTtb {
  width: 100%;
  margin-bottom: 6px; }
  ._1CtFOr5EXpFOZsLL-OuTtb label {
    border-color: #d6d5d5 !important; }
  ._1CtFOr5EXpFOZsLL-OuTtb .focus {
    background-color: #0b71ff !important;
    color: white !important;
    box-shadow: none; }
  ._1CtFOr5EXpFOZsLL-OuTtb label {
    background-color: white;
    color: black; }
    ._1CtFOr5EXpFOZsLL-OuTtb label:hover {
      background-color: white;
      color: black; }

._1QzUCv7W_vSiwedKkcLxv9 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; }
  ._1QzUCv7W_vSiwedKkcLxv9 button {
    border-radius: 8px;
    width: 120px;
    font-weight: bold; }
  ._1QzUCv7W_vSiwedKkcLxv9 ._2hlWxeh_Yu05Ji-SFiry6R .A0nCbF62wYk3yOssToI8Z {
    margin-right: 10px;
    border: 1px solid black; }

._3knVVZydqk5rOmsw_iCXvJ {
  margin-bottom: 30px; }

._142szwN21cEGO_Y_0PpTSZ {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

._3rt33suC3qe9fRAbdBVxe8 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0 !important;
  padding-right: 15px; }
  ._3rt33suC3qe9fRAbdBVxe8 span {
    font-size: 12px;
    color: #0b71ff; }

._1o8q6l6-4ewW-zELWW2rls {
  margin-bottom: 15px !important; }

._3eNCMDYikhHx86c5HGScL6 {
  width: 80%; }

._1NruQAVNXat6ssU1RiXfVv {
  width: 10%; }

.b4-xc6-r-C8PVAfSGLEb4 {
  font-weight: 600; }
  .b4-xc6-r-C8PVAfSGLEb4 input {
    height: 17px !important;
    width: 17px !important; }
  .b4-xc6-r-C8PVAfSGLEb4 label {
    margin-left: 5px;
    font-weight: 600 !important; }

@media screen and (max-width: 500px) {
  ._1QzUCv7W_vSiwedKkcLxv9 {
    flex-wrap: wrap; }
    ._1QzUCv7W_vSiwedKkcLxv9 button {
      width: unset !important; } }

@media screen and (max-width: 990px) {
  ._1a0aBAsDGuHHNPbD-C5ayX div {
    flex-wrap: wrap; } }

._3oRUy1EKgBA7SWx6a2TTCq {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._1UeY5RQgdm7gU2LCvVyQRc {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

._3UXE_UJnfkuFWS3PjzgjL8 {
  color: #0b71ff; }

._3cxL3NSTN4tw9eOCvpqAy9 {
  padding-right: 15px; }

.n4QdkkyYnLCWdpWjP_06X {
  min-height: 422px; }

._3A0DbmVTxt_jUhfp5e_9V {
  display: block; }

._3RDPcXR8oHdhaSM1Apey1E {
  display: none; }

._1OLdkripDYBRHt8GlVYB7y {
  min-height: 422px; }

.EaHPFQepkqbPgebhM3Jb3 {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._3jUQox2hloVhWSSiTPvyIu {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

.y0E_0tBf8K2FiKo82YHLr {
  color: #0b71ff; }

.GaBolGaEMcm_VYUY8UoyW {
  padding-right: 15px; }

._1aWsrgD1dzAdXb1DOHX2oJ {
  min-height: 422px; }

.uZ8W1DZ6qxuwVdEu4wZij {
  min-height: 522px; }

._1bQptEvPjzc4kxtTYqmzQc {
  background-color: #0b71ff !important;
  color: white !important;
  box-shadow: none; }

._1caVU7q-fUCYDwxKUgr49w {
  background-color: white !important;
  color: black !important;
  box-shadow: none; }

.Rf5aFTi_8OJm5M-5auDwR {
  padding-right: 15px; }

._1_2nPhrQW5gCZLuvUfVIoj {
  color: #0b71ff; }

._2B7FNSZDtIhqA7rTT1Y3aL {
  float: right; }
  ._2B7FNSZDtIhqA7rTT1Y3aL button {
    width: 150px;
    border-radius: 4px;
    font-weight: bold; }
  ._2B7FNSZDtIhqA7rTT1Y3aL button:first-child {
    margin-right: 10px; }

._312jBHdTdBdwTs6nG98KY7 {
  float: right; }
  ._312jBHdTdBdwTs6nG98KY7 button {
    width: 150px;
    border-radius: 4px;
    font-weight: bold; }
  ._312jBHdTdBdwTs6nG98KY7 button:first-child {
    margin-right: 10px; }

._39ONZezaFBua2lvuxr3-of {
  position: absolute;
  /* top: 59%; */
  right: 30%;
  left: 30%;
  margin-top: 25%; }

._3jio7XBmmkN6sYFAXaekfm {
  margin-top: 10px;
  text-align: center;
  color: #fff;
  font-weight: 600; }

._3LV_eFKFf3_zctUdd5ARHn {
  font-size: 12px;
  color: #fff;
  text-align: center; }

.BBRLp6r5JrmzL9eG052vR {
  position: absolute;
  z-index: 99999999999999999;
  background: #000;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.8; }

.xqWMJd2z5ByhmLtUnfenD {
  position: absolute;
  background: #000;
  width: 100%;
  z-index: 999999999999999999;
  right: 0;
  opacity: 0.8;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: Eq8JMU0itSQVhspex2uw6;
          animation-name: Eq8JMU0itSQVhspex2uw6; }

._2Om13nDlwM_TewcTGOFJXs {
  display: flex;
  align-items: center; }

._2I_U8hI0MxzlbGxpPBVaM9 {
  display: flex;
  flex-direction: column; }

._2p2rotA-LAgSa9FkRnI-C7 {
  font-weight: bold;
  margin-top: 10px; }

._2BnFaOxCnHuiqVvSQy6dz9 {
  font-weight: bold; }
  ._2BnFaOxCnHuiqVvSQy6dz9 i {
    color: #3fcc99 !important;
    margin-right: 10px; }

._3e8EUkFpwTB8aMOPL6Jg6d {
  font-weight: 600; }
  ._3e8EUkFpwTB8aMOPL6Jg6d i {
    color: #3fcc99 !important;
    margin-right: 10px; }

._3zo2_k0bWEatFwxDE7vmeq {
  padding: 5% 0px; }
  ._3zo2_k0bWEatFwxDE7vmeq label {
    font-size: 14px;
    font-weight: bold !important;
    color: #031937; }

._3x0Yq6Kg9fjo9IEKWKzrAh {
  justify-content: center;
  display: flex;
  flex-direction: column; }

._1dl4-7WHc8EYKJfKDtSYpW {
  margin-top: 10px; }

._3-rJp_2kRMqD4QhTJyTUyf {
  height: 200px;
  width: 200px;
  object-fit: contain;
  align-self: center; }

@media screen and (max-width: 990px) {
  ._3-rJp_2kRMqD4QhTJyTUyf {
    height: 200px;
    width: 200px;
    border-radius: 50% !important; } }

._3cUSrRJmOVXd44MsfZ-3FQ {
  display: flex;
  justify-content: center; }

._2K7tFNXLDlgMfTdeznYVi3 {
  align-items: center;
  display: flex;
  border: 2px solid black;
  justify-content: center;
  height: 180px;
  width: 180px;
  position: relative; }
  ._2K7tFNXLDlgMfTdeznYVi3 ._1jBU2MGYdi7_98Ugz2L-Ez {
    border: 2px solid black;
    position: absolute;
    height: 135px;
    width: 135px;
    border-radius: 75px; }
    ._2K7tFNXLDlgMfTdeznYVi3 ._1jBU2MGYdi7_98Ugz2L-Ez > p:first-child {
      position: absolute;
      left: 25%;
      font-weight: bold;
      text-align: center;
      top: 10%;
      word-break: break-word;
      width: 70px; }
    ._2K7tFNXLDlgMfTdeznYVi3 ._1jBU2MGYdi7_98Ugz2L-Ez p:last-child {
      position: absolute;
      bottom: -38px;
      font-weight: bold;
      left: 50%; }

._3AgctvwJ2HzW1bKaypJDxJ {
  align-items: center;
  display: flex;
  border: 2px solid black;
  justify-content: center;
  align-self: center;
  height: 80px;
  width: 180px;
  border-radius: 5px;
  position: relative; }
  ._3AgctvwJ2HzW1bKaypJDxJ ._1jBU2MGYdi7_98Ugz2L-Ez {
    border: 2px solid black;
    position: absolute;
    height: 35px;
    width: 135px;
    border-radius: 5px; }
    ._3AgctvwJ2HzW1bKaypJDxJ ._1jBU2MGYdi7_98Ugz2L-Ez > p:first-child {
      position: absolute;
      left: 25%;
      font-weight: bold;
      text-align: center;
      top: 10%;
      word-break: break-word;
      width: 70px; }
    ._3AgctvwJ2HzW1bKaypJDxJ ._1jBU2MGYdi7_98Ugz2L-Ez p:last-child {
      position: absolute;
      bottom: -38px;
      font-weight: bold;
      left: 50%; }

.vXk0ytnbCEF6YtruBrqRU input {
  background: white !important; }

.vXk0ytnbCEF6YtruBrqRU ._1ZWk7XHFcCyUEHOSsU5fbp {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

.vXk0ytnbCEF6YtruBrqRU span {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: bold;
  display: inline-block; }

.vXk0ytnbCEF6YtruBrqRU ._1J3E-EAnogW4ZxM9KUrp56 {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

._3l6SIanBLPQdTE3elbMshT {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._2OPFaD-F4rmnw2ch0fbMz2 {
  padding: 12px 30px 0px 30px; }

._3HKqbdvUc0ZxXhcO_2KvFX {
  color: #3fcc99 !important;
  margin-right: 10px; }

._3KY5n8VjD5OVs9Hqv_VrV2 {
  border-bottom: none;
  margin-bottom: 30px;
  margin-top: 10px; }

._2iN-Ui4ayvk0HhjSeVN6FL {
  min-height: 80px; }

._2sm79OwTU7hoG0jEKYn0ti {
  float: right; }
  ._2sm79OwTU7hoG0jEKYn0ti button {
    width: 120px;
    font-weight: bold;
    border-radius: 8px; }
  ._2sm79OwTU7hoG0jEKYn0ti button:last-child {
    margin-left: 10px;
    border: 1px solid black; }

._2EKGNxDNndmS1CrEkvyo07 {
  justify-content: space-between;
  display: flex; }
  ._2EKGNxDNndmS1CrEkvyo07 button {
    border-radius: 8px;
    width: 120px; }
  ._2EKGNxDNndmS1CrEkvyo07 button:first-child {
    border: 1px solid black; }

@media screen and (max-width: 380PX) {
  ._2EKGNxDNndmS1CrEkvyo07 button {
    width: unset !important; } }

.QI4H-6mb_vs2A5-Fo8VJx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: normal; }

._2228i0QFsGI283XIfe5HTv {
  color: white;
  padding-left: 2px; }

._2tqo9uU2lxRJ1VUPmufckz {
  color: white;
  padding-right: 2px; }


.vJF4ZDzkbqpV2i8uQ5EBf {
  font-weight: 600; }
  .vJF4ZDzkbqpV2i8uQ5EBf i {
    color: #3fcc99 !important;
    margin-right: 10px; }

._106tSiLtImawpofeDZMYGx {
  color: #6c757d;
  margin-top: 20px; }

._15cBTEDrhi40-lEiv7AKcZ {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  ._15cBTEDrhi40-lEiv7AKcZ > p:first-child {
    font-weight: bold; }

._31JLbsF6_eL6811da0fGEf {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  object-fit: contain;
  align-self: center;
  margin-bottom: 10px; }

._2wh9LbKBCvMg3FRRt33K6Z {
  display: flex;
  align-items: center;
  margin-top: -2%;
  justify-content: center;
  flex-direction: column; }
  ._2wh9LbKBCvMg3FRRt33K6Z > p:first-child {
    font-weight: bold; }
  ._2wh9LbKBCvMg3FRRt33K6Z p:last-child {
    font-size: 50px !important;
    word-break: break-word;
    text-align: center; }

._2cbrfyrDjHQN4YFdJFU3O_ {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

._2Z5_6BCOeI8fyQYPrVAVjL {
  padding: 10% 0; }

._2eY2gnURbTZMrqwS1or_eH {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none; }
  ._2eY2gnURbTZMrqwS1or_eH div:first-child {
    width: 100% !important; }
    ._2eY2gnURbTZMrqwS1or_eH div:first-child > ul li:last-child {
      display: none !important; }
    ._2eY2gnURbTZMrqwS1or_eH div:first-child button {
      background: none;
      border-radius: 2px; }

._2NDVk9pLZfMVL-uk7esuM- {
  font-size: 14px;
  font-weight: bold; }

._1Omcmdvfo3G1qqjVQnxp0A {
  color: #504bea !important;
  padding-left: 10px;
  padding-right: 15px; }

._1GOluhvN57LemTQbB7nNCO {
  float: right !important; }

.VwRYj0IHsqGu_BU1SbNNO {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .VwRYj0IHsqGu_BU1SbNNO ._3EJTQBUc-tyzVudkSqLxLp {
    position: absolute;
    right: 0;
    font-weight: 600; }
    .VwRYj0IHsqGu_BU1SbNNO ._3EJTQBUc-tyzVudkSqLxLp i {
      color: #e92727 !important; }
  .VwRYj0IHsqGu_BU1SbNNO ._1yaq2REJPlHe82g_3klmr8 {
    opacity: 0.4;
    margin-bottom: 15px;
    position: relative; }
  .VwRYj0IHsqGu_BU1SbNNO ._1sTCMjXNyUZQViWFatLQx1 {
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    position: relative; }
    .VwRYj0IHsqGu_BU1SbNNO ._1sTCMjXNyUZQViWFatLQx1:hover {
      cursor: pointer; }
  .VwRYj0IHsqGu_BU1SbNNO ._2T5bHv-fgxxNi66HrAztAL {
    text-decoration: none;
    color: black;
    margin-right: 15px; }
  .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl {
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px; }
    .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl ._21EkHUqIjBbWKeOkbf4M1r {
      display: flex;
      overflow-x: overlay;
      align-items: center;
      height: 180px;
      width: 180px;
      font-size: 32px; }
      .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl ._21EkHUqIjBbWKeOkbf4M1r p {
        font-size: 32px;
        color: blue !important; }
    .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl .Dhn3al-2YZu5QZSxdMK2s {
      height: 180px;
      width: 180px;
      object-fit: contain; }
    .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl ._2GAOFytHEKQfP6at_J7mgy {
      height: 180px;
      width: 180px;
      object-fit: contain; }
    .VwRYj0IHsqGu_BU1SbNNO .JTuPxvgOLtfbFIqmhOxcl ._2qesPQLEVE_2kAYpgxYa-6 {
      position: absolute;
      font-size: 41px;
      color: blue;
      opacity: 0.8;
      left: 4%;
      top: 50%; }

._2qc08Pq6PdunnUh5aZeuCh {
  position: absolute;
  bottom: 0px; }
  ._2qc08Pq6PdunnUh5aZeuCh ._1371mclxVrO8pHwQO6q90x button {
    border: 1px solid black; }

._3EjGa1BLRKm8twsXgG3TI6 {
  margin-top: 13px; }
  ._3EjGa1BLRKm8twsXgG3TI6 p {
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px; }
  ._3EjGa1BLRKm8twsXgG3TI6 ._1371mclxVrO8pHwQO6q90x {
    display: flex;
    justify-content: center; }
    ._3EjGa1BLRKm8twsXgG3TI6 ._1371mclxVrO8pHwQO6q90x button {
      border: 1px solid black; }
      ._3EjGa1BLRKm8twsXgG3TI6 ._1371mclxVrO8pHwQO6q90x button:hover {
        cursor: pointer; }

._1aJu6WAg4zNLyTBUsH0_xb {
  padding: 80px 10px 80px 20px; }
  ._1aJu6WAg4zNLyTBUsH0_xb label {
    font-size: 14px;
    font-weight: bold !important;
    color: #031937; }

._2V5x37gFXZ-8KJHA7dEP1s {
  display: flex;
  align-items: center; }
  ._2V5x37gFXZ-8KJHA7dEP1s img {
    height: 180px;
    width: 180px;
    object-fit: contain; }

._2x-Aij8vg4X8O1oU4PSnAw {
  position: absolute;
  /* top: 59%; */
  right: 30%;
  left: 30%;
  margin-top: 25%; }

._1TLBAV86oQOQLTVpj2nWL9 {
  margin-top: 10px;
  text-align: center;
  color: #fff;
  font-weight: 600; }

._2ZxQFfjjayCp8gz1EET-yM {
  font-size: 12px;
  color: #fff;
  text-align: center; }

._-3LkasLJ35iL401l3gh6z {
  position: absolute;
  z-index: 99999999999999999;
  background: #000;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.8; }

._3G-iuFBrCwgjVAfAcwRaO5 {
  position: absolute;
  background: #000;
  width: 100%;
  z-index: 999999999999999999;
  right: 0;
  opacity: 0.8;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: _3kuFG7LGd5V0UA0MMdEKkQ;
          animation-name: _3kuFG7LGd5V0UA0MMdEKkQ; }

.NysKknONoBKQRbmwJPD {
  padding: 16px !important; }

._3Pf3-6yygn5NgiUg9ThVAq {
  cursor: pointer;
  color: black; }
  ._3Pf3-6yygn5NgiUg9ThVAq:hover {
    text-decoration: none;
    color: black; }
  ._3Pf3-6yygn5NgiUg9ThVAq .o_fc9NYZVPGfzRVBDl1m8 {
    padding: 16px;
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid #F1F1F4;
    background: #F8FAFC; }

.d6HPT53PzjVSGAJ194vB1 {
  height: 4px;
  background: #04B5FF;
  border-radius: 8px 8px 0px 0px; }

._2TLXccp4k4nXyiDrnx0k5m {
  position: absolute;
  top: 50%; }

._2q2V7TBAQRbMgUikX9GzYu {
  background: #edf1f6;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  margin-bottom: 10px; }
  ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 {
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04); }
    ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 ._2BpwDHOF0X8kp0wv-BJqWa {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      margin-top: -5px; }
    ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 .A9n1erngUt4j40IOvmrs6 {
      color: #0F172A;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; }
    ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 p {
      margin-bottom: 5px; }
    ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 ._2bx3IqR9v7_SfH39x4y9se {
      color: #000;
      font-weight: 600; }
    ._2q2V7TBAQRbMgUikX9GzYu ._3dLFpcXufBg5GyOzkHNu74 ._121aiB2FRILeFPNUqGAp4C {
      color: #04B5FF;
      font-weight: 400; }

._2uDsZBpQnvkLfZhhEWQVtI {
  font-size: 16px;
  font-style: normal;
  line-height: normal; }

._2SoPjagcktsT48WY9UT4X_ {
  padding: 12px 30px 0 30px; }

._15XhzBUOuQ_Fhg0qZCrI1V {
  display: flex;
  height: 216px;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/SignatureBackground.6421357a.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  color: #475569;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal; }

._2bVZpPfoJ4BPW7crQi9PxG {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

@media screen and (max-width: 360px) {
  ._2bVZpPfoJ4BPW7crQi9PxG {
    justify-content: center !important; } }

@media screen and (max-width: 570px) {
  ._2-WuFyOvEsytVTv7Y5kuQf {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #fff;
    z-index: inherit; } }

._3896BOCmD4iehIDlGUPEs7 {
  color: #56f556 !important;
  margin-right: 10px; }

._1xTAysNjSDhFoBSYUk3Uks {
  display: flex;
  justify-content: center;
  flex-direction: row; }

._38ve5IUJyf3wHCXhThiJLH {
  margin-right: 8px; }

._2FZTJIrSVsWaYkyWlaD9-B {
  cursor: default;
  border: 1px solid #e8ebf0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px; }

._1N5-dR1-xrcstCAEvPELnZ {
  border-color: lime; }

._2Wl5IhZ7P4l0zypMyVzk7I {
  border-color: red; }

._3qKkzkNLzxstWXwchS12zp {
  border: 1px dashed #d5d2f3;
  min-height: 197px;
  min-height: 200px !important;
  border-radius: 6px;
  display: flex;
  background: #f9f9f9;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px; }
  ._3qKkzkNLzxstWXwchS12zp:focus {
    border: 1px solid #ebe2e2 !important; }
  ._3qKkzkNLzxstWXwchS12zp:active {
    border: 1px solid #ebe2e2 !important; }
  ._3qKkzkNLzxstWXwchS12zp:hover {
    cursor: pointer; }
  ._3qKkzkNLzxstWXwchS12zp ._3q3tmwm1wetUsFXhld-Tk0 {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 600;
    font-size: 22px; }
  ._3qKkzkNLzxstWXwchS12zp ._1xqkv7KlvNuh6GBUJlYoxV {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 600; }
  ._3qKkzkNLzxstWXwchS12zp ._13gJ_s9eKwukNCUL4-APFh {
    font-size: small;
    color: #7a7474; }

._9FQaQ4w0mfySu_o1nvLba {
  border: 1px dashed #d5d2f3;
  min-height: 270px !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  ._9FQaQ4w0mfySu_o1nvLba:focus {
    border: 1px solid #ebe2e2 !important; }
  ._9FQaQ4w0mfySu_o1nvLba:active {
    border: 1px solid #ebe2e2 !important; }
  ._9FQaQ4w0mfySu_o1nvLba ._3q3tmwm1wetUsFXhld-Tk0 {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 600;
    font-size: 22px; }
  ._9FQaQ4w0mfySu_o1nvLba ._1xqkv7KlvNuh6GBUJlYoxV {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 600; }
  ._9FQaQ4w0mfySu_o1nvLba ._13gJ_s9eKwukNCUL4-APFh {
    font-size: small;
    color: #7a7474;
    width: 60%;
    text-align: center; }

._1zQdJTqRm77JEPw-YLFT24 {
  border: 1px dashed #d5d2f3;
  min-height: 270px !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  ._1zQdJTqRm77JEPw-YLFT24:focus {
    border: 1px solid #ebe2e2 !important; }
  ._1zQdJTqRm77JEPw-YLFT24:active {
    border: 1px solid #ebe2e2 !important; }
  ._1zQdJTqRm77JEPw-YLFT24 ._3q3tmwm1wetUsFXhld-Tk0 {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 600;
    font-size: 22px; }
  ._1zQdJTqRm77JEPw-YLFT24 ._1xqkv7KlvNuh6GBUJlYoxV {
    margin-top: 10px;
    color: #0f172a;
    font-weight: 500; }
  ._1zQdJTqRm77JEPw-YLFT24 ._13gJ_s9eKwukNCUL4-APFh {
    font-size: small;
    color: #7a7474;
    width: 60%;
    text-align: center; }

._14xxROp48rPRi3jfwOY-nF {
  padding: 5% 0 10% 0 !important;
  text-align: center; }
  ._14xxROp48rPRi3jfwOY-nF ._2wgA9eI7doOamZLKoxjIDz {
    width: 92%;
    margin: auto; }
  ._14xxROp48rPRi3jfwOY-nF p {
    font-weight: bold;
    font-size: 14px; }

.Sn_8GCSBOt2eAI8ugmftW {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .Sn_8GCSBOt2eAI8ugmftW:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer; }
  .Sn_8GCSBOt2eAI8ugmftW .cVcXyZ45rgiDEZ7Kkvbm1 {
    height: 49px;
    width: 49px;
    border-radius: 8px; }

._3mRyrDV1QC8zI0pOVxfH2y {
  margin-bottom: 0 !important;
  margin-top: 20px;
  font-weight: bold; }

._3CJ9spX7qlK9xjGDaqZ1Eh {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

@media screen and (max-width: 410px) {
  .cVcXyZ45rgiDEZ7Kkvbm1 {
    width: 43px; } }

.XZLkXKbCXkoMktvOF5Qo {
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 1px 0;
  border: 1px solid #E8EBF0;
  border-radius: 8px;
  background: #fff; }

._21U_po6KkE-3uZUSgnMx-I {
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 20px;
  height: 20px; }

._3pKys5J18b6ZQnWzgQraHz {
  width: 100%;
  border: 0;
  margin-right: 2px; }
  ._3pKys5J18b6ZQnWzgQraHz:focus {
    outline: 0; }
  ._3pKys5J18b6ZQnWzgQraHz::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px; }
  ._3pKys5J18b6ZQnWzgQraHz::-moz-placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px; }
  ._3pKys5J18b6ZQnWzgQraHz:-ms-input-placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px; }
  ._3pKys5J18b6ZQnWzgQraHz::-ms-input-placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px; }
  ._3pKys5J18b6ZQnWzgQraHz::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px; }

._25HbSvLDXHajtsudlk9LEW {
  width: 1px;
  height: 1em;
  background: black;
  margin: 0 20px; }

._2s4RO6r9uRDgxVEobNtqnu {
  text-align: right;
  padding-top: 0; }

.lY8rkSQF53iggcsaUXvSF {
  padding: 30px !important; }

._1yrSJM2oDdFoy3idzp2a2X {
  font-weight: 700;
  color: black;
  padding: 14px 0; }

._3-EgEuXhROEnaqYPnwS8yo button {
  width: 120px;
  font-weight: bold;
  border-radius: 8px; }

.NgNsxu5i1MtEN7LxMvOAv {
  border-bottom: solid #bdc2ca 1px; }

.jRPKuBQDwIwYNtt5ewlQ3 {
  margin-top: 10px; }

._3MOjEs3HR_M0oiBfwwHEa {
  color: #1275ff;
  font-weight: 700; }

._3iys0JUkYFsMU8y_saKe-Z {
  color: #808b9a;
  font-weight: 600; }

._2ICGv11x91glWRUlMRq_AM {
  padding: 10px 30px 0 10px; }

._1Uq4J_R1Z3A4bkAEy_h9os {
  margin: 0; }
  ._1Uq4J_R1Z3A4bkAEy_h9os tr td {
    padding: 8px; }
  ._1Uq4J_R1Z3A4bkAEy_h9os th {
    color: #808b9a;
    font-weight: 600;
    width: 42%; }
  ._1Uq4J_R1Z3A4bkAEy_h9os td:nth-child(2) {
    font-weight: 700; }
  ._1Uq4J_R1Z3A4bkAEy_h9os td:nth-child(3) {
    text-align: right; }
  ._1Uq4J_R1Z3A4bkAEy_h9os td {
    vertical-align: middle; }

.LtaQePQn47HCBhFGLE7dA {
  position: inherit; }

._36XMcqxrm3JK40Qof7G34W {
  font-size: 1.3em;
  letter-spacing: 0; }

.SBz5fm-yQL1qj6q19ezqc {
  background: #fff !important; }

._2f8Nq2ob2vehSJBkqfgaZp {
  width: 100%; }

._2HgmOQPqyTM7LyjNeIqqnU {
  padding: 5% 35%; }

._3-k_1Hb9dk-Mkh2LzYgLK8 {
  height: 80%; }

.CLAtNAgQLYoO1uPfpwxkG {
  padding: 5px;
  width: 190px;
  border: 1px solid #e4dfdf;
  font-weight: bold;
  border-radius: 4px; }
  .CLAtNAgQLYoO1uPfpwxkG:focus {
    outline: 0; }

._3brXJ3Y0mny84MYbKDL7h5 {
  background: white;
  font-weight: bold;
  border: none; }

.NkChv1Z8XSsKoZAVAyHa7 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: normal; }

.U5QA0k9_hycVSEK_an1k4 {
  color: white;
  padding-left: 2px; }

.QlHih1i2hjQFJSe3XcCFk {
  color: white;
  padding-right: 2px; }

.Y-KSO4uLNSEjl4uiF7av2 {
  display: flex; }

._3rbCYbD0P6TkcRwQsHlsGh {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.tArUc4ILCzex4AjmNBC75 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

._1-8ol9x8WjNBFwS6RjXQ_Y {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

._3BTdcWs3VxprO5rwEsU7-5 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

@media screen and (max-width: 978px) {
  ._2HgmOQPqyTM7LyjNeIqqnU {
    padding: 5% 20%;
    align-items: stretch; } }

@media screen and (max-width: 765px) {
  ._1Uq4J_R1Z3A4bkAEy_h9os {
    margin-top: 20px; } }

._3_srzfEnSdcuZCvoTHLpgc {
  padding: 25px 50px; }
  ._3_srzfEnSdcuZCvoTHLpgc ._1-1W9t-JY65HcN25CtSZT9 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    height: auto; }
  ._3_srzfEnSdcuZCvoTHLpgc ._1uqG-m_qngDRl5fzpkcVBd {
    margin-top: 2.5%; }
  ._3_srzfEnSdcuZCvoTHLpgc label {
    font-size: 14px;
    font-weight: bold !important;
    color: #031937; }
  ._3_srzfEnSdcuZCvoTHLpgc select {
    background-color: #FFFFFF; }
  ._3_srzfEnSdcuZCvoTHLpgc input {
    background: #FFFFFF !important; }
  ._3_srzfEnSdcuZCvoTHLpgc button {
    font-weight: bold;
    border-radius: 4px;
    height: 48px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }
  ._3_srzfEnSdcuZCvoTHLpgc ._3JzP0OgZnrpZGzw7fNHJ3k {
    margin-top: 10px;
    display: flex;
    flex-direction: row; }
    ._3_srzfEnSdcuZCvoTHLpgc ._3JzP0OgZnrpZGzw7fNHJ3k button {
      margin-top: 0px !important; }

._1rpaYGGihlXSsJzuKSiY6Q {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  min-width: 796px; }
  ._1rpaYGGihlXSsJzuKSiY6Q h2 {
    color: #000; }
  ._1rpaYGGihlXSsJzuKSiY6Q ._1pqa2snqkR8JA07BZqnkuq {
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer; }
  ._1rpaYGGihlXSsJzuKSiY6Q ._3wtTmV7_a2hLnhCN_s3gmQ {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  ._1rpaYGGihlXSsJzuKSiY6Q ._29ZFujAJYnjigUoz8dgZxx {
    font-weight: 300; }
  ._1rpaYGGihlXSsJzuKSiY6Q ._3uKTmGMh2x6EVAxx3XLm74 {
    color: #475569; }
  ._1rpaYGGihlXSsJzuKSiY6Q ._2E7LJBL1Nz8pQnHJ4iSe2X {
    font-size: 16px;
    line-height: 22px;
    color: #475569; }
    ._1rpaYGGihlXSsJzuKSiY6Q ._2E7LJBL1Nz8pQnHJ4iSe2X .KjPrf-A_fZz3QOm4uJEvR {
      cursor: pointer;
      font-weight: 500; }
  ._1rpaYGGihlXSsJzuKSiY6Q .nJVG5UgirF4pv7Ho-EKVW {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  ._1rpaYGGihlXSsJzuKSiY6Q .A4CIQ-f8ITRMU-1fbzKd1 {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

.wCRH-EFbqi2Y210k1L5gV {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._2HUgWWquSGayvCC8FRie0I {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.Z7-N4cSk7MgJLwwJHBl09 {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

._2xwytGJJgbSmP4f5l1kdbL {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

.pRVZdUESs2SteUZwTQ_U8 {
  display: flex;
  justify-content: center; }

._1k5u1R2BniONk67uFcBNqQ input {
  height: 17px;
  width: 17px; }

._1k5u1R2BniONk67uFcBNqQ p {
  font-weight: 300 !important; }
  ._1k5u1R2BniONk67uFcBNqQ p span {
    font-weight: bold; }

._1k5u1R2BniONk67uFcBNqQ label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  ._1k5u1R2BniONk67uFcBNqQ label p span {
    color: #0b71ff; }
    ._1k5u1R2BniONk67uFcBNqQ label p span:hover {
      cursor: pointer; }

._1-2KVtnf02OzrWSZqGshvx {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%; }

._2W-1XnW7GdYhU3W9gUEYer {
  display: none; }

._3n6p97-fyvIS0cxGDqsagA {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._3knOtzyxSG4Mt3jzjzknRt {
  display: block; }

._3n6p97-fyvIS0cxGDqsagA {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

.nPN0bnkduya1QSQqV6HPv {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

._1hArqD2szd3BnXIc-ilk_r {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

._2-psF2_M4rKwVoLAbIm4xn {
  margin-top: 30%; }

.byh1aJ8Wr0ctoGj-0OJPC {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._1-13s7HMsCwjeBrnclVohL span {
  display: inline-block; }

.jyMk_j7uoOyT1-5AGjH1b {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._28qY68IsKJjIigMR5YKzTW {
  margin-top: 25px; }

._3SDByfnovZtDvHXurpkcAQ {
  margin-top: 10px; }

._3srd3FumeEELu_NnakWYfb {
  height: 200px;
  width: 200px;
  object-fit: contain; }

._2YPOyXSk4-70Q4D9oVwH30 {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  ._3srd3FumeEELu_NnakWYfb {
    height: 200px;
    width: 200px; } }

._3ioJVRvAoq0xAiXFzwejI1 {
  margin-top: 14px; }

._1Hi60__JDs10Yfoey6MEjG {
  margin-top: 30px; }

._1toUXHslAGHojAkN0Frx33 {
  border: none;
  color: black; }
  ._1toUXHslAGHojAkN0Frx33:hover {
    background: transparent;
    color: black; }

._2MjegGQN0ZVb421NzQvmFS {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  ._2MjegGQN0ZVb421NzQvmFS div:first-child {
    width: 100% !important; }
    ._2MjegGQN0ZVb421NzQvmFS div:first-child > ul li:last-child {
      display: none !important; }
    ._2MjegGQN0ZVb421NzQvmFS div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes _20zZPip1_rC3sRo2x5xXd1 {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes _20zZPip1_rC3sRo2x5xXd1 {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._3aBKL5LfUm2Xjzd7DeSppO {
  -webkit-animation: _20zZPip1_rC3sRo2x5xXd1 4s forwards;
          animation: _20zZPip1_rC3sRo2x5xXd1 4s forwards; }

._2LuShK_cydZZT2hJR0AESX {
  display: flex;
  justify-content: center;
  align-items: center; }

._3tmWKle_GnasdYzQ3XpURK {
  justify-content: center;
  flex-wrap: wrap; }
  ._3tmWKle_GnasdYzQ3XpURK .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._3tmWKle_GnasdYzQ3XpURK .active button:hover {
    background: none;
    cursor: default !important; }
  ._3tmWKle_GnasdYzQ3XpURK .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._3tmWKle_GnasdYzQ3XpURK div button {
    display: block !important; }

@media screen and (min-width: 425px) and (max-width: 1024px) {
  ._1rpaYGGihlXSsJzuKSiY6Q {
    padding: 48px 0;
    min-width: 50px; }
  ._3_srzfEnSdcuZCvoTHLpgc {
    padding: 0 25px; } }

@media screen and (max-width: 520px) {
  ._1-2KVtnf02OzrWSZqGshvx {
    justify-content: center; }
  ._1rpaYGGihlXSsJzuKSiY6Q {
    padding: 48px 0;
    min-width: 50px; }
  ._3_srzfEnSdcuZCvoTHLpgc {
    padding: 0 25px; } }

.MYnSsWL1uECtTVShmHUAm {
  max-height: 70vh;
  min-width: 200vh; }

._2p-lZtg00VvQPnPbOzve_8 {
  padding: 12px 30px 0 30px; }

.ReactTable .-pagination .-previous {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-next {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-center {
  flex: 0 0 !important;
  flex-wrap: nowrap !important; }

.-pagination {
  justify-content: left !important; }

._1Tx19zSu7crcW3QAGcZ_ul {
  padding: 30px !important; }

._1stvr-hpo5LxMz9bwi5vZK:hover {
  cursor: pointer;
  text-decoration: none; }

.vYH5jvr1c9gatwpLjXIz2 {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

._2ZiEa8RYEdrZ5NjIvPG-zk {
  margin-top: 15px;
  overflow-x: scroll; }

._30j9Ghiw06xrR70VJKGIQG {
  border: Solid 1px black;
  align-items: flex-end;
  height: 25; }

._2G8Pi9YsG091u5xPxLy6o2 {
  padding: 6px 5px;
  white-space: normal;
  white-space: initial; }

.tpoDSYZNC7t_KrP8NRowW {
  margin-top: -40px !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border: none; }

._1al5Gx_s_ZMEalo_FRRqaC {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 10px;
  padding-left: 20px; }

.TnWD35ySzlnwuihG266nz {
  font-weight: 600; }
  .TnWD35ySzlnwuihG266nz:hover {
    cursor: pointer; }

.GoiA89CJiXziAMa1HZw-t {
  background: #fcd325; }

.omwF2JBfHnbNwaW3dusKs {
  background: #3c9; }

._2Tef_PeOogON1ef82TIjpm {
  background: #e64767; }

._2vQxl_O9um5hIA4PR1LBVF {
  display: flex; }
  ._2vQxl_O9um5hIA4PR1LBVF div {
    display: flex;
    align-items: center; }

.docstable .nav-link {
  font-weight: 500;
  color: darkgray;
  padding-left: 30px;
  padding-right: 30px;
  border-right: none;
  border-left: none; }

.rt-tr-group:hover {
  background-color: #edf1f6 !important;
  cursor: pointer; }

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute; }

@media screen and (max-width: 1355px) {
  .tpoDSYZNC7t_KrP8NRowW {
    width: 52%; } }

@media screen and (max-width: 1056px) {
  .tpoDSYZNC7t_KrP8NRowW {
    width: 48%; } }

@media screen and (max-width: 988px) {
  .tpoDSYZNC7t_KrP8NRowW {
    width: 100%; } }

@media screen and (max-width: 988px) {
  .tpoDSYZNC7t_KrP8NRowW {
    margin-top: 10px !important; } }

@media screen and (max-width: 565px) {
  .vYH5jvr1c9gatwpLjXIz2 {
    padding-left: 0; } }

@media screen and (max-width: 660px) {
  .ReactTable .rt-th.-sort-desc .rt-resizer:before {
    right: 80%; }
  .ReactTable .rt-th.-sort-asc .rt-resizer:before {
    right: 80%; } }

._3eHFukZt-XppIS2oS_lkU3 {
  display: flex;
  justify-content: center;
  margin: 20px; }

._3QZXWVXVKh3n1DD8XZZcH7 {
  margin-bottom: 10px; }

._3QZXWVXVKh3n1DD8XZZcH7 span {
  font-weight: bold;
  margin-left: 0px !important;
  margin-top: 0px !important;
  position: unset !important; }

._3QlHsNUpO3Nlr9T_YtshM0 {
  align-self: center;
  margin-left: 5px; }

._3eHFukZt-XppIS2oS_lkU3 span {
  font-size: 24px;
  font-weight: 600; }

._1z65e8bzx5F3GVLPdt2Op7 p {
  font-size: 16px;
  font-weight: bold;
  color: #808b9a;
  margin-bottom: 0; }

._1z65e8bzx5F3GVLPdt2Op7 span {
  margin-bottom: 15px;
  color: #031937;
  font-weight: bold;
  display: inline-block; }

._3Dol9YrKcVWF_G0EFyG8pb {
  margin-left: 20px; }

._1NM1K17ijsL1PO63n3Z44t {
  background-color: #3c9;
  color: white; }
  ._1NM1K17ijsL1PO63n3Z44t:hover {
    background-color: #3c9;
    color: white; }

._3u70TfIS7amCdQeag0AWr1 {
  page-break-before: always;
  margin-top: 10px; }

._3FblDG84ACbHgZEHzB3ivP {
  margin-top: 20px;
  float: right; }
  ._3FblDG84ACbHgZEHzB3ivP button:first-child {
    width: 130px;
    border-radius: 8px;
    margin-right: 10px; }
  ._3FblDG84ACbHgZEHzB3ivP button:last-child {
    border: 1px solid black;
    width: 200px;
    font-weight: bold;
    border-radius: 8px; }

._3ndFsrHZoB11BZyyDP-cGo {
  position: absolute;
  margin-left: 32px;
  font-weight: bold; }
  ._3ndFsrHZoB11BZyyDP-cGo i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 15px; }
  ._3ndFsrHZoB11BZyyDP-cGo ._2IaKBolZgFqntazT5OFBFK {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

.vEOphiXRu5cUfWMtxfScI {
  padding: 10px 5px 55px 5px; }

._2S5nrLuSxjQpz31YxUZfgC {
  padding: 15px 40px; }
  ._2S5nrLuSxjQpz31YxUZfgC div {
    margin-bottom: 3px; }

._3qVrxlTZDAhqLVwO7J-mKs {
  height: 1px;
  width: 100%;
  background: #ececec; }

._2WoaVQksEHlscLjgMFH0JB {
  border-bottom: none; }
  ._2WoaVQksEHlscLjgMFH0JB ._8c2h7xKNbitfcrIoXBNQ6 {
    height: 25px;
    background: #b5b7b9;
    width: 2px;
    margin-left: 10px;
    margin-right: 10px; }
  ._2WoaVQksEHlscLjgMFH0JB div div div a {
    color: black !important;
    font-weight: bold; }

._1776MCPzAcE5mm8lr0UGjL {
  padding: 0 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center; }

._1VclcZA0yEqcTEb9OQBfrE {
  word-break: break-all; }

.Izl9MFROQYQdQir04a87C {
  display: flex;
  justify-content: space-between;
  padding: 12px 40px; }
  .Izl9MFROQYQdQir04a87C ._1QRGTgPMNUk_uulVyUZWAr {
    display: -webkit-box; }
    .Izl9MFROQYQdQir04a87C ._1QRGTgPMNUk_uulVyUZWAr button {
      border-radius: 8px;
      font-weight: bold; }
    .Izl9MFROQYQdQir04a87C ._1QRGTgPMNUk_uulVyUZWAr button:first-child {
      margin-right: 10px; }
  .Izl9MFROQYQdQir04a87C ._1TgI5ao-Fpc-6qq0xL6Yd6 {
    margin-left: -13px;
    font-weight: bold; }
  .Izl9MFROQYQdQir04a87C ._16pXsCqprtiZ-0i3zlkBxQ {
    word-break: break-all; }
  .Izl9MFROQYQdQir04a87C span {
    font-weight: bold;
    margin-left: 5px;
    position: relative;
    top: 2px; }

@media screen and (max-width: 900px) {
  .Izl9MFROQYQdQir04a87C {
    flex-wrap: wrap; } }

@media screen and (max-width: 400px) {
  .Izl9MFROQYQdQir04a87C {
    flex-wrap: wrap; }
    .Izl9MFROQYQdQir04a87C ._1QRGTgPMNUk_uulVyUZWAr {
      display: unset; }
  ._3FblDG84ACbHgZEHzB3ivP button:first-child {
    width: 108px !important;
    border-radius: 8px;
    margin-right: 10px; }
  ._3FblDG84ACbHgZEHzB3ivP button:last-child {
    border: 1px solid black;
    width: 150px !important;
    font-weight: bold;
    border-radius: 8px; } }

@media screen and (max-width: 430px) {
  .Izl9MFROQYQdQir04a87C {
    justify-content: center; }
  ._1QRGTgPMNUk_uulVyUZWAr {
    display: flex;
    justify-content: center;
    align-items: center; }
    ._1QRGTgPMNUk_uulVyUZWAr button:first-child {
      margin-right: 0px !important;
      margin-bottom: 5px; } }

@media screen and (max-width: 520px) {
  ._1TgI5ao-Fpc-6qq0xL6Yd6 {
    margin-left: -10px !important; } }

@media screen and (max-width: 385px) {
  ._1TgI5ao-Fpc-6qq0xL6Yd6 {
    margin-left: 20px !important; } }

._2WoQYAPIcWaXMbx7sS7FXD > canvas {
  width: 100% !important;
  height: 100% !important; }

.diupAc3s3AeDFj0JRTyFG {
  position: absolute;
  margin-left: 32px;
  font-weight: bold; }
  .diupAc3s3AeDFj0JRTyFG i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 15px; }
  .diupAc3s3AeDFj0JRTyFG .vsEpp0ASB5J6mtM2awxTW {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

._1jONVmOygV77DJ9GCgAtRN {
  width: 600px;
  height: 600px;
  overflow: scroll; }

._2gIsUDLnF-BMpjUSwai-ys {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px !important;
  flex-direction: row;
  align-items: center; }
  ._2gIsUDLnF-BMpjUSwai-ys > button:first-child {
    width: 210px; }
  ._2gIsUDLnF-BMpjUSwai-ys img {
    margin-right: 10px; }
  ._2gIsUDLnF-BMpjUSwai-ys button {
    font-weight: bold;
    border-radius: 8px;
    width: 120px; }
  ._2gIsUDLnF-BMpjUSwai-ys div button:first-child {
    margin-right: 15px; }

._2mAh93lixh7C4_-r3PB3ZN {
  flex-direction: column;
  justify-content: space-between; }
  ._2mAh93lixh7C4_-r3PB3ZN ._2Y5BuMJxr6PeiS2-DxC5B5 {
    font-weight: 600; }
    ._2mAh93lixh7C4_-r3PB3ZN ._2Y5BuMJxr6PeiS2-DxC5B5 input {
      height: 17px !important;
      width: 17px !important;
      border-radius: 50%; }
    ._2mAh93lixh7C4_-r3PB3ZN ._2Y5BuMJxr6PeiS2-DxC5B5 label {
      margin-left: 5px;
      font-weight: 600 !important;
      font-size: 16px; }
  ._2mAh93lixh7C4_-r3PB3ZN .gQpQaAVFmGjQ4iYsR2B2v {
    max-width: 90%; }
  ._2mAh93lixh7C4_-r3PB3ZN .qwbQLgndASCsMw-B4kDv3 {
    max-width: 90%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: groove;
    margin-left: 40px;
    overflow-x: scroll; }

._4eB5AkeLi1cpJjMjqYVmT {
  display: flex;
  justify-content: center; }

._3cjX-RTe2fm1sW7-Er81gh {
  width: 25px;
  height: 25px;
  margin-right: 5px; }

._1ER4L7HZFLfEx_Ju83RYNs {
  width: 25px;
  height: 25px;
  margin-left: 5px; }

._2dmPZUSUJz_99uLrITay_7 {
  justify-content: space-between;
  display: flex; }
  ._2dmPZUSUJz_99uLrITay_7 button {
    border-radius: 8px;
    width: 120px;
    font-weight: bold; }

._1gA2K_HA_cRfyypn-DfxWA {
  margin-left: 4%; }

._2zeJIxvQM4q68BmvPSfGC6 {
  display: flex;
  margin-top: 5px; }
  ._2zeJIxvQM4q68BmvPSfGC6 > div:first-child {
    display: flex; }
  ._2zeJIxvQM4q68BmvPSfGC6 input {
    height: 19px !important;
    width: 19px !important;
    border-radius: 50%;
    margin-left: 10px; }
  ._2zeJIxvQM4q68BmvPSfGC6 label {
    margin-left: 5px;
    font-weight: 500 !important;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 35px; }

._3qJv1K0BlsSTvQfWrorcL0 input {
  background: white !important; }

._1MjJaYKWjyuUQcOfn-D4__ {
  min-height: 400px;
  display: flex;
  flex-direction: column; }
  ._1MjJaYKWjyuUQcOfn-D4__ button {
    width: 120px;
    margin-left: 16%;
    margin-top: -1%;
    font-weight: bold;
    font-size: 14px; }
  ._1MjJaYKWjyuUQcOfn-D4__ label:first-child {
    margin-bottom: 3px;
    margin-left: 16.6%;
    font-size: 14px;
    font-weight: bold; }

._3J7A1KUlfOtveLpnd5vxSg ._3HCDZx3X_5hRy5rAGxdw_e {
  padding: 14px 0px 70px 0px; }
  ._3J7A1KUlfOtveLpnd5vxSg ._3HCDZx3X_5hRy5rAGxdw_e p {
    margin-bottom: 0px !important; }
  ._3J7A1KUlfOtveLpnd5vxSg ._3HCDZx3X_5hRy5rAGxdw_e span {
    font-size: 12px;
    color: #0b71ff; }

._3J7A1KUlfOtveLpnd5vxSg ._1M52QgA4_JqrtmpB1aNPEg input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important; }

._3J7A1KUlfOtveLpnd5vxSg ._3ZjnT6CuAHEw0Kz7t3-cZ5 {
  padding: 0 5px; }
  ._3J7A1KUlfOtveLpnd5vxSg ._3ZjnT6CuAHEw0Kz7t3-cZ5 p {
    color: grey;
    font-size: 10.5px; }
  ._3J7A1KUlfOtveLpnd5vxSg ._3ZjnT6CuAHEw0Kz7t3-cZ5 span {
    color: grey;
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer; }

@media screen and (max-width: 1230px) {
  ._2mAh93lixh7C4_-r3PB3ZN {
    display: unset; }
    ._2mAh93lixh7C4_-r3PB3ZN div {
      margin-left: 30px;
      margin-top: 20px; } }

@media screen and (max-width: 992px) {
  ._2gIsUDLnF-BMpjUSwai-ys {
    flex-direction: column;
    align-items: unset; }
  .qwbQLgndASCsMw-B4kDv3 {
    max-width: 90%;
    overflow: scroll; } }

@media screen and (max-width: 768px) {
  ._2zeJIxvQM4q68BmvPSfGC6 {
    display: unset;
    flex-wrap: wrap; } }

._3wKjQtGt2V8KLpQ_90iMqp {
  position: absolute;
  /* top: 59%; */
  right: 30%;
  left: 30%;
  margin-top: 25%; }

.dqEqoiLdBMX-oBZiPaCWp {
  margin-top: 10px;
  text-align: center;
  color: #fff;
  font-weight: 600; }

.NYwYn07cHnvw7-NPU8DOy {
  font-size: 12px;
  color: #fff;
  text-align: center; }

.XAHdMPZBWAiyZ3Dj5GIkz {
  position: absolute;
  z-index: 99999999999999999;
  background: #000;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.8; }

._3NePfZTrJPEvgz4xoQkns4 {
  position: absolute;
  background: #000;
  width: 100%;
  z-index: 999999999999999999;
  right: 0;
  opacity: 0.8;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: RJ6fHreeEktwOFL5Z_2DZ;
          animation-name: RJ6fHreeEktwOFL5Z_2DZ; }

.j-jm-S8pLA1n4lv9W4EM7 {
  margin-top: 4%; }
  .j-jm-S8pLA1n4lv9W4EM7 input {
    background: #edf1f6 !important; }

._2LCZAxrBlvuPbPTvNbCndI {
  font-size: 48px !important; }

._2-Ood14oc6xt53I_eKvGiY {
  display: flex;
  justify-content: space-around;
  margin: auto;
  font-size: 16px;
  margin-top: 16px;
  width: 240px;
  font-weight: bold;
  margin-bottom: 45px !important; }

._1RP36KzgT6ZA3JCIfznlhP {
  font-weight: 600; }
  ._1RP36KzgT6ZA3JCIfznlhP input {
    height: 17px !important;
    width: 17px !important; }
  ._1RP36KzgT6ZA3JCIfznlhP label {
    margin-left: 5px; }

._1fEedF5dMxlS5JafzMmBPv {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

.Lv9fxB6pT6RSTkJp94BaI {
  display: flex !important;
  align-items: center; }
  .Lv9fxB6pT6RSTkJp94BaI > div:first-child {
    margin-bottom: 0px !important; }
    .Lv9fxB6pT6RSTkJp94BaI > div:first-child > div:first-child {
      background: #edf1f6;
      border: none; }
      .Lv9fxB6pT6RSTkJp94BaI > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  .Lv9fxB6pT6RSTkJp94BaI p {
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0px !important; }
    .Lv9fxB6pT6RSTkJp94BaI p span {
      color: #0b71ff; }
      .Lv9fxB6pT6RSTkJp94BaI p span:hover {
        cursor: pointer; }

._2Pwdo_LgFCUPDQZm0dxcYw {
  text-align: right;
  font-size: 12px;
  font-weight: 500; }
  ._2Pwdo_LgFCUPDQZm0dxcYw:hover {
    cursor: pointer; }

._2ATMS3E27feunrOg31FsZw {
  margin-top: 4%;
  font-weight: bold; }

._2JDYdfuueSS810ZZHxHJhU {
  margin-bottom: 4px; }

._39mSQVn20SCDT6Tc9QqT8L {
  font-weight: 600;
  margin-top: 25%;
  text-align: center;
  color: #031937; }
  ._39mSQVn20SCDT6Tc9QqT8L span {
    color: #0b71ff; }
    ._39mSQVn20SCDT6Tc9QqT8L span:hover {
      cursor: pointer; }

._39gvtnELfYtBfzpzoepXxU {
  width: 1px;
  height: 1em;
  background: black;
  margin: 0 20px; }

._1Xw_M3UE8UWt6YyfI1Rrmk {
  padding: 30px !important; }

._3m-7APHFT8vAgek-XN68Ro {
  font-weight: 700;
  color: black;
  padding: 14px 0; }

._3-AQQAeF-QqQW_GoL1UEjy button {
  width: 120px;
  font-weight: bold;
  border-radius: 8px; }

._3LsbVKvEJFjh8CCbwJkVNt {
  border-bottom: solid #bdc2ca 1px; }

.BoILj7m5CPB1knRdbHmO_ {
  margin-top: 10px; }

._2gHtKzaVMPJQooTyI_uVPu {
  color: #1275ff;
  font-weight: 700; }

._2QJyUdo-CxUWnWFQOOc4T6 {
  color: #808b9a;
  font-weight: 600; }

._2gWv0HvzdqAGgDoA4ljhZm {
  padding: 10px 30px 0 10px; }

._3aox7zDLJyn9ZONux-9ahD {
  margin: 0; }
  ._3aox7zDLJyn9ZONux-9ahD tr td {
    padding: 8px; }
  ._3aox7zDLJyn9ZONux-9ahD th {
    color: #808b9a;
    font-weight: 600;
    width: 42%; }
  ._3aox7zDLJyn9ZONux-9ahD td:nth-child(2) {
    font-weight: 700; }
  ._3aox7zDLJyn9ZONux-9ahD td:nth-child(3) {
    text-align: right; }
  ._3aox7zDLJyn9ZONux-9ahD td {
    vertical-align: middle; }

._2zRDjv6AGWk2dIjXinuTd0 {
  position: inherit; }

._2vpwvPtCqWjxHC41ZNWQg6 {
  font-size: 1.3em;
  letter-spacing: 0; }

._1uBR2Aei0OdfUqB6tqe_M8 {
  background: #fff !important; }

._2Aa5HsUTgKY4TY1kj9aARo {
  width: 100%; }

.CjYFSI3WOwA60bO4WW4P {
  padding: 5% 35%; }

._3nek2sJGhlYhyvW30n1R0J {
  height: 80%; }

._17ExSHzyja03iSpIP6TcdZ {
  padding: 5px;
  width: 190px;
  border: 1px solid #e4dfdf;
  font-weight: bold;
  border-radius: 4px; }
  ._17ExSHzyja03iSpIP6TcdZ:focus {
    outline: 0; }

._2duurDZutmqMtYQ7xgXb9Y {
  background: white;
  font-weight: bold;
  border: none; }

._2n07XoBk9e2gt6I1kxhM1Z {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: normal; }

._3BYPtOXWWxHuuQ9kBwQ9C5 {
  color: white;
  padding-left: 2px; }

._2jZqPxxZN3GUDw5ujBevyJ {
  color: white;
  padding-right: 2px; }

._2mMZvasivGdYt1W4BwMtGW {
  display: flex; }

._3HwEADWXNLLrBot8BJNbYI {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.a4S47RK1Ks5mv8fUfGu2T {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

._1N07pcK13TsAM-7rCyukxS {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.yV0-CzfQOhToemxtMr09Q {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

@media screen and (max-width: 978px) {
  .CjYFSI3WOwA60bO4WW4P {
    padding: 5% 20%;
    align-items: stretch; } }

@media screen and (max-width: 765px) {
  ._3aox7zDLJyn9ZONux-9ahD {
    margin-top: 20px; } }

._32CYVAhPiGGLnaaU3pGQfE {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.YOTgjfXJxxTtpU05Kj1_6 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 50% !important;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%; }

._3YHCNtSSLZdxWfs8fnYWyz {
  flex-direction: column;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  align-items: flex-start;
  padding: 30px; }

._29GidZBLoCCCRM6aELYjrM {
  width: 40px;
  margin-right: 24px; }

._3OM5e4J19oPP2I_gjJF92x {
  text-align: left; }

._2eaXpgcxDGhbRyd99Ox__n {
  margin-top: 20px;
  width: 100% !important;
  text-align: center;
  font-size: 1rem;
  font-weight: bold; }

._2Kx8gzTSYK0ncDnG17cQ89 {
  margin-right: 5px; }

.lPJf3wvQEtqJcXqUA6lBM {
  margin-bottom: 0;
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px; }

._20m-OVjdgYEq4bt_Vr8M3a {
  text-align: left !important;
  margin-top: 20px; }

.kTSEfx66GrBR9OKt-VdwK {
  text-align: center !important;
  margin-top: 20px; }

._25_r-_cpcU8kudnckcG7if {
  margin-bottom: 10px !important;
  text-align: center; }

._2SgHAgZ_tb6GCNJvdq3BJz {
  font-size: 1.2rem;
  text-align: left;
  font-weight: bold; }

._1TEMpkP2kNnquBhKJt2VJ5 {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  font-weight: bold; }

._1hDkCD-JqzqbCIXq086t8i {
  font-weight: bold; }

._3N3mtLnbw0U_iweNlm6rqG {
  cursor: pointer;
  display: flex; }

._24wwScwoIreHWxFwjp5nxN {
  font-style: italic !important; }

.servicebot-radio-button-wrapper {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

._3kPpmeLzaOoEVVX2fP9nCd {
  padding: 20% 25%;
  font-size: 20px;
  font-weight: bolder; }

.NFLYNVvzENB3KQNmdISOT {
  max-height: 70vh;
  min-width: 200vh; }

._1fl-5zBWYSMJQdZriVgtAP {
  padding: 12px 30px 0 30px; }

.ReactTable .-pagination .-previous {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-next {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-center {
  flex: 0 0 !important;
  flex-wrap: nowrap !important; }

.-pagination {
  justify-content: left !important; }

.mGzzS5_MQcx8azMk3xqu5 {
  padding: 30px !important; }

._3CwBQpBKOp6-6OSLDC48AM:hover {
  cursor: pointer;
  text-decoration: none; }

._3Xgc4kb2cUADym10bISO9V {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

.Dhwt4g_ek3PihpvdJmxcS {
  margin-top: 15px;
  overflow-x: scroll; }

._2sPnGSsm2gKspGhTlK6NL1 {
  border: Solid 1px black;
  align-items: flex-end;
  height: 25; }

._1R63jS6tWKrwYMBNVIUOPI {
  padding: 6px 5px;
  white-space: normal;
  white-space: initial; }

._2pwlAs72rb02ozk93FkG4T {
  margin-top: -40px !important;
  z-index: 1000;
  width: 58%;
  border: none; }

._1Ooue7IfvTOjakIVeKCabt {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 10px;
  padding-left: 20px; }

._JZbTWirrGix2vbLb9hHE {
  font-weight: 600; }
  ._JZbTWirrGix2vbLb9hHE:hover {
    cursor: pointer; }

.CkmOdEMWrv9cJgLjveMzu {
  background: #fcd325; }

._3yeR6-PFTi-4VNFPNVAwau {
  background: #3c9; }

._1KwrAvwbN3vcebCq3MoPYT {
  background: #e64767; }

.Zrk6NKjxxAPUnbEz7tYfd {
  display: flex; }
  .Zrk6NKjxxAPUnbEz7tYfd div {
    display: flex;
    align-items: center; }

.docstable .nav-link {
  font-weight: 500;
  color: darkgray;
  padding-left: 30px;
  padding-right: 30px;
  border-right: none;
  border-left: none; }

.rt-tr-group:hover {
  background-color: #edf1f6 !important;
  cursor: pointer; }

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute; }

@media screen and (max-width: 1355px) {
  ._2pwlAs72rb02ozk93FkG4T {
    width: 52%; } }

@media screen and (max-width: 1056px) {
  ._2pwlAs72rb02ozk93FkG4T {
    width: 48%; } }

@media screen and (max-width: 988px) {
  ._2pwlAs72rb02ozk93FkG4T {
    width: 100%; } }

@media screen and (max-width: 988px) {
  ._2pwlAs72rb02ozk93FkG4T {
    margin-top: 10px !important; } }

@media screen and (max-width: 565px) {
  ._3Xgc4kb2cUADym10bISO9V {
    padding-left: 0; } }

@media screen and (max-width: 660px) {
  .ReactTable .rt-th.-sort-desc .rt-resizer:before {
    right: 80%; }
  .ReactTable .rt-th.-sort-asc .rt-resizer:before {
    right: 80%; } }

.bx36s-ARpHWAwg2OmfSqK {
  max-height: 70vh;
  min-width: 200vh; }

._3GJi-beOc3qGaEADkoiU2E {
  padding: 12px 30px 0 30px; }

.ReactTable .-pagination .-previous {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-next {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-center {
  flex: 0 0 !important;
  flex-wrap: nowrap !important; }

.-pagination {
  justify-content: left !important; }

._1OVcJe4-MHaGaph9oOvuSj {
  padding: 30px !important; }

.PQDzOsytPz8RrnmdQzoJW:hover {
  cursor: pointer;
  text-decoration: none; }

._2vM40Cxd_q_EXKcNa7bXCS {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

._2qIzI5RzsVQ67QHw6TgX4V {
  margin-top: 15px;
  overflow-x: scroll; }

._3RcKms5DUESL3b5UNUGzYe {
  border: Solid 1px black;
  align-items: flex-end;
  height: 25; }

._2p0O_tld4tUsX3c7MtrVGL {
  padding: 6px 5px;
  white-space: normal;
  white-space: initial; }

._3neB3_QfTzUb_km-sqFKar {
  margin-top: -40px !important;
  z-index: 1000;
  width: 58%;
  border: none; }

._1CUQwWnlSRuVEI7i8ZuaqL {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 10px;
  padding-left: 20px; }

._3DRujj8X3nPe1QSS5nXvhP {
  font-weight: 600; }
  ._3DRujj8X3nPe1QSS5nXvhP:hover {
    cursor: pointer; }

.x21nEsnqBtsQuxWkWlAvF {
  background: #fcd325; }

.WDbgzawyxCftUBwKVbwiH {
  background: #3c9; }

._1v03HMvPlA4COD1CmwyFY8 {
  background: #e64767; }

._1SfSwdyCp0kTHiQce04GiL {
  display: flex; }
  ._1SfSwdyCp0kTHiQce04GiL div {
    display: flex;
    align-items: center; }

.docstable .nav-link {
  font-weight: 500;
  color: darkgray;
  padding-left: 30px;
  padding-right: 30px;
  border-right: none;
  border-left: none; }

.rt-tr-group:hover {
  background-color: #edf1f6 !important;
  cursor: pointer; }

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute; }

@media screen and (max-width: 1355px) {
  ._3neB3_QfTzUb_km-sqFKar {
    width: 52%; } }

@media screen and (max-width: 1056px) {
  ._3neB3_QfTzUb_km-sqFKar {
    width: 48%; } }

@media screen and (max-width: 988px) {
  ._3neB3_QfTzUb_km-sqFKar {
    width: 100%; } }

@media screen and (max-width: 988px) {
  ._3neB3_QfTzUb_km-sqFKar {
    margin-top: 10px !important; } }

@media screen and (max-width: 565px) {
  ._2vM40Cxd_q_EXKcNa7bXCS {
    padding-left: 0; } }

@media screen and (max-width: 660px) {
  .ReactTable .rt-th.-sort-desc .rt-resizer:before {
    right: 80%; }
  .ReactTable .rt-th.-sort-asc .rt-resizer:before {
    right: 80%; } }

._3H7n-c0EPkWU2DG_NQBbWt {
  max-height: 70vh; }

.sK4WxiWJc9pUpwQyIJdI8 {
  padding: 12px 30px 0 30px; }

.ReactTable .-pagination .-previous {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-next {
  flex: 0.1 0 !important; }

.ReactTable .-pagination .-center {
  flex: 0 0 !important;
  flex-wrap: nowrap !important; }

.-pagination {
  justify-content: left !important; }

.rsR_gHI0_Xev--MhMHl4D {
  padding: 30px !important; }

._2cGX5xjTaYR8TrIUPtyNGa:hover {
  cursor: pointer;
  text-decoration: none; }

._3OkHf-Y6WKcCQQTRwZ2r48 {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

._1nYL2bUF7BCvpGPfjN4G2z {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  white-space: pre-wrap;
  font-size: large; }

.wTx2nDLXtn1SijGT5A9ey {
  margin-top: 15px;
  overflow-x: scroll; }

._2g0WBxpNSYy_iejHMKEd9E {
  border: Solid 1px black;
  align-items: flex-end;
  height: 25; }

.Mj-5ML3VpeqkDsuwMKjRA {
  padding: 6px 5px;
  white-space: normal;
  white-space: initial; }

._3kJJDoFyNnZy0xu3LqHEwA {
  margin-top: -40px !important;
  z-index: 1000;
  width: 58%;
  border: none; }

._1eKjQlxdUjlUYyxYySPFBX {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 10px;
  padding-left: 20px; }

._1XIzOweh1RTqKEZuXNmvC6 {
  font-weight: 600; }
  ._1XIzOweh1RTqKEZuXNmvC6:hover {
    cursor: pointer; }

._1ctLBbLjwFIbybLm-LHeU5 {
  background: #fcd325; }

._21fyOncByrT1TdIpoJThSf {
  background: #3c9; }

._3QqBDUlfxC2BIHfn013gqG {
  background: #e64767; }

._2TlDIreXp6NyuDFRl6bE_l {
  display: flex; }
  ._2TlDIreXp6NyuDFRl6bE_l div {
    display: flex;
    align-items: center; }

.docstable .nav-link {
  font-weight: 500;
  color: darkgray;
  padding-left: 30px;
  padding-right: 30px;
  border-right: none;
  border-left: none; }

.rt-tr-group:hover {
  background-color: #edf1f6 !important;
  cursor: pointer; }

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(/static/media/top-arrow.8189ed46.jpg);
  right: 100%;
  top: 35%;
  position: absolute; }

@media screen and (max-width: 1355px) {
  ._3kJJDoFyNnZy0xu3LqHEwA {
    width: 52%; } }

@media screen and (max-width: 1056px) {
  ._3kJJDoFyNnZy0xu3LqHEwA {
    width: 48%; } }

@media screen and (max-width: 988px) {
  ._3kJJDoFyNnZy0xu3LqHEwA {
    width: 100%; } }

@media screen and (max-width: 988px) {
  ._3kJJDoFyNnZy0xu3LqHEwA {
    margin-top: 10px !important; } }

@media screen and (max-width: 565px) {
  ._3OkHf-Y6WKcCQQTRwZ2r48 {
    padding-left: 0; } }

@media screen and (max-width: 660px) {
  .ReactTable .rt-th.-sort-desc .rt-resizer:before {
    right: 80%; }
  .ReactTable .rt-th.-sort-asc .rt-resizer:before {
    right: 80%; } }

.y9CtD_bTdFYoZptBLnbQV {
  position: relative;
  background-image: url(/static/media/onboarding-background.5798e1b0.svg);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0; }

.daR7m5hy4Lw82qdLSrzI6 ._3U06rUQ0XDkLiQhxLpXtzo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: auto; }

.daR7m5hy4Lw82qdLSrzI6 ._3T8h89dyvrSfQ9E7o1n7yD {
  margin-top: 2.5%; }

.daR7m5hy4Lw82qdLSrzI6 label {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

.daR7m5hy4Lw82qdLSrzI6 select {
  background-color: #FFFFFF; }

.daR7m5hy4Lw82qdLSrzI6 input {
  background: #FFFFFF !important; }

.daR7m5hy4Lw82qdLSrzI6 button {
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

.daR7m5hy4Lw82qdLSrzI6 ._28kjDuW1Oob18AdUL86iid {
  margin-top: 10px;
  display: flex;
  flex-direction: row; }
  .daR7m5hy4Lw82qdLSrzI6 ._28kjDuW1Oob18AdUL86iid button {
    margin-top: 0px !important; }

._2asiagN52ojWpoASt1-qdw {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569; }
  ._2asiagN52ojWpoASt1-qdw h2 {
    color: #000; }
  ._2asiagN52ojWpoASt1-qdw ._2CmehfqPumfXaxVucx0BI2 {
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer; }
  ._2asiagN52ojWpoASt1-qdw ._1g-x496TNYX-qNQ9_bQKX5 {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  ._2asiagN52ojWpoASt1-qdw .zuxME1dcu2EWtFWVt5a1h {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px; }
  ._2asiagN52ojWpoASt1-qdw ._1TqJq-L75jQn9ObW6i6jnW {
    color: #475569; }
  ._2asiagN52ojWpoASt1-qdw ._3RAsxPfXJ6FSA0wpG5FGz8 {
    margin-top: 32px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: #475569; }
    ._2asiagN52ojWpoASt1-qdw ._3RAsxPfXJ6FSA0wpG5FGz8 .Q70kZMX9FqBXX9KJqzp3I {
      cursor: pointer;
      font-weight: 500; }
  ._2asiagN52ojWpoASt1-qdw ._8-Kb40h4atLsnoI8E_DRM {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  ._2asiagN52ojWpoASt1-qdw ._1djf7KnxMMP3br763OihQY {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

.qywTLzeOHN5GcIAPs2Diw {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._3-MMxFREdHng8CbbxY9e2i {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

._3kb3leh88vA6mvn2xXHJn9 {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

._1ovXy4MI2LLO18UA8KToWI {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

._3U74HvTFHRZ7WWdK7sjWvj {
  display: flex;
  justify-content: center; }

._2N5dx9W0694NA6gr1aYWbX input {
  height: 17px;
  width: 17px; }

._2N5dx9W0694NA6gr1aYWbX p {
  font-weight: 300 !important; }
  ._2N5dx9W0694NA6gr1aYWbX p span {
    font-weight: bold; }

._2N5dx9W0694NA6gr1aYWbX label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  ._2N5dx9W0694NA6gr1aYWbX label p span {
    color: #0b71ff; }
    ._2N5dx9W0694NA6gr1aYWbX label p span:hover {
      cursor: pointer; }

.-VcWIwZopOt_ouhMbVz38 {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px; }

._2_Uc1qLsU0efydRcWC4Ztb {
  display: none; }

._3umD2WaOUzpB-rO3kZltwz {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

.-KVBr0LAVFsEY1fwPsnx6 {
  display: block; }

._3umD2WaOUzpB-rO3kZltwz {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._3a0qJKO958hrwfhaZFUiZG {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

._3Cng01-2zG-ajOQjtKSXrY {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

.LNu3XLqfn4cr2OAtyI4ax {
  margin-top: 30%; }

._3ZmSyRcVt76I_R8p34OSZJ {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._3FVWvKd4AqttHQPw2LU77_ span {
  display: inline-block; }

._2yhKXM_yv16A7bI08V4Y6R {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._1IHZD4-qKxTgKwYK9IB1Tj {
  margin-top: 25px; }

._12FULEDyfshqQDvGYQnZ1i {
  margin-top: 10px; }

._62cvNjXhovvH9gUpqJOVN {
  height: 200px;
  width: 200px;
  object-fit: contain; }

._1vOrD98FIsz-AhO2LulvX2 {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  ._62cvNjXhovvH9gUpqJOVN {
    height: 200px;
    width: 200px; } }

._2JfnbGKWaATWNIJnbBtazU {
  margin-top: 14px; }

.amzBC6SkvzsIVEYGSrwrr {
  margin-top: 32px; }

._2ffbztqlXfQp73krsE75bK {
  border: none;
  color: black; }
  ._2ffbztqlXfQp73krsE75bK:hover {
    background: transparent;
    color: black; }

._1S4xBXSrZPtwPGpjwshu2v {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  ._1S4xBXSrZPtwPGpjwshu2v div:first-child {
    width: 100% !important; }
    ._1S4xBXSrZPtwPGpjwshu2v div:first-child > ul li:last-child {
      display: none !important; }
    ._1S4xBXSrZPtwPGpjwshu2v div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes vYVEUI_CyLVJytHp-HZ7o {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes vYVEUI_CyLVJytHp-HZ7o {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._2CD6prxqEbHZWca0G_Ky1f {
  -webkit-animation: vYVEUI_CyLVJytHp-HZ7o 4s forwards;
          animation: vYVEUI_CyLVJytHp-HZ7o 4s forwards; }

._3NPBVyW6a02KZFVeUSJo9I {
  display: flex;
  justify-content: center;
  align-items: center; }

._2Qm7xLZFa1pRaC8BCfUtNA {
  justify-content: center;
  flex-wrap: wrap; }
  ._2Qm7xLZFa1pRaC8BCfUtNA .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._2Qm7xLZFa1pRaC8BCfUtNA .active button:hover {
    background: none;
    cursor: default !important; }
  ._2Qm7xLZFa1pRaC8BCfUtNA .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._2Qm7xLZFa1pRaC8BCfUtNA div button {
    display: block !important; }

@media screen and (max-width: 520px) {
  .-VcWIwZopOt_ouhMbVz38 {
    justify-content: center; }
  ._2asiagN52ojWpoASt1-qdw {
    padding: 48px 0;
    width: 350px !important;
    font-size: 14px;
    line-height: 20px; }
    ._2asiagN52ojWpoASt1-qdw ._1g-x496TNYX-qNQ9_bQKX5 {
      font-size: 30px;
      line-height: 40px; }
    ._2asiagN52ojWpoASt1-qdw .zuxME1dcu2EWtFWVt5a1h {
      font-size: 14px;
      line-height: 20px; }
    ._2asiagN52ojWpoASt1-qdw ._3RAsxPfXJ6FSA0wpG5FGz8 {
      font-size: 14px;
      line-height: 20px; }
  .daR7m5hy4Lw82qdLSrzI6 {
    padding: 0 25px; } }

._1EE3SWbN6Mz7Awhw_mJYa4 {
  min-height: 100vh;
  margin: 0 15px;
  padding: 0 5px 10px 0; }

._2XaE1TJeXYkPJWy13uA1O- {
  margin-top: 16px;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  line-height: normal;
  height: 428px; }
  ._2XaE1TJeXYkPJWy13uA1O- button {
    border-radius: 4px;
    height: 48px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; }
  ._2XaE1TJeXYkPJWy13uA1O- .LZ-gpR9rjTTkmHHvYSYcH {
    color: #475569;
    font-size: 24px;
    font-weight: 400; }
    ._2XaE1TJeXYkPJWy13uA1O- .LZ-gpR9rjTTkmHHvYSYcH .XSgkbfiYGkiKqT2Fk5eDd {
      display: flex;
      padding: 6px;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      border-radius: 16px;
      background: rgba(4, 181, 255, 0.08);
      color: #000A23;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }
  ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj {
      color: #0F172A;
      font-size: 48px;
      font-weight: 600; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC {
      color: #0F172A;
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 400; }
  ._2XaE1TJeXYkPJWy13uA1O- ._1nOC2lDerVPvYyDDZzoen5 {
    color: #A3AAB4;
    font-size: 16px;
    font-weight: 300; }
  ._2XaE1TJeXYkPJWy13uA1O- ._1w6ZUvoXEwcYGML7kNLYsS {
    color: #475569;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 20px; }
  ._2XaE1TJeXYkPJWy13uA1O- ._1zdvThuSU6zmK4DFmjQBeK {
    color: #A3AAB4;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px; }

._3pT8-28yqGui3CFWZDYpek {
  border-radius: 8px;
  margin-top: 16px;
  align-items: flex-start;
  padding: 24px;
  background: #FFF;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  line-height: normal; }
  ._3pT8-28yqGui3CFWZDYpek button {
    border-radius: 4px;
    height: 48px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
    margin-top: 24px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 140px; }
  ._3pT8-28yqGui3CFWZDYpek ._3rkybi7hZHaYKsHSpYqOHu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    height: 32px;
    border-radius: 4px;
    background: #a9dcf0;
    color: #000A23;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px; }
  ._3pT8-28yqGui3CFWZDYpek ._2YMAp7F7l6T7t1GlocujCx {
    color: #475569;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 16px;
    width: 100%; }
  ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj {
      color: #0F172A;
      font-size: 48px;
      font-weight: 600;
      line-height: 56px; }
    ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC {
      color: #0F172A;
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 400; }
  ._3pT8-28yqGui3CFWZDYpek ._1nOC2lDerVPvYyDDZzoen5 {
    color: #475569;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; }

.oq8D7bqLofLioUuwn91gF::after {
  content: none; }

.s2mssoXIQwDRg_nmrUHqa, ._1wagRdPeMaKSc-kPUZS1ze, ._2ls-n6OF0PmgR6XhBHlYnO {
  border-radius: 8px;
  margin-top: 16px;
  align-items: flex-start;
  padding: 24px;
  background: #FFF;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  line-height: normal;
  font-size: 18px;
  font-weight: 300;
  color: #475569; }
  .s2mssoXIQwDRg_nmrUHqa .oq8D7bqLofLioUuwn91gF::after, ._1wagRdPeMaKSc-kPUZS1ze .oq8D7bqLofLioUuwn91gF::after, ._2ls-n6OF0PmgR6XhBHlYnO .oq8D7bqLofLioUuwn91gF::after {
    content: none; }
  .s2mssoXIQwDRg_nmrUHqa ._3wmt3AeW-4KiMPeRHucjZ2, ._1wagRdPeMaKSc-kPUZS1ze ._3wmt3AeW-4KiMPeRHucjZ2, ._2ls-n6OF0PmgR6XhBHlYnO ._3wmt3AeW-4KiMPeRHucjZ2 {
    color: #000A23;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 16px; }
  .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 .oq8D7bqLofLioUuwn91gF::after, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 .oq8D7bqLofLioUuwn91gF::after, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 .oq8D7bqLofLioUuwn91gF::after {
      content: none; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3j1lZlL3oQ2P35odc5-NP, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3j1lZlL3oQ2P35odc5-NP, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 ._3j1lZlL3oQ2P35odc5-NP {
      margin-right: 3px;
      width: 50px;
      height: auto; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._2Lirwf3qLhfaJHwwdjQsqC, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._2Lirwf3qLhfaJHwwdjQsqC, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 ._2Lirwf3qLhfaJHwwdjQsqC {
      background-color: #E8E8E8;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      height: auto;
      padding: 5px;
      font-size: 12px;
      border-radius: 3px;
      margin-left: 10px; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA {
      cursor: pointer;
      font-size: 16px; }
      .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA .oq8D7bqLofLioUuwn91gF::after, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA .oq8D7bqLofLioUuwn91gF::after, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA .oq8D7bqLofLioUuwn91gF::after {
        display: none !important; }
      .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA ._3N8slgIEmDgKm-LnfQliKC, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA ._3N8slgIEmDgKm-LnfQliKC, ._2ls-n6OF0PmgR6XhBHlYnO ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA ._3N8slgIEmDgKm-LnfQliKC {
        margin-right: 3px;
        margin-top: -3px; }
  .s2mssoXIQwDRg_nmrUHqa .QUBAV65yZIoeucdc5CLz7, ._1wagRdPeMaKSc-kPUZS1ze .QUBAV65yZIoeucdc5CLz7, ._2ls-n6OF0PmgR6XhBHlYnO .QUBAV65yZIoeucdc5CLz7 {
    color: #0F172A;
    background: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 30px;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .s2mssoXIQwDRg_nmrUHqa .QUBAV65yZIoeucdc5CLz7 ._1fSApoitForU3DyRSHBPb-, ._1wagRdPeMaKSc-kPUZS1ze .QUBAV65yZIoeucdc5CLz7 ._1fSApoitForU3DyRSHBPb-, ._2ls-n6OF0PmgR6XhBHlYnO .QUBAV65yZIoeucdc5CLz7 ._1fSApoitForU3DyRSHBPb- {
      margin-right: 8px;
      margin-top: -3px; }

._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP {
  color: #475569;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 0 0 0 0;
  width: 100%;
  height: 16.5vh;
  overflow: auto !important; }
  ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table {
    overflow: auto !important; }
    ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table thead tr {
      padding: 24px;
      width: 100%; }
      ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table thead tr th {
        color: #0F172A;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        min-width: 280px; }
    ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table tbody tr {
      padding-bottom: 24px;
      border-bottom: 1px solid #F1F1F4;
      width: 100%; }
      ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table tbody tr td {
        min-width: 280px; }
      ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table tbody tr .LUBbO-Znc4YgQxjZL2hLh {
        color: #369327;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize; }
      ._1wagRdPeMaKSc-kPUZS1ze ._3SIG9BNwYSPmKptoDq7moP table tbody tr .geHjTjIWEpxbMPyJr2xk2 {
        margin-right: 3px;
        margin-top: -3px;
        cursor: pointer; }

._3_DqvVoPSac95jH702e39A {
  max-width: 798px;
  margin-top: 16px;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  color: #475569;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px; }
  ._3_DqvVoPSac95jH702e39A ._3wmt3AeW-4KiMPeRHucjZ2 {
    color: #0F172A;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; }
  ._3_DqvVoPSac95jH702e39A button {
    border-radius: 4px;
    height: 58px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; }
  ._3_DqvVoPSac95jH702e39A ._2Z95-UW7HL-L54LtAoHos- {
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
    color: #FFF;
    width: 363px; }
  ._3_DqvVoPSac95jH702e39A .v5PTC_QhcLY1OVrH-W5h, ._3_DqvVoPSac95jH702e39A .v5PTC_QhcLY1OVrH-W5h * {
    background: #E8E9EC !important;
    color: black !important;
    border-color: #E8E9EC !important;
    width: 363px; }
  ._3_DqvVoPSac95jH702e39A ._2Z95-UW7HL-L54LtAoHos-, ._3_DqvVoPSac95jH702e39A .v5PTC_QhcLY1OVrH-W5h {
    margin-top: 24px; }
  ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #F1F1F4; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._15dvrj7uvaqOjWZtiCLbpV {
      color: #A3AAB4;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin-top: 16px; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._3Psv7SzB6XNgcAPV6oL_de {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._3eh0l_Z47o2vG5iJoL5xH5 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #0F172A;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; }
  ._3_DqvVoPSac95jH702e39A b {
    color: #0F172A;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; }
  ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU {
    padding: 16px 0;
    border-bottom: 1px solid #F1F1F4; }
    ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU ._1CPimV5KhucueiYZp2KVtx {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 11px; }
    ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU ._31Gmtopm9j5eDtXQZUmYb5, ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU ._31Gmtopm9j5eDtXQZUmYb5 * {
      background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
      background: var(--Blue-Gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  ._3_DqvVoPSac95jH702e39A ._3x9M3wQgreS0g31Oo8DC5K {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 11px; }
  ._3_DqvVoPSac95jH702e39A ._3bvPAYqO2zGZZjkZdx5GgN {
    color: #475569;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin: 16px 0; }
  ._3_DqvVoPSac95jH702e39A .vpsGizWPZK4YnzjcIBehp {
    margin-top: 16px; }
  ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA {
    margin: 16px 0;
    border-radius: 5px;
    border: 1px solid #D1D7E2;
    padding: 16px; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA ._2F97O0iYf5JiE5JryXtZgs {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA ._3m8p68wM6iWRxfCOtOlXo0 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 12px;
      font-weight: normal; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA .UALCyD2zv27aPHhrGLPtr {
      color: white;
      padding-left: 2px; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA .XOSxmog8GJiHNTX5Hakbh {
      color: white;
      padding-right: 2px; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA .cnLK7ZOQ43n6-rMVEP1UF {
      width: 24px !important;
      height: 24px !important;
      border-radius: 4px !important;
      border: 1px solid #D1D7E2 !important;
      background: #FFF !important; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA ._1QVOX0fgjsDTsvSGYAbjXL {
      margin-left: 16px; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA .-FZGuDM6sEH6ANQDTWKSY {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; }
    ._3_DqvVoPSac95jH702e39A ._2gNhE_vfA9d3-QMxWAlGZA ._2PSu7IBij6s7zyRmYQU_s {
      color: #475569;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px; }

@media screen and (min-width: 521px) and (max-width: 990px) {
  ._2XaE1TJeXYkPJWy13uA1O- {
    height: 370px; }
  ._2XaE1TJeXYkPJWy13uA1O-, ._3pT8-28yqGui3CFWZDYpek {
    margin-top: 15px;
    padding: 18px;
    font-size: 12px; }
    ._2XaE1TJeXYkPJWy13uA1O- .LZ-gpR9rjTTkmHHvYSYcH, ._3pT8-28yqGui3CFWZDYpek .LZ-gpR9rjTTkmHHvYSYcH {
      font-size: 20px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj, ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj {
      font-size: 35px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC, ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC {
      font-size: 20px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1nOC2lDerVPvYyDDZzoen5, ._2XaE1TJeXYkPJWy13uA1O- ._1zdvThuSU6zmK4DFmjQBeK, ._3pT8-28yqGui3CFWZDYpek ._1nOC2lDerVPvYyDDZzoen5, ._3pT8-28yqGui3CFWZDYpek ._1zdvThuSU6zmK4DFmjQBeK {
      font-size: 14px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1w6ZUvoXEwcYGML7kNLYsS, ._3pT8-28yqGui3CFWZDYpek ._1w6ZUvoXEwcYGML7kNLYsS {
      font-size: 16px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1w6ZUvoXEwcYGML7kNLYsS, ._2XaE1TJeXYkPJWy13uA1O- ._1zdvThuSU6zmK4DFmjQBeK, ._3pT8-28yqGui3CFWZDYpek ._1w6ZUvoXEwcYGML7kNLYsS, ._3pT8-28yqGui3CFWZDYpek ._1zdvThuSU6zmK4DFmjQBeK {
      line-height: 22px; }
    ._2XaE1TJeXYkPJWy13uA1O- button, ._3pT8-28yqGui3CFWZDYpek button {
      font-size: 14px;
      line-height: 22px;
      height: 44px;
      margin-top: 44px; }
  ._1wagRdPeMaKSc-kPUZS1ze {
    padding: 18px; }
  ._3_DqvVoPSac95jH702e39A {
    font-size: 16px;
    padding: 18px;
    line-height: 24px; }
    ._3_DqvVoPSac95jH702e39A ._3wmt3AeW-4KiMPeRHucjZ2 {
      font-size: 20px; }
    ._3_DqvVoPSac95jH702e39A button {
      font-size: 14px; }
    ._3_DqvVoPSac95jH702e39A ._2Z95-UW7HL-L54LtAoHos-, ._3_DqvVoPSac95jH702e39A .v5PTC_QhcLY1OVrH-W5h {
      height: 44px;
      width: 200px; }
    ._3_DqvVoPSac95jH702e39A b {
      font-size: 16px; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._15dvrj7uvaqOjWZtiCLbpV {
      font-size: 15px;
      line-height: 20px; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._3eh0l_Z47o2vG5iJoL5xH5 {
      font-size: 25px; }
    ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU ._1CPimV5KhucueiYZp2KVtx {
      font-size: 16px; } }

@media screen and (max-width: 520px) {
  ._2XaE1TJeXYkPJWy13uA1O- {
    height: 370px; }
  ._2XaE1TJeXYkPJWy13uA1O-, ._3pT8-28yqGui3CFWZDYpek {
    font-size: 10px;
    padding: 18px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._3rkybi7hZHaYKsHSpYqOHu, ._3pT8-28yqGui3CFWZDYpek ._3rkybi7hZHaYKsHSpYqOHu {
      font-size: 8px; }
    ._2XaE1TJeXYkPJWy13uA1O- .LZ-gpR9rjTTkmHHvYSYcH, ._3pT8-28yqGui3CFWZDYpek .LZ-gpR9rjTTkmHHvYSYcH {
      font-size: 18px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj, ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._2ce-sA_Z-SQVAYwUAIZpuj {
      font-size: 33px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC, ._3pT8-28yqGui3CFWZDYpek ._2lmADJIm33i40tHoRgO_c4 ._3QN8UqUYmItCtQ6o51kZtC {
      font-size: 18px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1nOC2lDerVPvYyDDZzoen5, ._2XaE1TJeXYkPJWy13uA1O- ._1zdvThuSU6zmK4DFmjQBeK, ._3pT8-28yqGui3CFWZDYpek ._1nOC2lDerVPvYyDDZzoen5, ._3pT8-28yqGui3CFWZDYpek ._1zdvThuSU6zmK4DFmjQBeK {
      font-size: 12px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1w6ZUvoXEwcYGML7kNLYsS, ._3pT8-28yqGui3CFWZDYpek ._1w6ZUvoXEwcYGML7kNLYsS {
      font-size: 14px; }
    ._2XaE1TJeXYkPJWy13uA1O- ._1w6ZUvoXEwcYGML7kNLYsS, ._2XaE1TJeXYkPJWy13uA1O- ._1zdvThuSU6zmK4DFmjQBeK, ._3pT8-28yqGui3CFWZDYpek ._1w6ZUvoXEwcYGML7kNLYsS, ._3pT8-28yqGui3CFWZDYpek ._1zdvThuSU6zmK4DFmjQBeK {
      line-height: 20px; }
    ._2XaE1TJeXYkPJWy13uA1O- button, ._3pT8-28yqGui3CFWZDYpek button {
      font-size: 13px;
      line-height: 20px;
      height: 40px;
      margin-top: 40px; }
  .s2mssoXIQwDRg_nmrUHqa, ._1wagRdPeMaKSc-kPUZS1ze {
    padding: 18px;
    font-size: 12px; }
    .s2mssoXIQwDRg_nmrUHqa ._3wmt3AeW-4KiMPeRHucjZ2, ._1wagRdPeMaKSc-kPUZS1ze ._3wmt3AeW-4KiMPeRHucjZ2 {
      font-size: 18px; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3j1lZlL3oQ2P35odc5-NP, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3j1lZlL3oQ2P35odc5-NP {
      width: 40px;
      height: 40px; }
    .s2mssoXIQwDRg_nmrUHqa ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA ._1lLya-gtb7YR91XvSKOLLg, ._1wagRdPeMaKSc-kPUZS1ze ._8WDbbSWAYd4PKd74M8UD5 ._3tD7tLOjf-F3p2P8Gd4FOA ._1lLya-gtb7YR91XvSKOLLg {
      display: none; }
    .s2mssoXIQwDRg_nmrUHqa .QUBAV65yZIoeucdc5CLz7, ._1wagRdPeMaKSc-kPUZS1ze .QUBAV65yZIoeucdc5CLz7 {
      font-size: 13px;
      line-height: 20px;
      height: 40px; }
      .s2mssoXIQwDRg_nmrUHqa .QUBAV65yZIoeucdc5CLz7 ._1fSApoitForU3DyRSHBPb-, ._1wagRdPeMaKSc-kPUZS1ze .QUBAV65yZIoeucdc5CLz7 ._1fSApoitForU3DyRSHBPb- {
        width: 30px;
        height: 30px; }
  ._3_DqvVoPSac95jH702e39A {
    padding: 18px;
    font-size: 14px;
    line-height: 18px; }
    ._3_DqvVoPSac95jH702e39A ._3wmt3AeW-4KiMPeRHucjZ2 {
      font-size: 18px; }
    ._3_DqvVoPSac95jH702e39A button {
      font-size: 13px;
      line-height: 20px; }
    ._3_DqvVoPSac95jH702e39A ._2Z95-UW7HL-L54LtAoHos-, ._3_DqvVoPSac95jH702e39A .v5PTC_QhcLY1OVrH-W5h {
      height: 40px;
      width: 130px; }
    ._3_DqvVoPSac95jH702e39A b {
      font-size: 16px; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._15dvrj7uvaqOjWZtiCLbpV {
      font-size: 13px;
      line-height: 16px; }
    ._3_DqvVoPSac95jH702e39A ._1Go25bX69b3iIF7L32XsDe ._3eh0l_Z47o2vG5iJoL5xH5 {
      font-size: 22px; }
    ._3_DqvVoPSac95jH702e39A ._3vkta1qnNXGTPOgo6beBeU ._1CPimV5KhucueiYZp2KVtx {
      font-size: 15px; } }

.myClass .dropdown-toggle::after {
    display: none !important;
}

._2yvqAQMZSFOqTZElzvSpvG {
  min-height: 100vh;
  margin: 0 15px;
  padding: 0 5px 10px 0; }

button {
  border-radius: 4px;
  height: 58px;
  text-align: center; }

._35NamhS1v6H75jQaKYEcYY {
  border-radius: 8px;
  background: rgba(71, 123, 255, 0.1);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 56px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer; }
  ._35NamhS1v6H75jQaKYEcYY ._2QY5jYdIy82XIcFLIEYsPH {
    background: linear-gradient(135deg, #2946c1 0%, #0779eb 100%);
    background: var(--Blue-Gradient, linear-gradient(135deg, #2946c1 0%, #0779eb 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

._1CK9VOGWsDvPP1G-m4mXr8,
._2JrkXT4fJYS2I3hepPu-Jb,
._3OASv2p_0qA7cEpNpJnh9a {
  line-height: normal;
  font-style: normal;
  color: #000; }
  ._1CK9VOGWsDvPP1G-m4mXr8 ._2JZZr82O7TVEyu7NSsIS4l,
  ._2JrkXT4fJYS2I3hepPu-Jb ._2JZZr82O7TVEyu7NSsIS4l,
  ._3OASv2p_0qA7cEpNpJnh9a ._2JZZr82O7TVEyu7NSsIS4l {
    color: #000a23;
    color: var(--Primary-Dark, #000a23);
    font-size: 24px;
    font-weight: 400;
    padding: 8px; }
  ._1CK9VOGWsDvPP1G-m4mXr8 h6,
  ._2JrkXT4fJYS2I3hepPu-Jb h6,
  ._3OASv2p_0qA7cEpNpJnh9a h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 9px; }
  ._1CK9VOGWsDvPP1G-m4mXr8 p,
  ._1CK9VOGWsDvPP1G-m4mXr8 ol,
  ._2JrkXT4fJYS2I3hepPu-Jb p,
  ._2JrkXT4fJYS2I3hepPu-Jb ol,
  ._3OASv2p_0qA7cEpNpJnh9a p,
  ._3OASv2p_0qA7cEpNpJnh9a ol {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px; }
  ._1CK9VOGWsDvPP1G-m4mXr8 ._9hoUiiWT-9ZMu2cYBrMHV,
  ._2JrkXT4fJYS2I3hepPu-Jb ._9hoUiiWT-9ZMu2cYBrMHV,
  ._3OASv2p_0qA7cEpNpJnh9a ._9hoUiiWT-9ZMu2cYBrMHV {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer; }

._3OASv2p_0qA7cEpNpJnh9a ._24Iv1bFuPp-JYedlYDJMEx {
  background: linear-gradient(135deg, #2946c1 0%, #0779eb 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946c1 0%, #0779eb 100%));
  color: #fff; }

._3OASv2p_0qA7cEpNpJnh9a ._24Iv1bFuPp-JYedlYDJMEx {
  height: 36px;
  flex-shrink: 0; }
  ._3OASv2p_0qA7cEpNpJnh9a ._24Iv1bFuPp-JYedlYDJMEx span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; }

._3OASv2p_0qA7cEpNpJnh9a ._1rfLMQB9uPZUvuBjVgGXaf {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

._2JrkXT4fJYS2I3hepPu-Jb {
  font-size: 16px;
  font-weight: 200;
  line-height: 22px;
  text-align: center;
  color: #000; }
  ._2JrkXT4fJYS2I3hepPu-Jb ._1VeNSRKEfVOSA5k0XmImhv {
    color: #000a23;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; }
  ._2JrkXT4fJYS2I3hepPu-Jb ._2W1PNN7HJNZ6uiaj3uqwU9 {
    background: linear-gradient(135deg, #2946c1 0%, #0779eb 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946c1 0%, #0779eb 100%));
    color: #fff;
    width: 140px;
    height: 56px; }

._1jYgl-FjeWkG79OqW9y943 {
  min-height: 230px;
  font-size: 18px;
  line-height: normal;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content; }

.XRs9NMkUxBB0FXAmmTYt7,
._3TNICc9mVn3yhUAugPbmz7 {
  height: 320px;
  font-size: 14px;
  line-height: 24px; }

._1jYgl-FjeWkG79OqW9y943,
.XRs9NMkUxBB0FXAmmTYt7,
._3TNICc9mVn3yhUAugPbmz7 {
  border-radius: 8px;
  margin-top: 16px;
  align-items: flex-start;
  padding: 24px;
  background: #fff;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  font-weight: 300;
  color: #475569;
  border: none; }
  ._1jYgl-FjeWkG79OqW9y943 ._2JZZr82O7TVEyu7NSsIS4l,
  .XRs9NMkUxBB0FXAmmTYt7 ._2JZZr82O7TVEyu7NSsIS4l,
  ._3TNICc9mVn3yhUAugPbmz7 ._2JZZr82O7TVEyu7NSsIS4l {
    color: #000a23;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  ._1jYgl-FjeWkG79OqW9y943 ._1fs9Vr98r6p_nIziueBKzY,
  .XRs9NMkUxBB0FXAmmTYt7 ._1fs9Vr98r6p_nIziueBKzY,
  ._3TNICc9mVn3yhUAugPbmz7 ._1fs9Vr98r6p_nIziueBKzY {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 11px; }
  ._1jYgl-FjeWkG79OqW9y943 ._2zTUceYem6HUaPF48Z_8DU,
  .XRs9NMkUxBB0FXAmmTYt7 ._2zTUceYem6HUaPF48Z_8DU,
  ._3TNICc9mVn3yhUAugPbmz7 ._2zTUceYem6HUaPF48Z_8DU {
    margin-top: 16px; }
  ._1jYgl-FjeWkG79OqW9y943 ._1vbKbgxQyj4jj3XEZZ4Nn7,
  .XRs9NMkUxBB0FXAmmTYt7 ._1vbKbgxQyj4jj3XEZZ4Nn7,
  ._3TNICc9mVn3yhUAugPbmz7 ._1vbKbgxQyj4jj3XEZZ4Nn7 {
    cursor: pointer; }
    ._1jYgl-FjeWkG79OqW9y943 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._46BGRAWFYoz0qhRVJccYV,
    .XRs9NMkUxBB0FXAmmTYt7 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._46BGRAWFYoz0qhRVJccYV,
    ._3TNICc9mVn3yhUAugPbmz7 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._46BGRAWFYoz0qhRVJccYV {
      margin-right: 5px; }
    ._1jYgl-FjeWkG79OqW9y943 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._2QY5jYdIy82XIcFLIEYsPH,
    .XRs9NMkUxBB0FXAmmTYt7 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._2QY5jYdIy82XIcFLIEYsPH,
    ._3TNICc9mVn3yhUAugPbmz7 ._1vbKbgxQyj4jj3XEZZ4Nn7 ._2QY5jYdIy82XIcFLIEYsPH {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: normal; }
  ._1jYgl-FjeWkG79OqW9y943 .Pr3MaG9mVWQyPwbTg6WT-,
  ._1jYgl-FjeWkG79OqW9y943 .Pr3MaG9mVWQyPwbTg6WT- *,
  .XRs9NMkUxBB0FXAmmTYt7 .Pr3MaG9mVWQyPwbTg6WT-,
  .XRs9NMkUxBB0FXAmmTYt7 .Pr3MaG9mVWQyPwbTg6WT- *,
  ._3TNICc9mVn3yhUAugPbmz7 .Pr3MaG9mVWQyPwbTg6WT-,
  ._3TNICc9mVn3yhUAugPbmz7 .Pr3MaG9mVWQyPwbTg6WT- * {
    background: linear-gradient(135deg, #2946c1 0%, #0779eb 100%);
    background: var(--Blue-Gradient, linear-gradient(135deg, #2946c1 0%, #0779eb 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  ._1jYgl-FjeWkG79OqW9y943 ._1VaCKYym_bFdRT_XEjPCtQ,
  ._1jYgl-FjeWkG79OqW9y943 ._2Ib6V-cAlDW_glAzx66W5L,
  ._1jYgl-FjeWkG79OqW9y943 .cJACRUZQm5xbX-SFh7byK,
  ._1jYgl-FjeWkG79OqW9y943 ._24Iv1bFuPp-JYedlYDJMEx,
  .XRs9NMkUxBB0FXAmmTYt7 ._1VaCKYym_bFdRT_XEjPCtQ,
  .XRs9NMkUxBB0FXAmmTYt7 ._2Ib6V-cAlDW_glAzx66W5L,
  .XRs9NMkUxBB0FXAmmTYt7 .cJACRUZQm5xbX-SFh7byK,
  .XRs9NMkUxBB0FXAmmTYt7 ._24Iv1bFuPp-JYedlYDJMEx,
  ._3TNICc9mVn3yhUAugPbmz7 ._1VaCKYym_bFdRT_XEjPCtQ,
  ._3TNICc9mVn3yhUAugPbmz7 ._2Ib6V-cAlDW_glAzx66W5L,
  ._3TNICc9mVn3yhUAugPbmz7 .cJACRUZQm5xbX-SFh7byK,
  ._3TNICc9mVn3yhUAugPbmz7 ._24Iv1bFuPp-JYedlYDJMEx {
    background: linear-gradient(135deg, #2946c1 0%, #0779eb 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946c1 0%, #0779eb 100%));
    color: #fff; }
  ._1jYgl-FjeWkG79OqW9y943 ._1VaCKYym_bFdRT_XEjPCtQ,
  .XRs9NMkUxBB0FXAmmTYt7 ._1VaCKYym_bFdRT_XEjPCtQ,
  ._3TNICc9mVn3yhUAugPbmz7 ._1VaCKYym_bFdRT_XEjPCtQ {
    margin-left: 12px;
    width: 140px; }
  ._1jYgl-FjeWkG79OqW9y943 .cJACRUZQm5xbX-SFh7byK,
  .XRs9NMkUxBB0FXAmmTYt7 .cJACRUZQm5xbX-SFh7byK,
  ._3TNICc9mVn3yhUAugPbmz7 .cJACRUZQm5xbX-SFh7byK {
    height: 36px;
    flex-shrink: 0;
    width: 120px; }
    ._1jYgl-FjeWkG79OqW9y943 .cJACRUZQm5xbX-SFh7byK span,
    .XRs9NMkUxBB0FXAmmTYt7 .cJACRUZQm5xbX-SFh7byK span,
    ._3TNICc9mVn3yhUAugPbmz7 .cJACRUZQm5xbX-SFh7byK span {
      color: #fff;
      text-align: center;
      font-style: normal;
      line-height: 24px; }
  ._1jYgl-FjeWkG79OqW9y943 ._24Iv1bFuPp-JYedlYDJMEx,
  .XRs9NMkUxBB0FXAmmTYt7 ._24Iv1bFuPp-JYedlYDJMEx,
  ._3TNICc9mVn3yhUAugPbmz7 ._24Iv1bFuPp-JYedlYDJMEx {
    height: 36px;
    flex-shrink: 0; }
    ._1jYgl-FjeWkG79OqW9y943 ._24Iv1bFuPp-JYedlYDJMEx span,
    .XRs9NMkUxBB0FXAmmTYt7 ._24Iv1bFuPp-JYedlYDJMEx span,
    ._3TNICc9mVn3yhUAugPbmz7 ._24Iv1bFuPp-JYedlYDJMEx span {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; }
  ._1jYgl-FjeWkG79OqW9y943 ._2Ib6V-cAlDW_glAzx66W5L,
  .XRs9NMkUxBB0FXAmmTYt7 ._2Ib6V-cAlDW_glAzx66W5L,
  ._3TNICc9mVn3yhUAugPbmz7 ._2Ib6V-cAlDW_glAzx66W5L {
    height: 36px;
    flex-shrink: 0;
    width: 100px; }
    ._1jYgl-FjeWkG79OqW9y943 ._2Ib6V-cAlDW_glAzx66W5L span,
    .XRs9NMkUxBB0FXAmmTYt7 ._2Ib6V-cAlDW_glAzx66W5L span,
    ._3TNICc9mVn3yhUAugPbmz7 ._2Ib6V-cAlDW_glAzx66W5L span {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; }
  ._1jYgl-FjeWkG79OqW9y943 ._3pQV9-dFxY3ctQ5KEGYVdW,
  .XRs9NMkUxBB0FXAmmTYt7 ._3pQV9-dFxY3ctQ5KEGYVdW,
  ._3TNICc9mVn3yhUAugPbmz7 ._3pQV9-dFxY3ctQ5KEGYVdW {
    color: #000;
    font-size: 14px;
    font-weight: 200;
    line-height: normal; }
  ._1jYgl-FjeWkG79OqW9y943 ._1VeNSRKEfVOSA5k0XmImhv,
  .XRs9NMkUxBB0FXAmmTYt7 ._1VeNSRKEfVOSA5k0XmImhv,
  ._3TNICc9mVn3yhUAugPbmz7 ._1VeNSRKEfVOSA5k0XmImhv {
    color: #000a23;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 16px; }
  ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs,
  .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs,
  ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs {
    color: #475569;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0 0 0 0;
    width: 100%;
    height: 16.5vh;
    overflow: auto !important; }
    ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table,
    .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table,
    ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table {
      overflow: auto !important; }
      ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr,
      .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr,
      ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr {
        padding: 24px;
        width: 100%;
        border-bottom: 1px solid #f1f1f4; }
        ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr th,
        .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr th,
        ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table thead tr th {
          color: #0f172a;
          font-size: 14px;
          font-weight: 500; }
      ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr,
      .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr,
      ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr {
        padding-bottom: 24px;
        border-bottom: 1px solid #f1f1f4;
        width: 100%; }
        ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr td,
        .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr td,
        ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr td {
          min-width: 100px; }
        ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr ._1wdHZpde5R5G3ESe0_zGqp,
        ._1jYgl-FjeWkG79OqW9y943 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr .DaACI4FnNvhsMnUzSYSQ8,
        .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr ._1wdHZpde5R5G3ESe0_zGqp,
        .XRs9NMkUxBB0FXAmmTYt7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr .DaACI4FnNvhsMnUzSYSQ8,
        ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr ._1wdHZpde5R5G3ESe0_zGqp,
        ._3TNICc9mVn3yhUAugPbmz7 ._3BDDbJ_TGh6Is4C0fJFCHs table tbody tr .DaACI4FnNvhsMnUzSYSQ8 {
          color: #000a23;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: capitalize;
          border-radius: 4px;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          padding: 6px 12px; }

._3b1zX8J2LopwpvVSizG8Oc {
  padding: 0 0 35px; }

._2earuqvuiZXps7BDrQdYS- {
  float: right; }
  ._2earuqvuiZXps7BDrQdYS- button {
    width: 100px;
    border-radius: 8px; }
  ._2earuqvuiZXps7BDrQdYS- button:first-child {
    margin-right: 10px; }
  ._2earuqvuiZXps7BDrQdYS- button:last-child {
    border: 1px solid black;
    font-weight: bold; }

@media screen and (min-width: 521px) and (max-width: 990px) {
  ._3w6gAJdHLBg_sO2mtGVlE- {
    height: 370px; }
  ._3w6gAJdHLBg_sO2mtGVlE-,
  ._2ukXqRMz28cNQ84qxS8CE6 {
    margin-top: 15px;
    padding: 18px;
    font-size: 12px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._1YVdfYKuWJBLbGELtaHtOy,
    ._2ukXqRMz28cNQ84qxS8CE6 ._1YVdfYKuWJBLbGELtaHtOy {
      font-size: 20px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .Uk8b54Xc-Vh29zmWfvlFO .ZJVzq7Y7j_NCZJWRsh1Ok,
    ._2ukXqRMz28cNQ84qxS8CE6 .Uk8b54Xc-Vh29zmWfvlFO .ZJVzq7Y7j_NCZJWRsh1Ok {
      font-size: 35px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .Uk8b54Xc-Vh29zmWfvlFO ._2bJzm8KXdraQzNiUcblJfZ,
    ._2ukXqRMz28cNQ84qxS8CE6 .Uk8b54Xc-Vh29zmWfvlFO ._2bJzm8KXdraQzNiUcblJfZ {
      font-size: 20px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .HRI_uNs86UpDTj3WaKEYI,
    ._3w6gAJdHLBg_sO2mtGVlE- ._3GmPGOOo4GpcMlZOANriUz,
    ._2ukXqRMz28cNQ84qxS8CE6 .HRI_uNs86UpDTj3WaKEYI,
    ._2ukXqRMz28cNQ84qxS8CE6 ._3GmPGOOo4GpcMlZOANriUz {
      font-size: 14px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._2fE69jhW6wUJecOEfv_EVn,
    ._2ukXqRMz28cNQ84qxS8CE6 ._2fE69jhW6wUJecOEfv_EVn {
      font-size: 16px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._2fE69jhW6wUJecOEfv_EVn,
    ._3w6gAJdHLBg_sO2mtGVlE- ._3GmPGOOo4GpcMlZOANriUz,
    ._2ukXqRMz28cNQ84qxS8CE6 ._2fE69jhW6wUJecOEfv_EVn,
    ._2ukXqRMz28cNQ84qxS8CE6 ._3GmPGOOo4GpcMlZOANriUz {
      line-height: 22px; }
    ._3w6gAJdHLBg_sO2mtGVlE- button,
    ._2ukXqRMz28cNQ84qxS8CE6 button {
      font-size: 14px;
      line-height: 22px;
      height: 44px;
      margin-top: 44px; }
  .EXn5unz6rCMeoGi6BUgrS {
    padding: 18px; }
  ._1cnhSMSPFyHBZ5ixZpErIk {
    font-size: 16px;
    padding: 18px;
    line-height: 24px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._2JZZr82O7TVEyu7NSsIS4l {
      font-size: 20px; }
    ._1cnhSMSPFyHBZ5ixZpErIk button {
      font-size: 14px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._3nRz3dPW0AtIDVBHr8HDed,
    ._1cnhSMSPFyHBZ5ixZpErIk ._1H9yHT6eOuxKdsC86bz6mB {
      height: 44px;
      width: 200px; }
    ._1cnhSMSPFyHBZ5ixZpErIk b {
      font-size: 16px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._1CzRrPsR2PQ_RJrVeU1Tw0 ._1XROwphLSA-DGajTndNKqu {
      font-size: 15px;
      line-height: 20px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._1CzRrPsR2PQ_RJrVeU1Tw0 ._Z72U2LlKZdHSk-zoXYly {
      font-size: 25px; }
    ._1cnhSMSPFyHBZ5ixZpErIk .VM_DZogYas0WXRzDgZaU2 .W81GBr1x-IzQZyY8VzO9H {
      font-size: 16px; } }

@media screen and (max-width: 520px) {
  ._3w6gAJdHLBg_sO2mtGVlE- {
    height: 370px; }
  ._3w6gAJdHLBg_sO2mtGVlE-,
  ._2ukXqRMz28cNQ84qxS8CE6 {
    font-size: 10px;
    padding: 18px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._1YVdfYKuWJBLbGELtaHtOy,
    ._2ukXqRMz28cNQ84qxS8CE6 ._1YVdfYKuWJBLbGELtaHtOy {
      font-size: 18px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .Uk8b54Xc-Vh29zmWfvlFO .ZJVzq7Y7j_NCZJWRsh1Ok,
    ._2ukXqRMz28cNQ84qxS8CE6 .Uk8b54Xc-Vh29zmWfvlFO .ZJVzq7Y7j_NCZJWRsh1Ok {
      font-size: 33px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .Uk8b54Xc-Vh29zmWfvlFO ._2bJzm8KXdraQzNiUcblJfZ,
    ._2ukXqRMz28cNQ84qxS8CE6 .Uk8b54Xc-Vh29zmWfvlFO ._2bJzm8KXdraQzNiUcblJfZ {
      font-size: 18px; }
    ._3w6gAJdHLBg_sO2mtGVlE- .HRI_uNs86UpDTj3WaKEYI,
    ._3w6gAJdHLBg_sO2mtGVlE- ._3GmPGOOo4GpcMlZOANriUz,
    ._2ukXqRMz28cNQ84qxS8CE6 .HRI_uNs86UpDTj3WaKEYI,
    ._2ukXqRMz28cNQ84qxS8CE6 ._3GmPGOOo4GpcMlZOANriUz {
      font-size: 12px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._2fE69jhW6wUJecOEfv_EVn,
    ._2ukXqRMz28cNQ84qxS8CE6 ._2fE69jhW6wUJecOEfv_EVn {
      font-size: 14px; }
    ._3w6gAJdHLBg_sO2mtGVlE- ._2fE69jhW6wUJecOEfv_EVn,
    ._3w6gAJdHLBg_sO2mtGVlE- ._3GmPGOOo4GpcMlZOANriUz,
    ._2ukXqRMz28cNQ84qxS8CE6 ._2fE69jhW6wUJecOEfv_EVn,
    ._2ukXqRMz28cNQ84qxS8CE6 ._3GmPGOOo4GpcMlZOANriUz {
      line-height: 20px; }
    ._3w6gAJdHLBg_sO2mtGVlE- button,
    ._2ukXqRMz28cNQ84qxS8CE6 button {
      font-size: 13px;
      line-height: 20px;
      height: 40px;
      margin-top: 40px; }
  ._3QfvCxANvQvTx65W64QqTq,
  .EXn5unz6rCMeoGi6BUgrS {
    padding: 18px;
    font-size: 12px; }
    ._3QfvCxANvQvTx65W64QqTq ._2JZZr82O7TVEyu7NSsIS4l,
    .EXn5unz6rCMeoGi6BUgrS ._2JZZr82O7TVEyu7NSsIS4l {
      font-size: 18px; }
    ._3QfvCxANvQvTx65W64QqTq .nt8O50w8eWVy9xSo66AHq ._22VlbfrIFVkJK-PsRR1l9x,
    .EXn5unz6rCMeoGi6BUgrS .nt8O50w8eWVy9xSo66AHq ._22VlbfrIFVkJK-PsRR1l9x {
      width: 40px;
      height: 40px; }
    ._3QfvCxANvQvTx65W64QqTq .nt8O50w8eWVy9xSo66AHq ._2ZG3PsMcvD0g5aak3sbVfZ ._3YuuxDtrj-ewMpCT-LxhXm,
    .EXn5unz6rCMeoGi6BUgrS .nt8O50w8eWVy9xSo66AHq ._2ZG3PsMcvD0g5aak3sbVfZ ._3YuuxDtrj-ewMpCT-LxhXm {
      display: none; }
    ._3QfvCxANvQvTx65W64QqTq ._1b6imYt2tMP0PRFXcvTktJ,
    .EXn5unz6rCMeoGi6BUgrS ._1b6imYt2tMP0PRFXcvTktJ {
      font-size: 13px;
      line-height: 20px;
      height: 40px; }
      ._3QfvCxANvQvTx65W64QqTq ._1b6imYt2tMP0PRFXcvTktJ .nMz09OjnOEQ5mWKP95e-Y,
      .EXn5unz6rCMeoGi6BUgrS ._1b6imYt2tMP0PRFXcvTktJ .nMz09OjnOEQ5mWKP95e-Y {
        width: 30px;
        height: 30px; }
  ._1cnhSMSPFyHBZ5ixZpErIk {
    padding: 18px;
    font-size: 14px;
    line-height: 18px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._2JZZr82O7TVEyu7NSsIS4l {
      font-size: 18px; }
    ._1cnhSMSPFyHBZ5ixZpErIk button {
      font-size: 13px;
      line-height: 20px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._3nRz3dPW0AtIDVBHr8HDed,
    ._1cnhSMSPFyHBZ5ixZpErIk ._1H9yHT6eOuxKdsC86bz6mB {
      height: 40px;
      width: 130px; }
    ._1cnhSMSPFyHBZ5ixZpErIk b {
      font-size: 16px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._1CzRrPsR2PQ_RJrVeU1Tw0 ._1XROwphLSA-DGajTndNKqu {
      font-size: 13px;
      line-height: 16px; }
    ._1cnhSMSPFyHBZ5ixZpErIk ._1CzRrPsR2PQ_RJrVeU1Tw0 ._Z72U2LlKZdHSk-zoXYly {
      font-size: 22px; }
    ._1cnhSMSPFyHBZ5ixZpErIk .VM_DZogYas0WXRzDgZaU2 .W81GBr1x-IzQZyY8VzO9H {
      font-size: 15px; } }

.react-multi-email {
    padding: 0 0.5em !important;
    margin-top: 15px;
    width: 75%;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.react-multi-email > input {
    font-size: 1rem !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}

.react-multi-email.focused {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.KlXL6RoYPxjCuh0Q_VYIL {
  cursor: pointer; }

._1DZAkuTBia-5iTBjDPtHQP {
  margin-top: 4%; }
  ._1DZAkuTBia-5iTBjDPtHQP input {
    background: #edf1f6 !important; }

.kNoc2LrS6EnW5yK9Fv0Aa {
  font-size: 48px !important; }

._1P5YWgcadtReizCpXOsClf {
  display: flex;
  justify-content: space-around;
  margin: auto;
  font-size: 16px;
  margin-top: 16px;
  width: 240px;
  font-weight: bold;
  margin-bottom: 45px !important; }

._1fWA4QbrsFFlGWy-TkPsNz {
  font-weight: 600; }
  ._1fWA4QbrsFFlGWy-TkPsNz input {
    height: 17px !important;
    width: 17px !important; }
  ._1fWA4QbrsFFlGWy-TkPsNz label {
    margin-left: 5px; }

._1wJE5G93EwKiak27NKrCUd {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

._2OdEa9gRDM46wZY_qC--T- {
  display: flex !important;
  align-items: center; }
  ._2OdEa9gRDM46wZY_qC--T- > div:first-child {
    margin-bottom: 0px !important; }
    ._2OdEa9gRDM46wZY_qC--T- > div:first-child > div:first-child {
      background: #edf1f6;
      border: none; }
      ._2OdEa9gRDM46wZY_qC--T- > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  ._2OdEa9gRDM46wZY_qC--T- p {
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0px !important; }
    ._2OdEa9gRDM46wZY_qC--T- p span {
      color: #0b71ff; }
      ._2OdEa9gRDM46wZY_qC--T- p span:hover {
        cursor: pointer; }

._3W3pnH55nzYuCV_Mnd9VnX {
  margin: 32px 0;
  font-weight: bold; }

.XKX1_yzZT0eoFhSleQSRT {
  margin-bottom: 4px; }

._1yr8y5FspMBfgeb7KbpMB_ {
  text-align: center;
  color: #031937; }
  ._1yr8y5FspMBfgeb7KbpMB_ span {
    color: #0b71ff; }
    ._1yr8y5FspMBfgeb7KbpMB_ span:hover {
      cursor: pointer; }

._2lnxnMo4-RYEKwoQcSVW2U {
  position: relative;
  background-image: url(/static/media/onboarding-background.5798e1b0.svg);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0; }

._2p1BHIX_SHhgUC2LZls8ga ._2Y2oUIwhVnbgTTwu0zXPIQ {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: auto; }

._2p1BHIX_SHhgUC2LZls8ga ._2pTzxPxqCGQlHTbJ2oFYdh {
  margin-top: 2.5%; }

._2p1BHIX_SHhgUC2LZls8ga label {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

._2p1BHIX_SHhgUC2LZls8ga select {
  background-color: #FFFFFF; }

._2p1BHIX_SHhgUC2LZls8ga input {
  background: #FFFFFF !important; }

._2p1BHIX_SHhgUC2LZls8ga button {
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._2p1BHIX_SHhgUC2LZls8ga ._2wJh1CeTx_U_tplGyXI1j9 {
  margin-top: 10px;
  display: flex;
  flex-direction: row; }
  ._2p1BHIX_SHhgUC2LZls8ga ._2wJh1CeTx_U_tplGyXI1j9 button {
    margin-top: 0px !important; }

._1htKVr__qt8aVFnaoW9pJ_ {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  width: 532px; }
  ._1htKVr__qt8aVFnaoW9pJ_ h2 {
    color: #000; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._2DtQ_CvxYj_mSyTrS_3L4_ {
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._2YLJVgzq6YkiBgaVnrZDXm {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._1cwhoaLgJ4Tj-X7VYvzwYo {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px; }
  ._1htKVr__qt8aVFnaoW9pJ_ .B7b3meZjYDZNFGMGSEAus {
    color: #475569; }
  ._1htKVr__qt8aVFnaoW9pJ_ .E7BqCZWHbDp-zU_Jgt1y {
    text-align: right;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #04B5FF;
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
    ._1htKVr__qt8aVFnaoW9pJ_ .E7BqCZWHbDp-zU_Jgt1y:hover {
      cursor: pointer; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._2_9sqZXALFmtkuUvcWB-iQ {
    font-size: 16px;
    line-height: 22px;
    color: #475569; }
    ._1htKVr__qt8aVFnaoW9pJ_ ._2_9sqZXALFmtkuUvcWB-iQ ._2hAMWEmPK_AX5u1PBrVNjg {
      cursor: pointer;
      font-weight: 500; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._1adzvQy0j1e31PMCaJxbg0 {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  ._1htKVr__qt8aVFnaoW9pJ_ ._3KQm9k9zzzcy3fqTRpt2k {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._1etDVVAbstuLHN1pNnYZAK {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._3zMq_EhdW304B0twrofhl_ {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

._3Xf9I_U2NYLHHNiQKvKOZt {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

._1sw6fCNfDmfgsd9V47BG8H {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

.KlyKElqr02jdH6Rh8-Q0Z {
  display: flex;
  justify-content: center; }

._1DwZn-i9G9VEwHpqZW8Tym input {
  height: 17px;
  width: 17px; }

._1DwZn-i9G9VEwHpqZW8Tym p {
  font-weight: 300 !important; }
  ._1DwZn-i9G9VEwHpqZW8Tym p span {
    font-weight: bold; }

._1DwZn-i9G9VEwHpqZW8Tym label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  ._1DwZn-i9G9VEwHpqZW8Tym label p span {
    color: #0b71ff; }
    ._1DwZn-i9G9VEwHpqZW8Tym label p span:hover {
      cursor: pointer; }

._2rd9mbE-juJgtZ5WDTKip7 {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px; }

._7kVgk8DVgObmsZB3tOH5N {
  display: none; }

._1Yiuv0fxhg9O8oKrC95bxF {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._13y4YLbdPkpGxtumqdtLWu {
  display: block; }

._1Yiuv0fxhg9O8oKrC95bxF {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

.ov17b0rcc9pe2ZVZJrtGv {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

._2-48JbbFHK_DFWsY9hYhQa {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

._2sJ5tIPG0QRb1TcFDpJ6Wl {
  margin-top: 30%; }

._3vwKVuhuA5rRTozKlEytet {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._2DGPw-5ToxiQcDlpUbpIbV span {
  display: inline-block; }

._36A1Qj4Lbp2tPg3W0fYopH {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._1egiRMJPV73hhkz68MdOm5 {
  margin-top: 25px; }

._2jF7KMFN1tVJozrq1weVzT {
  margin-top: 10px; }

._7JI_mcr2C4M1pCzlv6c61 {
  height: 200px;
  width: 200px;
  object-fit: contain; }

.AfG3JLLxRf6UVLuENe8-3 {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  ._7JI_mcr2C4M1pCzlv6c61 {
    height: 200px;
    width: 200px; } }

._2PY8WmuG20RNNbeabABDef {
  margin-top: 14px; }

._31qlCAoftyWeb-u2hKbslZ {
  margin-top: 30px; }

._11StTi8zO2B-cVw0gCrBDU {
  border: none;
  color: black; }
  ._11StTi8zO2B-cVw0gCrBDU:hover {
    background: transparent;
    color: black; }

._3NQD1-N9L7ne2-kMdKIqmz {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  ._3NQD1-N9L7ne2-kMdKIqmz div:first-child {
    width: 100% !important; }
    ._3NQD1-N9L7ne2-kMdKIqmz div:first-child > ul li:last-child {
      display: none !important; }
    ._3NQD1-N9L7ne2-kMdKIqmz div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes _2Z96Sw7sCljSoOIwQxVVYw {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes _2Z96Sw7sCljSoOIwQxVVYw {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._2IH6TRaDjdHZJgwjOSGwJj {
  -webkit-animation: _2Z96Sw7sCljSoOIwQxVVYw 4s forwards;
          animation: _2Z96Sw7sCljSoOIwQxVVYw 4s forwards; }

._2rPo3oOOAbzm-ATKbdI33Z {
  display: flex;
  justify-content: center;
  align-items: center; }

._24etqsoJPIQtK6k-2JkvyG {
  justify-content: center;
  flex-wrap: wrap; }
  ._24etqsoJPIQtK6k-2JkvyG .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._24etqsoJPIQtK6k-2JkvyG .active button:hover {
    background: none;
    cursor: default !important; }
  ._24etqsoJPIQtK6k-2JkvyG .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._24etqsoJPIQtK6k-2JkvyG div button {
    display: block !important; }

@media screen and (max-width: 520px) {
  ._2rd9mbE-juJgtZ5WDTKip7 {
    justify-content: center; }
  ._1htKVr__qt8aVFnaoW9pJ_ {
    padding: 48px 0;
    width: 350px;
    font-size: 14px;
    line-height: 20px; }
    ._1htKVr__qt8aVFnaoW9pJ_ ._2YLJVgzq6YkiBgaVnrZDXm {
      font-size: 30px;
      line-height: 40px; }
    ._1htKVr__qt8aVFnaoW9pJ_ ._1cwhoaLgJ4Tj-X7VYvzwYo, ._1htKVr__qt8aVFnaoW9pJ_ .E7BqCZWHbDp-zU_Jgt1y {
      font-size: 14px;
      line-height: 20px; }
  ._2p1BHIX_SHhgUC2LZls8ga {
    padding: 0 25px; } }

._2mQyicEriuJBCAMb4bFmtc {
  cursor: pointer; }

._17HoFyK6EdY9XnxpmubPjV {
  margin-top: 4%; }
  ._17HoFyK6EdY9XnxpmubPjV input {
    background: #edf1f6 !important; }

._2CCPSCatSL0Jch6SZmDjlj {
  font-size: 48px !important; }

._26lYUCZIaAKB_71IsoVg1y {
  margin: 5rem auto 2rem auto;
  text-align: left;
  font-size: 16px; }

._gqF6ffM_pLMmZMlxBJ2v {
  margin: 10rem auto; }

._3z70LKEqyWum0BwNlj92GT {
  text-align: center;
  justify-content: center;
  display: grid; }

._1Dqms71UnbXH93qjxYx3g4 {
  margin: auto;
  font-size: 12px;
  margin-top: 16px;
  width: 300px;
  margin-bottom: 20px !important; }

._1_dYlMrmSXS6OvT7TmvKsu {
  display: flex;
  justify-content: space-around;
  margin: auto;
  font-size: 16px;
  margin-top: 16px;
  width: 240px;
  font-weight: bold;
  margin-bottom: 45px !important; }

._3sGSa71g0UUyGov7ibFWKK {
  font-weight: 600; }
  ._3sGSa71g0UUyGov7ibFWKK input {
    height: 17px !important;
    width: 17px !important; }
  ._3sGSa71g0UUyGov7ibFWKK label {
    margin-left: 5px; }

._2qIHljzupqk-2d-1gKWM2l {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px; }

.V_TbFzYp7VcallwbpYCz5 {
  display: flex !important;
  align-items: center; }
  .V_TbFzYp7VcallwbpYCz5 > div:first-child {
    margin-bottom: 0px !important; }
    .V_TbFzYp7VcallwbpYCz5 > div:first-child > div:first-child {
      background: #edf1f6;
      border: none; }
      .V_TbFzYp7VcallwbpYCz5 > div:first-child > div:first-child input {
        height: 17px;
        width: 17px; }
  .V_TbFzYp7VcallwbpYCz5 p {
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0px !important; }
    .V_TbFzYp7VcallwbpYCz5 p span {
      color: #0b71ff; }
      .V_TbFzYp7VcallwbpYCz5 p span:hover {
        cursor: pointer; }

._2y0h72CQY-d8NV52wxn_zy {
  text-align: right;
  font-size: 12px;
  font-weight: 500; }
  ._2y0h72CQY-d8NV52wxn_zy:hover {
    cursor: pointer; }

._1o0V9oCSaNNmL947ecBhuB {
  margin-top: 32px;
  font-weight: bold; }

._3Q0QupMHL9HFSvHQYQ3dNK {
  margin-bottom: 4px; }

._3HL95WjtaLPbtElnAPRxIK {
  font-weight: 600;
  margin-top: 25%;
  text-align: center;
  color: #031937; }
  ._3HL95WjtaLPbtElnAPRxIK span {
    color: #0b71ff; }
    ._3HL95WjtaLPbtElnAPRxIK span:hover {
      cursor: pointer; }

._1E3sYltLt0BO0foqbH1a5C {
  position: relative;
  background-image: url(/static/media/onboarding-background.5798e1b0.svg);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0; }

._1ZKvI106OX8-AltGbzU3hM ._3-barkbRug2zx2BevyWnP5 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  height: auto; }

._1ZKvI106OX8-AltGbzU3hM ._39uLaaD8xrHtL5SQ1cHZlc {
  margin-top: 2.5%; }

._1ZKvI106OX8-AltGbzU3hM label {
  font-size: 14px;
  font-weight: bold !important;
  color: #031937; }

._1ZKvI106OX8-AltGbzU3hM select {
  background-color: #FFFFFF; }

._1ZKvI106OX8-AltGbzU3hM input {
  background: #FFFFFF !important; }

._1ZKvI106OX8-AltGbzU3hM button {
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
  background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._1ZKvI106OX8-AltGbzU3hM ._2PmnH3F5j_q4q4_UzboG7k {
  margin-top: 10px;
  display: flex;
  flex-direction: row; }
  ._1ZKvI106OX8-AltGbzU3hM ._2PmnH3F5j_q4q4_UzboG7k button {
    margin-top: 0px !important; }

.z88pMCFuX4xOLuQNujxa9 {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  width: 532px; }
  .z88pMCFuX4xOLuQNujxa9 h2 {
    color: #000; }
  .z88pMCFuX4xOLuQNujxa9 ._3SLJMjWhpu6JS3pcmEHOGO {
    margin-right: 10px;
    cursor: pointer; }
  .z88pMCFuX4xOLuQNujxa9 .c_RTio7_kfjmA-SWtSmYa {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000; }
  .z88pMCFuX4xOLuQNujxa9 ._1Bj_-dxD0ZZcmajM5QZeqd {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px; }
  .z88pMCFuX4xOLuQNujxa9 ._3UmNQCP95fNrsCMSk1Es4g {
    color: #475569; }
  .z88pMCFuX4xOLuQNujxa9 ._2mqmq71-MjQwzmbaXQaQ3Z {
    font-size: 16px;
    line-height: 22px;
    color: #475569; }
    .z88pMCFuX4xOLuQNujxa9 ._2mqmq71-MjQwzmbaXQaQ3Z ._3hI0--z-jX17EGq_dEcG-a {
      cursor: pointer;
      font-weight: 500; }
  .z88pMCFuX4xOLuQNujxa9 ._3rQQzcbDc1sdDtrpka9kxR {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px; }
  .z88pMCFuX4xOLuQNujxa9 ._3vzmG8F7r3URazNaqAJH_N {
    border-radius: 4px;
    background: linear-gradient(135deg, #2946C1 0%, #0779EB 100%);
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%)); }

._2Im7aMidnNOx58OPxfwAIM {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff; }

._2-ibSIlW1ejhImxA84B0On {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s; }

._2OZoKnGQS-V5q1LZ_hFNpN {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e; }

._1eoMIEvD_csB2AdSQilpwB {
  font-size: 14px;
  font-weight: bold;
  color: #031937; }

._1aEOdbNMnCU6ZbgXL2qtkr {
  display: flex;
  justify-content: center; }

.GthrpEItZFX2uPoq7SZRX input {
  height: 17px;
  width: 17px; }

.GthrpEItZFX2uPoq7SZRX p {
  font-weight: 300 !important; }
  .GthrpEItZFX2uPoq7SZRX p span {
    font-weight: bold; }

.GthrpEItZFX2uPoq7SZRX label {
  margin-left: 5px;
  font-weight: normal !important;
  font-size: 12px !important; }
  .GthrpEItZFX2uPoq7SZRX label p span {
    color: #0b71ff; }
    .GthrpEItZFX2uPoq7SZRX label p span:hover {
      cursor: pointer; }

._3hZSSdURvbXVZefJIg4XrP {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px; }

._1PKEdQdSakMdVRwZlqQQh3 {
  display: none; }

._2BXb0PqYvdUQd4U3dN7RJf {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._1hQyTzXv2v1CDhoVGDX0ja {
  display: block; }

._2BXb0PqYvdUQd4U3dN7RJf {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px; }

._2vafjG8wexamD-1maNmTbN {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center; }

.suR63VqEJ-dzMoSkl9qMY {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important; }

._3xwFYwYVZlFcbKSscIiDI1 {
  margin-top: 30%; }

.Aqto2mZpm88zUIAypgxp3 {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._2_yZzWuPedoW81p409oQIp span {
  display: inline-block; }

._3eE2oSy9-wpDwZPSI55NYo {
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  color: blue;
  padding: 15px;
  top: 31%; }

._1EHYTokczZGRLy26oW0CXH {
  margin-top: 25px; }

._31fVD-SIXfqY7wgdAvCw9Z {
  margin-top: 10px; }

._1AdSzZ_F4u7EzFHPBhby3N {
  height: 200px;
  width: 200px;
  object-fit: contain; }

._2uacsOZPEy8vMeAXcu3_Mf {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 990px) {
  ._1AdSzZ_F4u7EzFHPBhby3N {
    height: 200px;
    width: 200px; } }

._2voFFUeMCJ4MVfDoCL4Wyi {
  margin-top: 14px; }

._3IlhqBc0EvCnSHAHU_TvP5 {
  margin-top: 30px; }

._1ViR23o5ssNkBMrIg067VO {
  border: none;
  color: black; }
  ._1ViR23o5ssNkBMrIg067VO:hover {
    background: transparent;
    color: black; }

._9bKM_8M-OluHAB2VIttV3 {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gray; }
  ._9bKM_8M-OluHAB2VIttV3 div:first-child {
    width: 100% !important; }
    ._9bKM_8M-OluHAB2VIttV3 div:first-child > ul li:last-child {
      display: none !important; }
    ._9bKM_8M-OluHAB2VIttV3 div:first-child button {
      background: none;
      border-radius: 2px; }

@-webkit-keyframes _2FYewevd4C1q2CMjLaEGUx {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes _2FYewevd4C1q2CMjLaEGUx {
  0% {
    opacity: 0.1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

._2rxi-MU9y1qe0W2SMA8Bqd {
  -webkit-animation: _2FYewevd4C1q2CMjLaEGUx 4s forwards;
          animation: _2FYewevd4C1q2CMjLaEGUx 4s forwards; }

._1FSEXgP5F0i_VjtdPjNzG6 {
  display: flex;
  justify-content: center;
  align-items: center; }

._1ugtn34VngSDSdXNJursCH {
  justify-content: center;
  flex-wrap: wrap; }
  ._1ugtn34VngSDSdXNJursCH .active button span:first-child {
    background-color: #3fcc99 !important; }
  ._1ugtn34VngSDSdXNJursCH .active button:hover {
    background: none;
    cursor: default !important; }
  ._1ugtn34VngSDSdXNJursCH .active button span:last-child {
    font-size: 16px;
    color: #031937;
    font-weight: 600; }
  ._1ugtn34VngSDSdXNJursCH div button {
    display: block !important; }

@media screen and (max-width: 520px) {
  ._3hZSSdURvbXVZefJIg4XrP {
    justify-content: center; }
  .z88pMCFuX4xOLuQNujxa9 {
    padding: 48px 0;
    width: 350px !important;
    font-size: 14px;
    line-height: 20px; }
    .z88pMCFuX4xOLuQNujxa9 .c_RTio7_kfjmA-SWtSmYa {
      font-size: 30px;
      line-height: 40px; }
    .z88pMCFuX4xOLuQNujxa9 ._1Bj_-dxD0ZZcmajM5QZeqd {
      font-size: 14px;
      line-height: 20px; }
  ._1ZKvI106OX8-AltGbzU3hM {
    padding: 0 25px; } }

._1xIdWyNW1TFSnHYQTI_2E1 {
  padding: 20px 40px;
  background-color: #ffffff;
  height: 100vh; }

._3NHlx9qzzMSxa1-nucW7dX {
  font-size: 24px !important; }

.T8JCVJxEL98Z_NfThqfAp {
  padding: 20px 5px;
  white-space: normal;
  white-space: initial;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475569; }

._3mOk_NgxijeuXmSGl5mnDd {
  max-height: 75vh;
  box-shadow: 0px 4px 32px 0px #0000000A;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #F1F1F4; }

._2aBt7cntHI6vbJlcs0OwP1 {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

._1y9lgi_ACcLKsMrHnu9erj {
  color: #2946c1; }

._2A04wIuiV0bXtyMQ9lzQg {
  padding: 20px 40px;
  background-color: #ffffff;
  min-height: 85dvh; }

._2AJ0AzPOb5yHqbRfc1mAzk {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  height: 400px; }

._3WyaQarnMeaCzUrAMnLIuH {
  margin: 20px;
  display: flex; }

._2lTh57DYy3QihjaaVcqbuf {
  box-shadow: 0px 4px 32px 0px #0000000A;
  padding: 20px;
  border-radius: 4px; }

._2rx3YN89pcKc6HqCp1GV9S {
  box-shadow: 0px 4px 32px 0px #0000000A;
  padding: 20px;
  border-radius: 4px; }

._1DGO2Xu8441dcV5znrN1LR {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0F172A; }

._1_IzUYLQ6BkBJY0mWc5kRI {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0F172A9C; }

._X74Myvdn4A_Sq9Rop8cH {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0F172A9C; }

.-ybJyKfrTrVMyyBKLSwuk {
  padding: 20px 40px;
  background-color: #ffffff;
  min-height: 85dvh; }

._3p5nnH4_60HOl7OgzP-8Kv {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  height: 400px; }

._1d_TwkesYwQmmN2dAGDpum {
  margin: 20px;
  display: flex; }

._14oJusUkThmk1O63YVZFig {
  padding: 20px 40px;
  background-color: #ffffff;
  min-height: 85dvh; }

.pdNv9drR8dXl4xDoG2KNL {
  font-size: 24px !important; }

._1UiIZk1jk6mQXrkldILPNK {
  padding: 20px 5px;
  white-space: normal;
  white-space: initial;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475569; }

.WbqRePBHFhd_5Yj4VHyv- {
  max-height: 70vh; }

._1mTKMCCjfxOozVN4QqBGwz {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap; }

._2X0g-uxtW4AV2_6Ji3BFbd {
  color: #2946c1; }

._3gtrsoAfizfSZpI5ZAkMOT {
  margin: 20px;
  display: flex; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-100.6abee480.woff) format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-100Italic.154dd507.woff) format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-300.2fd4ffcf.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-300Italic.eb980e29.woff) format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans_500.4cd69bc5.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans_500.4cd69bc5.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans_500_Italic.0df21e8a.woff) format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans_700.1e715487.woff) format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-700Italic.f2a493eb.woff) format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans_900.c1d55a65.woff) format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Museo Sans";
  src: url(/static/media/MuseoSans-900Italic.4bdf7015.woff) format("woff");
  font-weight: 900;
  font-style: italic; }

body {
  margin: 0;
  height: 100%;
  font-size: 16px;
  background: #edf1f6;
  font-family: "Museo Sans" !important; }

.css-2b097c-container div div:last-child span {
  background: none !important; }

.css-2b097c-container div div:last-child div {
  color: #b1a4a4 !important; }

.docstable .nav-link {
  font-weight: 500;
  color: darkgray;
  padding-left: 30px;
  padding-right: 30px;
  border-right: none;
  border-left: none; }

.docstable .nav-link.active {
  color: black;
  border-color: white;
  border-bottom: solid #0b71ff 5px; }

.docstable .nav-link.active:hover {
  border-bottom: solid #0b71ff 5px; }

.docstable .nav-link:hover {
  border-color: white; }

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  box-shadow: none !important; }

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87; }

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 35%;
  height: 40%;
  width: 3px;
  z-index: 120;
  content: ".";
  background: #000; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper input {
    width: 100%;
    height: 58px;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da; }

.form-group label {
  font-size: 18px;
  font-weight: bold !important;
  color: #031937; }

.form-group input, .form-group select {
  font-weight: bold;
  padding: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 58px; }

.form-group input::-webkit-input-placeholder {
  color: #7C8495;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; }

.form-group input::-moz-placeholder {
  color: #7C8495;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; }

.form-group input:-ms-input-placeholder {
  color: #7C8495;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; }

.form-group input::-ms-input-placeholder {
  color: #7C8495;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; }

.form-group input::placeholder, .form-group select {
  color: #7C8495;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; }

.focus {
  outline: 0 !important;
  box-shadow: none !important; }

.form-check-input {
  height: 15px !important; }

button:focus {
  outline: 0 !important;
  box-shadow: none !important; }

label:focus {
  outline: 0 !important;
  box-shadow: none !important; }

