.wrapper {
  padding: 20px 40px;
  background-color: #ffffff;
  min-height: 85dvh;
}

.title {
  // font-weight: bold;
  font-size: 24px !important;
  // margin-bottom: 0;
}

.tblCell {
  padding: 20px 5px;
  white-space: initial;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475569;
}

.ReactTable {
  max-height: 70vh;
  // min-width: 200vh;
}

.tblColHeading {
  font-weight: bold;
  color: black;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px 10px;
  padding-right: 0;
  white-space: pre-wrap;
}

.statsText {
  color: #2946c1;
}

.backContainer {
  margin: 20px;
  display: flex;
}
