.react-multi-email {
    padding: 0 0.5em !important;
    margin-top: 15px;
    width: 75%;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.react-multi-email > input {
    font-size: 1rem !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}

.react-multi-email.focused {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}