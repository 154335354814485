@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap');

$bgcolor: #edf1f6;

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-100.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-100Italic.woff") format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-300.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-300Italic.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans_500.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans_500.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans_500_Italic.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans_700.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-700Italic.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans_900.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("font/MuseoSans/MuseoSans-900Italic.woff") format('woff');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  height: 100%;
  font-size: 16px;
  background: $bgcolor;
  font-family: "Museo Sans" !important;
  //font-family: 'Montserrat', sans-serif !important;
}

:global(.css-2b097c-container) {
  div {
    div:last-child {
      span {
        background: none !important;
      }
      div {
        color: #b1a4a4 !important;
      }
    }
  }
}

:global(.docstable) {
  :global(.nav-link) {
    font-weight: 500;
    color: darkgray;
    padding-left: 30px;
    padding-right: 30px;
    border-right: none;
    border-left: none;
  }
}

:global(.docstable .nav-link.active) {
  color: black;
  border-color: white;
  border-bottom: solid #0b71ff 5px;
}

:global(.docstable .nav-link.active:hover) {
  border-bottom: solid #0b71ff 5px;
}

:global(.docstable .nav-link:hover) {
  border-color: white;
}

:global(.ReactTable .rt-resizable-header) {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

:global(.ReactTable .rt-resizer:before) {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}
:global(.ReactTable .rt-resizable-header-content:after) {
  position: absolute;
  right: 8px;
  top: 35%;
  height: 40%;
  width: 3px;
  z-index: 120;
  content: ".";
  background: #000;
}

:global(.react-datepicker-wrapper) {
  width: 100%;
  input {
    width: 100%;
    height: 58px;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
  }
}

:global(.form-group) {
  label {
    font-size: 18px;
    font-weight: bold !important;
    color: #031937;
  }
  input, select {
    font-weight: bold;
    padding: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 58px;
  }
  input::placeholder, select {
    color: #7C8495;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
  }
}

:global {
  .focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  .form-check-input {
    height: 15px !important;
  }

  button {
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }

  label {
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}
