$bgcolor:  #FFFFFF;

.backgroundImage {
  position: relative;
  background-image: url("../../../../assets/onboarding-background.svg");
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 0 0 50px 0;
}

.wrapper {
  //padding: 25px 50px;

  .centerClass {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    height: auto;
  }

  .marginForm {
    margin-top: 2.5%;
  }
  label {
    font-size: 14px;
    font-weight: bold !important;
    color: #031937;
  }

  select {
    background-color: $bgcolor;
  }

  input {
    background: $bgcolor !important;
  }
  button {
    font-weight: bold;
    border-radius: 4px;
    height: 48px;
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
    //transition: transform 0.2ms ease-out;
    //&:hover {
    //  transform: scale(1.05);
    //}
  }
  .dualBtns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    button {
      margin-top: 0px !important;
    }
  }
}

.onboardingCard {
  padding: 48px;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #475569;
  width: 532px;

  h2 {
    color: #000;
  }

  .arrowImage {
    margin-right: 10px;
    cursor: pointer;
  }

  .cardTitle {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #000;
  }

  .cardSubtitle {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px
  }

  .greyColor {
    color: #475569;
  }

  .termsText {
    font-size: 16px;
    line-height: 22px;
    color: #475569;

    .termsTextBold {
      cursor: pointer;
      font-weight: 500;
    }
  }

  .mandatory {
    color: #B30021;
    font-size: 18px;
    font-style: normal;
    font-weight: 4300;
    line-height: 26px;
  }

  .button {
    border-radius: 4px;
    background: var(--blue-gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%));
  }

}


.fwBold {
  font-weight: bold;
  opacity: 1 !important;
  color: #007bff;
}

.divCard {
  padding: 20px 100px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.logoStyle {
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1e;
}

.cutomLabel {
  font-size: 14px;
  font-weight: bold;
  color: #031937;
}

.centerStyle {
  display: flex;
  justify-content: center;
}

.checkboxLabel {
  input {
    height: 17px;
    width: 17px;
  }
  p {
    font-weight: 300 !important;
    span {
      font-weight: bold;
    }
  }

  label {
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 12px !important;

    p {
      span {
        color: #0b71ff;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.headerLogo {
  display: flex;
  justify-content: flex-start;
  margin-left: -14%;
  padding: 25px 50px 0 50px;
}

.blockDiv {
  display: none;
}
.error {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px;
}
.showDiv {
  display: block;
}

.error {
  font-size: 80%;
  color: #dc3545;
  margin-top: 5px;
}
.signatueDiv {
  border: 1px solid #ced4da;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signatueBox {
  color: #707070;
  word-break: break-word;
  font-size: 35px !important;
}

.marginTop {
  margin-top: 30%;
}

.signatureOnly {
  // font-size: larger !important;
  // position: absolute;
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  // text-align: center;
  color: blue;
  padding: 15px;
  // font-size: 52px;
  top: 31%;
}

.stateText span {
  display: inline-block;
}

.signatureView {
  // font-size: larger !important;
  // position: absolute !important;
  // text-align: center;
  // color: blue;
  // top: 55%;
  // font-size: larger !important;
  // font-size: larger !important;
  // position: absolute;
  text-align: center;
  font-size: 50px !important;
  word-break: break-word;
  // text-align: center;
  color: blue;
  padding: 15px;
  // font-size: 52px;
  top: 31%;
}
.reUploadSealButton {
  margin-top: 25px;
}
.uploadSealButton {
  margin-top: 10px;
}

.uploadSealImg {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.alignSeal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .uploadSealImg {
    height: 200px;
    width: 200px;
  }
}

.margin {
  margin-top: 14px;
}

.marginDbl {
  margin-top: 30px;
}

.noBorder {
  border: none;
  color: black;

  &:hover {
    background: transparent;
    color: black;
  }
}

.fontPickerStyle {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
  color: hsl(0, 0%, 50%);

  div:first-child {
    width: 100% !important;
    > ul {
      li:last-child {
        display: none !important;
      }
    }

    button {
      background: none;
      border-radius: 2px;
    }
  }
}
@keyframes mymove {
  0% {
    opacity: 0.1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.bouncingSeal {
  animation: mymove 4s forwards;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeClass {
  justify-content: center;
  flex-wrap: wrap;

  :global {
    .active {
      button {
        span:first-child {
          background-color: #3fcc99 !important;
        }
        &:hover {
          background: none;
          cursor: default !important;
        }

        span:last-child {
          font-size: 16px;
          color: #031937;
          font-weight: 600;
        }
      }
    }
  }

  div {
    button {
      display: block !important;
    }
  }
}

@media screen and (max-width: 520px) {
  .headerLogo {
    justify-content: center;
  }

  .onboardingCard {
    padding: 48px 0;
    width: 350px !important;
    font-size: 14px;
    line-height: 20px;

    .cardTitle {
      font-size: 30px;
      line-height: 40px;
    }

    .cardSubtitle {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .wrapper {
    padding: 0 25px;
  }
}
